import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";

import GenericLayout from "../../common/layout/GenericLayout";
import secureCodeAPI from "../../Api/secureCodeAPI";
import { ThemeContext } from "../../context/ThemeContext";
import AreaChart from "../../common/Charts/AreaChart";
import DonutChart from "../../common/Charts/DonutChart";
import SecureCodeReviewTable from "../../modules/home/components/secureCodeReview/secureCodeReviewTable";
import { complianceAPI } from "../../Api/CSPM/api";
import CreateRepo from "./CreateRepo";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import { isAgentOrAdminOrSuperAdmin } from "../../utils/accessCheck";
import OrganizationsList from "../compliance/OrganizationsList";
import { TbArrowUpRight } from "react-icons/tb";

export const STATUS = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  {
    label: "Risk Accepted",
    value: "risk_accepted",
  },
];

export default function SecureCodeReview() {
  const { isDarkMode } = useContext(ThemeContext);
  const { role, org_id } = useSelector(getUserDetails);
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [visibleOrg, setVisibleOrg] = useState(false);

  const getData = () => {
    secureCodeAPI
      .get("/dashboard/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployees = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getEmployees();
  }, []);

  const overviewData = [
    {
      name: "Scanned repositories",
      data: 0,
    },
    {
      name: "Code Coverage",
      data: "0%",
    },
    {
      name: "Compliance Status",
      data: "0%",
    },
  ];

  const columns = [
    {
      field: "name",
      header: "Repository Name",
    },
    {
      field: "last_commit",
      header: "Last Commit",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: STATUS,
    },
    {
      field: "last_reviewed",
      header: "Last Review",
      type: "date",
    },
    {
      field: "assignee",
      header: "Reviewer",
      type: "select",
      value: employees?.map((i) => ({
        value: i.id,
        label: i.email,
      })),
    },
    isAgentOrAdminOrSuperAdmin(role) && {
      field: "org_id",
      header: "User",
      type: "select",
      value: employees?.map((i) => ({
        value: i.id,
        label: i.email,
      })),
    },
    {
      field: "full-info",
      header: "Full info",
      type: "func",
      func: (row) => (
        <button
          onClick={() => {
            navigate("./details", {
              state: {
                data: row,
                employees,
              },
            });
          }}
        >
          <FiArrowUpRight size={20} color="#074EE8" />
        </button>
      ),
    },
    {
      field: "action",
      header: "Action",
      type: "action",
    },
  ];

  useEffect(() => {
    getData();
  }, [org_id]);

  return (
    <GenericLayout>
      <div className="flex justify-between dashboard-header items-center">
        <p className="font-bold">Secure Code Review</p>
        {isAgentOrAdminOrSuperAdmin(role) && (
          <div className="flex justify-between">
            <button
              className="flex items-center gap-[6px] text-[#074EE8] text-base"
              onClick={() => setVisibleOrg(true)}
            >
              Select Organizations
              <TbArrowUpRight />
            </button>
          </div>
        )}
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <AreaChart
            title="Risk Score"
            data={data?.risk_score_graph?.map((item) => item.score) || []}
            colors={["#F14D34", "#FEEDEB"]}
            categories={data?.risk_score_graph?.map((item) => item.date) || []}
            value={data?.risk_score || 0}
            tooltip="Secure Score"
          />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3">
            <DonutChart
              title="Risk by severity"
              type="normal"
              labels={["Critical", "High", "Medium", "Low"]}
              colors={["#A30123", "#E8001D", "#F5B207", "#148842"]}
              chartData={[
                data?.severity?.critical || 0,
                data?.severity?.high || 0,
                data?.severity?.medium || 0,
                data?.severity?.low || 0,
              ]}
              total={
                (data?.severity?.critical || 0) +
                (data?.severity?.high || 0) +
                (data?.severity?.medium || 0) +
                (data?.severity?.low || 0)
              }
              legends={[
                {
                  name: `Critical - ${data?.severity?.critical || 0}`,
                  color: "#A30123",
                },
                {
                  name: `High - ${data?.severity?.high || 0}`,
                  color: "#E8001D",
                },
                {
                  name: `Medium - ${data?.severity?.medium || 0}`,
                  color: "#F5B207",
                },
                {
                  name: `Low - ${data?.severity?.low || 0}`,
                  color: "#148842",
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3">
            <DonutChart
              title="Vulnerability status"
              type="normal"
              labels={["Open", "Closed", "Risk accepted"]}
              colors={["#A30123", "#F5B207", "#148842"]}
              chartData={[
                data?.status?.open || 0,
                data?.status?.closed || 0,
                data?.status?.risk_accepted || 0,
              ]}
              total={
                (data?.status?.open || 0) +
                (data?.status?.closed || 0) +
                (data?.status?.risk_accepted || 0)
              }
              legends={[
                {
                  name: `Open - ${data?.status?.open || 0}`,
                  color: "#A30123",
                },
                {
                  name: `Risk accepted - ${data?.status?.risk_accepted || 0}`,
                  color: "#F5B207",
                },
                {
                  name: `Closed - ${data?.status?.closed || 0}`,
                  color: "#148842",
                },
              ]}
            />
          </div>
        </div>
        {/* <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3">
            <DonutChart
              title="Vulnerability Breakdown"
              type="normal"
              labels={Object.keys(data?.vulns || {})}
              colors={["#A30123", "#E8001D", "#F5B207", "#148842", "#074EE8"]}
              chartData={Object.values(data?.vulns || {})}
              total={
                Object.values(data?.vulns || {}).reduce(
                  (acc, item) => acc + item,
                  0
                ) || 0
              }
              legends={[
                {
                  name: Object.keys(data?.vulns || {})[0],
                  color: "#A30123",
                },
                {
                  name: Object.keys(data?.vulns || {})[1],
                  color: "#E8001D",
                },
                {
                  name: Object.keys(data?.vulns || {})[2],
                  color: "#F5B207",
                },
                {
                  name: Object.keys(data?.vulns || {})[3],
                  color: "#148842",
                },
                {
                  name: Object.keys(data?.vulns || {})[4],
                  color: "#074EE8",
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3">
            <DonutChart
              title="Report Scan Status"
              type="normal"
              labels={[]}
              colors={[]}
              chartData={[]}
              total={0}
              legends={[]}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl p-3`}
        >
          <h1 className="text-lg font-medium mb-2 text-center">Overview</h1>
          <div className="flex flex-col gap-2">
            {overviewData.map((item, index) => (
              <div
                className="py-2 px-4 flex flex-col gap-2 border border-tertiary rounded"
                key={index}
              >
                <p className="text-primary text-sm font-medium">{item.name}</p>
                <h1 className="text-primary font-bold">{item.data}</h1>
              </div>
            ))}
          </div>
        </div>*/}
      </div>
      <div className="mt-6">
        <SecureCodeReviewTable
          key={org_id}
          title="Repositories"
          columns={columns}
          url_path="/repositories/"
          AddComponent={CreateRepo}
        />
      </div>

      <OrganizationsList
        visibleOrg={visibleOrg}
        setVisibleOrg={setVisibleOrg}
      />
    </GenericLayout>
  );
}
