import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { TbChevronDown, TbChevronUp } from "react-icons/tb";
import { BiPlus } from "react-icons/bi";
import { toast } from "react-toastify";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import { Dropdown } from "primereact/dropdown";
import fileUpload from "../../../../../utils/fileUpload";
import { confirmDialog } from "primereact/confirmdialog";

const Accordion = ({ title, content }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div
      className={`mt-5 p-2 bg-slate-100 rounded-lg border transition-all duration-300 ease-in-out ${
        accordionOpen
          ? "border-transparent border-l-[3px] border-l-orange-400"
          : ""
      }`}
    >
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex justify-between items-center w-full border-none outline-none"
      >
        <h1 className="ml-1">{title}</h1>
        <span className="text-xl">
          {accordionOpen ? <TbChevronUp /> : <TbChevronDown />}
        </span>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="flex flex-col gap-3 overflow-hidden">{content}</div>
      </div>
    </div>
  );
};

export default function AddLessons({
  showLessons,
  setShowLessons,
  currentDetails,
}) {
  const [lesson, setLesson] = useState([]);

  const getLessions = () => {
    complianceAPI
      .post("/campaign/lesson/filter/", { campaign: showLessons.id })
      .then(({ data }) => setLesson(data))
      .catch((err) => console.log(err));
  };
  const cleanClose = () => {
    setShowLessons(false);
  };

  const CourseForm = ({ item, i }) => {
    const [data, setData] = useState(item);
    const [error, setError] = useState({});
    // const [visible, setVisible] = useState(v);

    const handleAPI = (e) => {
      if (data?.id) {
        complianceAPI
          .patch(`/campaign/lesson/${data.id}/`, data)
          .then((res) => {
            if (!res.id) {
              HandleResponseError(res.message);
              e.target.submit.removeAttribute("disabled");
            } else {
              toast.success("Lesson updated successfully");
              e.target.submit.removeAttribute("disabled");
              cleanClose();
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
            e.target.submit.removeAttribute("disabled");
          });
      } else {
        complianceAPI
          .post("/campaign/lesson/", data)
          .then((res) => {
            if (!res.id) {
              HandleResponseError(res.message);
              e.target.submit.removeAttribute("disabled");
            } else {
              toast.success("Lesson created successfully");
              e.target.submit.removeAttribute("disabled");
              cleanClose();
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
            e.target.submit.removeAttribute("disabled");
          });
      }
    };

    const handleDelete = () => {
      confirmDialog({
        header: `Delete ${item.name}`,
        message: "Are you Sure..?",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          if (item.id)
            complianceAPI
              .delete(`/campaign/lesson/${item.id}/`)
              .then((res) => {
                toast.success("Lesson deleted successfully");
                setLesson(lesson.filter((_, j) => i !== j));
              })
              .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message);
              });
          else setLesson(lesson.filter((_, j) => i !== j));
        },
      });
    };

    const handleCreate = (e) => {
      e.preventDefault();
      e.target.submit.setAttribute("disabled", "disabled");

      if (data?.type === "pdf") {
        const file = e.target.file.files[0];
        fileUpload("v1/compliance/attachments/", file)
          .then((res) => {
            if (res.status === "success") {
              data.file = res.data.file;
              handleAPI(e);
            } else {
              toast.error(res.message);
              e.target.submit.removeAttribute("disabled");
              return;
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(err);
            e.target.submit.removeAttribute("disabled");
            return;
          });
      } else {
        handleAPI(e);
      }
    };

    const cleanClose = () => {
      // setShowLessons(false);
      getLessions();
      // setData({});
      setError({});
    };

    return (
      <div className="w-[90%] flex flex-wrap mt-6 mb-2 gap-2 mx-auto">
        <form
          onSubmit={(e) => handleCreate(e)}
          className="w-full flex items-center flex-col gap-4"
        >
          {/* Name */}
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Name</label>
            <input
              className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter Lesson name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div>
          {/* Duration */}
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">
              Duration (min)
            </label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="number"
              placeholder="Enter Lesson duration"
              value={data.duration}
              required
              onChange={(e) => {
                setData({ ...data, duration: e.target.value });
                setError({ ...error, duration: false });
              }}
            />
            {error.duration && (
              <p className="text-red-500 text-sm">{error.duration}</p>
            )}
          </div>
          <div className="w-full">
            <Dropdown
              value={data.type}
              onChange={(e) => setData({ ...data, type: e.value })}
              options={[
                {
                  label: "Video",
                  value: "video",
                },
                {
                  label: "PDF",
                  value: "pdf",
                },
              ]}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full my-1"
            />
          </div>
          {/* URL */}
          {data?.type === "video" ? (
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">Url</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="url"
                placeholder="Enter video url"
                value={data.file}
                required
                onChange={(e) => {
                  setData({ ...data, file: e.target.value });
                  setError({ ...error, file: false });
                }}
              />
              {error.file && (
                <p className="text-red-500 text-sm">{error.file}</p>
              )}
            </div>
          ) : (
            <div className="w-full pt-3">
              <label className="block text-gray-500 text-sm" for="file_input">
                Upload file
              </label>
              <input
                className="w-full cursor-pointer rounded-lg border-[1px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter dark:file:text-gray-500 file:py-2 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:focus:border-primary"
                name="file"
                type="file"
              />
            </div>
          )}
          {/* Button */}
          <div className="flex w-full">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {data?.id ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={() => handleDelete()}
              type="reset"
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    );
  };

  useEffect(() => {
    if (showLessons?.id) getLessions();
  }, [showLessons]);

  return (
    <Sidebar
      visible={showLessons}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-40rem sidebar-style"
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Lesson`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } lesson`}</p>
      {lesson.length ? (
        lesson?.map((item, i) => {
          return (
            <Accordion
              title={`Lesson ${i + 1}`}
              content={<CourseForm item={item} i={i} />}
            />
          );
        })
      ) : (
        <div className="flex justify-center">No lessons Added..!</div>
      )}
      <button
        onClick={() =>
          setLesson([
            ...lesson,
            {
              campaign: showLessons.id,
              name: null,
              duration: null,
              url: null,
            },
          ])
        }
        className="mt-4 px-4 py-2 border-none outline-none rounded-md bg-black flex items-center justify-between gap-3 text-white"
      >
        <BiPlus />
        <span>Add Lesson</span>
      </button>
    </Sidebar>
  );
}
