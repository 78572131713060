import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import SecureScore from "../../attackSurfaceManagement/components/SecureScore";
import DonutChart from "../../../common/Charts/DonutChart";
import BrandTreat from "../../dataLeak/components/BrandTreat";
import { useNavigate } from "react-router-dom";
import baseAPI, { baseUrl } from "../../../Api/Repository";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import Impersonation from "../../attackSurfaceManagement/table/BrandThreatIntelligence/Impersonation";

const BrandThreatIntelligenceComponent = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const handleNavigate = (e, type) => {
    console.log("SGDGFDF", e, type);
    e.preventDefault();
    if (type === "dark-web") {
      navigate(`/digital-risk-protection/${type}`);
    }
  };

  const scan = useSelector(getUserDetails).scan_id;
  // const uniqueID = useSelector(getUserDetails).drp_unique_id;
  // console.log("userDetails", userDetails);

  const [secureScoreData, setSecureScoreData] = useState({});
  const [secureScoreGraphCategory, setSecureScoreGraphCategory] = useState([]);
  const [secureScoreGraphData, setSecureScoreGraphData] = useState([]);
  const [VIPProtection, setVIPProtection] = useState({});
  const [socialLeaks, setSocialLeaks] = useState({});

  const getSecureScore = () => {
    baseAPI
      .get(`${baseUrl}v1/web/darkweb/dashboard/securescore/`, {
        params: { scan },
      })
      .then((res) => {
        setSecureScoreData(res.data.data);
      });
  };

  const getSecureScoreGraph = () => {
    baseAPI
      .get(`${baseUrl}v1/web/darkweb/dashboard/securescoregraph/`, {
        params: { scan },
      })
      .then((res) => {
        setSecureScoreGraphCategory(res.data.data.categories);
        setSecureScoreGraphData(res.data.data.data);
      });
  };

  const getVIPProtection = () => {
    baseAPI
      .get(`${baseUrl}v1/web/digital-risk/impersonationleaks/`, {
        params: { scan },
      })
      .then((res) => {
        console.log("getVIPProtection", res.data);
        setVIPProtection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSocialLeaks = () => {
    baseAPI
      .get(`${baseUrl}v1/web/digital-risk/socialleaks/`, {
        params: { scan },
      })
      .then((res) => {
        console.log("getSocialLeaks", res.data);
        setSocialLeaks(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (scan) {
      getSecureScore();
      getSecureScoreGraph();
      getVIPProtection();
      getSocialLeaks();
    }
  }, [scan]);

  return (
    <div className="overflow-hidden">
      {/* <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 pb-6"> */}
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-6 pb-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <SecureScore
            secureScoreGraphCategory={secureScoreGraphCategory}
            secureScoreGraphData={secureScoreGraphData}
            avg={secureScoreData?.average_score}
            progressBar={[
              {
                name: "Darkweb",
                value: secureScoreData?.darkweb_vulnerability || 0,
              },
              {
                name: "Brand",
                value: secureScoreData?.brand_vulnerability || 0,
              },
              {
                name: "Secrets",
                value: secureScoreData?.secrets_vulnerability || 0,
              },
            ]}
            tooltip="Truzta Risk Score reflects the current state of your organization risk.
            A higher score denotes weaker security and more risks.
            A lower score denotes stronger security and less risks."
          />
        </div>
        <div className={`lg:col-span-2`}>
          <BrandTreat isDarkMode={isDarkMode} data={socialLeaks} />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <DonutChart
              title="VIP Protection"
              type="normal"
              labels={[
                // "Facebook",
                "Twitter",
                "Youtube",
                "Instagram",
              ]}
              colors={[
                // "#F14D34",
                "#FDCA3B",
                "#E8001D",
                "#148842",
              ]}
              chartData={[
                // VIPProtection?.facebook,
                VIPProtection?.twitter,
                VIPProtection?.youtube,
                VIPProtection?.instagram,
              ]}
              conditionType="Leaks"
              total={
                // (VIPProtection?.facebook || 0) +
                (VIPProtection?.twitter || 0) +
                (VIPProtection?.youtube || 0) +
                (VIPProtection?.instagram || 0)
              }
              legends={[
                // {
                //   name: `Facebook - ${
                //     VIPProtection?.facebook || 0
                //   }`,
                //   color: "#F14D34",
                // },
                {
                  name: `Twitter - ${VIPProtection?.twitter || 0}`,
                  color: "#FDCA3B",
                },
                {
                  name: `Youtube - ${VIPProtection?.youtube || 0}`,
                  color: "#E8001D",
                },
                {
                  name: `Instagram - ${VIPProtection?.instagram || 0}`,
                  color: "#148842",
                },
              ]}
              // navigateLink="#Impersonation"
              tooltip="Gain insights into impersonation attempts targeting brands and executives on social media."
            />
          </div>
          {/* <CircularProgressBar  initialPercentage={9.0} title="Brand Score" isDarkMode={isDarkMode} /> */}
        </div>
      </div>
      <Impersonation />
    </div>
  );
};

export default BrandThreatIntelligenceComponent;
