import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { toast } from "react-toastify";
import { AiOutlineCalendar } from "react-icons/ai";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import { STATUS } from "../../../../../screens/compliance/IncidentManagement";
import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../../helpers";
import fileUpload from "../../../../../utils/fileUpload";

export default function AddIncidentManagement({
  visible,
  onHide,
  currentDetails,
}) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [users, setUsers] = useState([]);
  const [openIncidentDate, setOpenIncidentDate] = useState(false);

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
  }, []);

  const handleAPI = (e) => {
    if (currentDetails) {
      complianceAPI
        .patch(`/incidents/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Incident updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/incidents/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Incident created successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    const file = e.target.file.files[0];

    if (!file && !currentDetails) {
      toast.error("Please upload a file");
      e.target.submit.removeAttribute("disabled");
      return;
    }

    if (file) {
      fileUpload("v1/compliance/attachments/", file)
        .then((res) => {
          data.attachments = [res.data.id];
          handleAPI(e);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error in uploading attachment");
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      handleAPI(e);
    }
  };

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };
  return (
    <Sidebar visible={visible} onHide={onHide} position="right">
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Incident`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Incident`}</p>
      <form onSubmit={(e) => handleCreate(e)} className="w-full">
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Title</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="Enter title"
            value={data.title}
            required
            onChange={(e) => {
              setData({ ...data, title: e.target.value });
              setError({ ...error, title: false });
            }}
          />
          {error.title && <p className="text-red-500 text-sm">{error.name}</p>}
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Category</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="Enter Category"
            value={data.category}
            required
            onChange={(e) => {
              setData({ ...data, category: e.target.value });
              setError({ ...error, category: false });
            }}
          />
          {error.category && (
            <p className="text-red-500 text-sm">{error.category}</p>
          )}
        </div>
        <div className="w-full">
          <label className="text-sm font-normal text-[#686868]">
            Incident Date
          </label>
          <div className="relative">
            <input
              value={DateTimeToDate(data?.date_time)}
              placeholder="___ __, ____"
              className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
            />
            <div
              className="absolute cursor-pointer top-[12px] right-[12px]"
              onClick={() => setOpenIncidentDate(!openIncidentDate)}
            >
              <AiOutlineCalendar />
            </div>
          </div>
          {openIncidentDate && (
            <div className="mx-auto">
              <div className="">
                <Calendar
                  value={new Date(data?.date_time)}
                  onChange={(e) => {
                    setData({
                      ...data,
                      date_time: DateSelectionToDjangoFormat(e.value),
                    });
                    setError({ ...error, date_time: false });
                    setOpenIncidentDate(false);
                  }}
                  inline
                  style={{ width: "360px" }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Reported By</label>
          <Dropdown
            value={data?.reported_by}
            onChange={(e) => setData({ ...data, reported_by: e.value })}
            options={users.map((u) => ({
              label: u.email,
              value: u.id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Investigator</label>
          <Dropdown
            value={data?.investigator}
            onChange={(e) => setData({ ...data, investigator: e.value })}
            options={users.map((u) => ({
              label: u.email,
              value: u.id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Details</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Enter description"
            value={data?.details}
            required
            onChange={(e) => {
              setData({ ...data, details: e.target.value });
              setError({ ...error, details: false });
            }}
          />
          {error.details && (
            <p className="text-red-500 text-sm">{error.details}</p>
          )}
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm" for="file_input">
            Attach File
          </label>
          <input
            className="w-full cursor-pointer rounded-lg border-[1px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter dark:file:text-gray-500 file:py-2 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:focus:border-primary"
            name="file"
            type="file"
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Status</label>
          <Dropdown
            value={data?.status}
            onChange={(e) => setData({ ...data, status: e.value })}
            options={STATUS}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            id="submit"
          >
            {currentDetails ? "Save" : "Add"}
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={cleanClose}
            type="reset"
          >
            Back
          </button>
        </div>
      </form>
    </Sidebar>
  );
}
