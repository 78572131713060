import { useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { Sidebar } from "primereact/sidebar";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { DateSelectionToDjangoFormat, DateTimeToDate } from "../../helpers";
import secureCodeAPI from "../../Api/secureCodeAPI";
import HandleResponseError from "../../utils/error";
import {
  handleSubmitButtonPress,
  handleSubmitButtonRelease,
} from "../../utils/utils";
import { STATUS } from "./index";

export default function UpdateStatus({
  visible,
  onHide,
  currentDetails,
  setCurrentDetails,
}) {
  const [data, setData] = useState({});
  const [openDate, setOpenDate] = useState(false);

  const handleCreate = (e) => {
    handleSubmitButtonPress(e);
    secureCodeAPI
      .patch(`/repositories/${currentDetails.id}/`, data)
      .then((res) => {
        if (!res.id) {
          HandleResponseError(res);
        } else {
          toast.success("Repository updated successfully");
          setCurrentDetails({
            ...currentDetails,
            status: STATUS.find((status) => status.value === data.status).label,
            deadline: data.deadline,
          });
          cleanClose();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleSubmitButtonRelease(e);
      });
  };

  const cleanClose = () => {
    onHide();
    setData({});
  };

  useEffect(() => {
    if (currentDetails)
      setData({
        status: STATUS.find((status) => status.label === currentDetails.status)
          .value,
        deadline: currentDetails.deadline,
      });
  }, [currentDetails]);

  return (
    <Sidebar visible={visible} position="right" onHide={onHide}>
      <p className="font-bold text-2xl">Update Status</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Status</label>
            <Dropdown
              value={data?.status}
              onChange={(e) => setData({ ...data, status: e.value })}
              options={STATUS}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">
              Deadline
            </label>
            <div className="relative">
              <input
                required
                value={DateTimeToDate(data?.deadline)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenDate(!openDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={
                      data?.deadline ? new Date(data?.deadline) : new Date()
                    }
                    onChange={(e) => {
                      setData({
                        ...data,
                        deadline: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                    minDate={new Date()}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Update" : "Create"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
