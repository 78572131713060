import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { LineWave } from "react-loader-spinner";

import { cspmAPI } from "../../../Api/CSPM/api";
import { DateTimeToDate } from "../../../helpers";
import { useSelector } from "react-redux";
import { getCSPMFilters } from "../../../redux-toolkit/redux/auth";

export default function ScanReportCloud({ handleCloseSideBar }) {
  const cspmFilters = useSelector(getCSPMFilters);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectReport, setSelectReport] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [error, setError] = useState({});

  const scanReport = [
    { name: "Executive Summary Report", code: "executive" },
    { name: "Detailed Report", code: "detailed" },
  ];

  const getData = () => {
    cspmAPI
      .get("/scan-history/")
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDownload = (e) => {
    e.preventDefault();
    if (!selectReport) {
      setError({ selectReport: true });
      return;
    }
    if (!reportType) {
      setError({ reportType: true });
      return;
    }
    setLoading(true);
    const scan = data.find((item) => item.id === selectReport);
    if (
      !cspmFilters?.length &&
      ((scan.detailed_report && reportType.code === "detailed") ||
        (scan.summary_report && reportType.code === "executive"))
    ) {
      if (reportType.code === "detailed") {
        // download from scan.detailed_report url
        window.open(scan.detailed_report, "_blank");
      } else {
        window.open(scan.summary_report, "_blank");
      }
      setLoading(false);
    } else {
      cspmAPI
        .get(
          `/report/${selectReport}`,
          {
            detailed: reportType.code === "detailed" ? "true" : "false",
            resource__labels__contains: JSON.stringify(cspmFilters),
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const blob = new Blob([res], { type: "application/pdf" });
          // Create a URL for the blob
          const url = URL.createObjectURL(blob);
          // Create a download link and trigger a click event to initiate the download
          const a = document.createElement("a");
          a.href = url;
          a.download = `${scan.provider}_${scan.account_id}_scan_report.pdf`; // Set the desired file name
          // document.body.appendChild(a);
          a.click();
          // Clean up by revoking the object URL
          URL.revokeObjectURL(url);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <form className="py-5 flex flex-col w-full login-style">
        <div className="w-full mb-5">
          <label className="block text-gray-500 text-sm ">Scanning Date</label>
          <Dropdown
            value={selectReport}
            onChange={(e) => {
              setError({
                ...error,
                selectReport: false,
              });
              setSelectReport(e.value);
            }}
            options={data.map((item) => {
              return {
                ...item,
                label: `${DateTimeToDate(item.started_at)} (${
                  item.provider
                } - ${item.account_id})`,
              };
            })}
            optionLabel="label"
            optionValue="id"
            placeholder="Scan Date"
            className=" w-full md:w-14rem h-[40px] my-1"
            required
          />
          {error?.selectReport && (
            <p className="text-red-500 text-sm">Scan Date is required</p>
          )}
        </div>
        <div className="w-full mb-10">
          <label className="block text-gray-500 text-sm ">Report type</label>
          <Dropdown
            value={reportType}
            onChange={(e) => {
              setError({
                ...error,
                reportType: false,
              });
              setReportType(e.value);
            }}
            options={scanReport}
            optionLabel="name"
            placeholder="Scan Report Type"
            className=" w-full md:w-14rem h-[40px] my-1"
          />
          {error?.reportType && (
            <p className="text-red-500 text-sm">Scan Report Type is required</p>
          )}
        </div>
        <div className="flex w-full pt-3">
          <button
            disabled={loading}
            className={`p-1 border rounded-[16px] border-[#AAAAAA] ${
              loading ? "button-disabled" : "button-bg-color"
            } h-[40px] w-full bg-[#181818] text-[#ffff]`}
            type="submit"
            onClick={handleDownload}
          >
            {/* Download */}
            {loading ? (
              <div className="flex items-center justify-center">
                <div className="absolute bottom-1 right-[12rem]">
                  <LineWave color="#fff" height={100} width={100} />
                </div>
                <span>Downloading...</span>
              </div>
            ) : (
              <>
                <span> Download</span>
              </>
            )}
          </button>
          {loading ? (
            ""
          ) : (
            <button
              onClick={handleCloseSideBar}
              className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full ml-2 "
              type="button"
            >
              Back
            </button>
          )}
        </div>
      </form>
    </>
  );
}
