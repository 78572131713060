import { useState, useContext, useEffect, createElement, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { useSelector } from "react-redux";
import { FiArrowUpRight } from "react-icons/fi";
import { Sidebar } from "primereact/sidebar";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoAlertCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";

import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import TableFilterApiBase from "../../../../Api/FIlterAPI/base";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { getFilter } from "../../../../utils/utils";

export default function SocialMedia({ platform }) {
  const { isDarkMode } = useContext(ThemeContext);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.scan_id;

  const api = new TableFilterApiBase("v1/web/digital-risk/socialleaks/filter/");
  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({
    scan,
    platform,
  });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    {
      field: "keyword",
      header: "Keyword",
      value: filterData?.keyword,
    },
    {
      field: "platform",
      header: "Platform",
      value: filterData?.platform,
    },
    {
      field: "sentimental_analysis",
      header: "Analysis",
      value: filterData?.sentimental_analysis,
    },
    {
      field: "post_url",
      header: "Source",
      value: filterData?.post_url,
    },
    { field: "user_url", header: "Account" },
    { field: "update_field_name", header: "Tags", value: [] },
    { field: "update_field_name", header: "Status", value: [] },
  ];

  const getFilterData = () => {
    api
      .get({ scan, platform })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    api
      .post({ ...getFilter(filterData, filterParams), platform, scan })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scan, platform]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  const AnalysisView = (type, text) => {
    let icon = null;
    let color = null;
    switch (type) {
      case "positive":
        color = "text-green-600	";
        icon = IoMdCheckmarkCircleOutline;
        break;
      case "negative":
        color = "text-red-600	";
        icon = IoAlertCircleOutline;
        break;
      default:
        color = "text-yellow-600	";
        icon = IoRemoveCircleOutline;
        break;
    }
    return (
      <div className={`flex content-center ${color}`}>
        <div className="text-xl">{createElement(icon)}</div>
        {text && text}
      </div>
    );
  };
  return (
    <div className="ssl-active">
      <Sidebar
        visible={currentDetails}
        position="right"
        onHide={() => setCurrentDetails(null)}
        className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
        closeOnEscape={false}
        dismissable={false}
      >
        <p className="font-bold text-2xl text-center">Social Media</p>
        <div className="px-6">
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Keywords</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.keyword}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Platform</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.platform}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Analysis</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.sentimental_analysis &&
                  AnalysisView(
                    currentDetails.sentimental_analysis,
                    currentDetails.sentimental_analysis
                  )}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Leaked Secrets</h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.network} */}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Source</h1>
              <p className="text-xs text-blue-700	 font-normal leading-5">
                <a
                  href={currentDetails?.post_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {currentDetails?.post_url}
                </a>
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Account</h1>
              <p className="text-xs text-blue-700	 font-normal leading-5">
                <a
                  href={currentDetails?.user_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {currentDetails?.user_url}
                </a>
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/1">
              <h1 className="text-sm text-color font-bold">Content</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.tweets}
              </p>
            </div>
          </div>
        </div>
      </Sidebar>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {platform
              ? platform.charAt(0).toUpperCase() + platform.slice(1)
              : "Social Media"}{" "}
            <span> ({data?.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              paginatorTemplate={paginationTemplate()}
              removableSort
              value={data}
              ref={ref}
              rows={10}
              sortField="sentimental_analysis"
              sortOrder={1}
              globalFilter={globalFilter}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => {
                if (col.field === "sentimental_analysis") {
                  return (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={(rowData) => {
                        return AnalysisView(rowData[col.field]);
                      }}
                      sortable
                    />
                  );
                }
                return (
                  <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                  />
                );
              })}
              <Column
                header="Full info"
                body={(rowData) => {
                  return (
                    <button
                      onClick={() => {
                        setCurrentDetails(rowData);
                      }}
                    >
                      <FiArrowUpRight size={20} color="#074EE8" />
                    </button>
                  );
                }}
                exportable={false}
              />
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}
