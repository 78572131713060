import baseAPI, { baseUrl } from "../Repository.js"; //just axios instance
import { store } from "../../redux-toolkit/store/store.js";
import { removeAuthenticationData } from "../../utils/constants/index.jsx";

class API {
  constructor(path) {
    this.baseUrl = baseUrl;
    this.endpoint = this.baseUrl + path;
  }

  get(path, params, config = null) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;

      let user = null;
      if (params && "user" in params) user = params.user;
      else user = store.getState()?.auth?.userDetails?.org_id || null;

      baseAPI
        .get(url, { params: { ...params, user }, ...config })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  post(path, data) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;

      let user = null;
      if (data && "user" in data) user = data.user;
      else user = store.getState()?.auth?.userDetails?.org_id || null;

      baseAPI
        .post(url, { ...data, user })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  patch(path, data) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;

      let user = null;
      if (data && "user" in data) user = data.user;
      else user = store.getState()?.auth?.userDetails?.org_id || null;

      baseAPI
        .patch(url, { ...data, user })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  postFile(path, data) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;

      let user = null;
      if (data && "user" in data) user = data.user;
      else user = store.getState()?.auth?.userDetails?.org_id || null;

      data.append("user", user);
      baseAPI
        .post(url, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  delete(path) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .delete(url)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }
}

const cspmAPI = new API("v1/cspm");
const complianceAPI = new API("v1/compliance");
const searchAPIView = new API("v1/operations");
const subscriptionAPI = new API("v1/subscription");

export { cspmAPI, complianceAPI, subscriptionAPI };
