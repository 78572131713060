import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { ThemeContext } from "../../../../context/ThemeContext";
import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../modules/SkeletonAnimation/SkeletonDataTable";

import paginationTemplate from "../../../../utils/paginationTemplate";
import AddEmployee from "../../../../modules/home/components/compliance/people/AddEmployee";
import { complianceAPI } from "../../../../Api/CSPM/api";
import IntegratePeople from "../../../../modules/home/components/compliance/people/IntegratePeople";
import { SidebarSlide } from "../../../../Component/Header/SidebarSlide";
import { setUserTableData } from "../../../../redux-toolkit/redux/TableData";
import ImportPeoples from "../../../../modules/home/components/compliance/people/ImportPeoples";
import { getFilter } from "../../../../utils/utils";
import React from "react";
import {
  isAuditorOrSubscriberOrAdminOrSuperAdmin,
  isSuperAdmin,
} from "../../../../utils/accessCheck";
import SecurityRole from "./SecurityRole";
import RolesAndResponsibilities from "./RolesAndResponsibilities";
import ResendInvitation from "../../../../modules/home/components/compliance/people/ResendInvitation";
import { DateTimeToDate } from "../../../../helpers";

export default function Employee() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDarkMode } = useContext(ThemeContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({
    status: [true, false],
  });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [view, setView] = useState(false);
  const [selectedTab, setSelectedTab] = useState("employee");
  const userInformation = JSON.parse(localStorage.getItem("userDetails"));

  const getFilterData = () => {
    complianceAPI
      .get("/employees/filter/")
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    setLoading(true);
    complianceAPI
      .post("/employees/filter/", {
        ...getFilter(filterData, filterParams),
        role: ["Employee", "Super User", "Editor"],
      })
      .then((res) => {
        setData(
          res.data.map((item) => ({
            ...item,
            status: item.status ? "Active" : "Pending",
          }))
        );
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartments(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
    getDepartment();
  }, []);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  const EmployeeComponent = () => (
    <>
      <div className="flex flex-wrap  dashboard-table">
        <p className="font-bold">
          Employee <span> ({data.length || 0}) </span>
        </p>
      </div>
      <>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          columns={columns}
          tableData={data}
          tableHeader={columns}
          dt={ref}
          isDarkMode={isDarkMode}
          btnName="Add"
          btnText={true}
          setVisibleRight={() => {
            setAddVisibility(true);
          }}
          IntegrationComponent={integrateOrImport}
        />
        {loading ? (
          <SkeletonDataTable
            columns={[...columns, { field: "full_info", header: "Full info" }]}
          />
        ) : (
          <DataTable
            ref={ref}
            removableSort
            value={data.map((i) => {
              return {
                ...i,
                department: departments?.find((j) => j.id == i.department)
                  ?.name,
                date_joined: DateTimeToDate(i.date_joined),
              };
            })}
            paginator
            rows={10}
            sortField="severity"
            sortOrder={-1}
            sortMode="multiple"
            globalFilter={globalFilter}
            paginatorTemplate={paginationTemplate()}
            className={`${
              isDarkMode ? "dark-mode text-dark" : "text-color"
            } text-xs cursor-pointer`}
            onRowClick={({ data }) => {
              navigate(`/compliance/employee/${data.id}`);
            }}
          >
            {columns.map((col, i) => {
              return (
                <Column
                  key={i}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              );
            })}
            <Column
              key="action"
              field="action"
              header="Action"
              body={(rowData) => (
                <SplitButton
                  icon={<FiMoreVertical size={24} />}
                  className="p-button-text outline-none split-style"
                  model={[
                    {
                      label: "Edit",
                      icon: "pi pi-refresh",
                      command: () => {
                        setCurrentDetails(rowData);
                        dispatch(setUserTableData(rowData));
                        setView(false);
                      },
                    },
                    {
                      label: "View",
                      icon: "pi pi-times",
                      command: () => {
                        setCurrentDetails(rowData);
                        dispatch(setUserTableData(rowData));
                        setView(true);
                      },
                    },
                  ]}
                ></SplitButton>
              )}
            />
          </DataTable>
        )}
      </>
    </>
  );

  const columns = [
    {
      field: "first_name",
      header: "First Name",
      value: filterData?.first_name,
    },
    { field: "last_name", header: "Last Name", value: filterData?.last_name },
    {
      field: "email",
      header: "Email",
      value: filterData?.email,
    },
    {
      field: "department",
      header: "Department",
    },
    {
      field: "scope",
      header: "Scope",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Active",
          value: true,
        },
        {
          label: "Pending",
          value: false,
        },
      ],
    },
  ];

  const tabs = [
    { id: 1, tabName: "Employee", tab: "employee" },
    { id: 2, tabName: "Security Roles", tab: "sec-role" },
    { id: 3, tabName: "Roles & Responsibilities", tab: "role-and-res" },
  ];

  const integrateOrImport = () => {
    return (
      <>
        <ImportPeoples />
        <IntegratePeople />
      </>
    );
  };

  const showActiveTabComponent = () => {
    switch (selectedTab) {
      case "employee":
        return <EmployeeComponent />;
      case "sec-role":
        return <SecurityRole />;
      case "role-and-res":
        return <RolesAndResponsibilities />;
      default:
        return;
    }
  };

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div
          className={`flex gap-5 border-b-2 mb-6 border-[#DEDEDE] ${
            isDarkMode ? "tab-dark-border" : "border-color"
          }`}
        >
          {tabs.map((tab) => {
            if (
              tab.admin &&
              !isAuditorOrSubscriberOrAdminOrSuperAdmin(userInformation?.role)
            )
              return;
            if (tab.superAdmin && !isSuperAdmin(userInformation.role)) return;
            if (
              ["departments", "entities"].includes(tab.tab) &&
              !userInformation.compliance
            )
              return;
            return (
              <div
                key={tab.id}
                className="cursor-pointer py-2"
                onClick={() => setSelectedTab(tab.tab)}
              >
                <p
                  className={`capitalize text-base cursor-pointer ${
                    isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                  }  ${
                    selectedTab === tab.tab
                      ? "selected-tab-color font-medium"
                      : "font-normal"
                  }`}
                >
                  {tab.tabName}
                </p>
                <div
                  className={`${
                    selectedTab === tab.tab
                      ? "selected-tab-color relative border-b-2"
                      : ""
                  } top-[0.53rem] `}
                ></div>
              </div>
            );
          })}
        </div>
        <div className="">{showActiveTabComponent()}</div>
      </div>
      <AddEmployee
        visible={addVisibility}
        onHide={() => {
          setAddVisibility(false);
          getFilterData();
        }}
      />
      <div className="side-user-info-style">
        <SidebarSlide
          title="User Information"
          description="Edit the user account"
          visibleRight={currentDetails}
          setVisibleRight={setCurrentDetails}
          userTableViewData={view}
          role={[]}
          getUserInformationTableData={getData}
        />
      </div>
    </>
  );
}
