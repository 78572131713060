import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiArrowUpRight } from "react-icons/fi";
import { Sidebar } from "primereact/sidebar";

import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import TableFilterApiBase from "../../../../Api/FIlterAPI/base";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { getFilter } from "../../../../utils/utils";

export default function JavaScriptLeaks() {
  const { isDarkMode } = useContext(ThemeContext);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;

  const api = new TableFilterApiBase(
    "v1/web/digital-risk/secretsleaks/filter/"
  );
  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({
    scan,
    platform: "JavaScript",
  });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    {
      field: "keyword",
      header: "Keyword",
      value: filterData?.keyword,
    },
    { field: "update_field_name", header: "Secret Type", value: [] },
    { field: "update_field_name", header: "Severity", value: [] },
    { field: "secreats", header: "Leaked Secrets", value: filterData?.secrets },
    { field: "update_field_name", header: "Source", value: [] },
    { field: "update_field_name", header: "Tags", value: [] },
    { field: "update_field_name", header: "Status", value: [] },
  ];

  const getFilterData = () => {
    api
      .get({ scan, platform: "JavaScript" })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    api
      .post({
        ...getFilter(filterData, filterParams),
        scan,
        platform: "JavaScript",
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <div className="ssl-active">
      <Sidebar
        visible={currentDetails}
        position="right"
        onHide={() => setCurrentDetails(null)}
        className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
        closeOnEscape={false}
        dismissable={false}
      >
        <p className="font-bold text-2xl text-center">Java Scripts</p>
        <div className="px-6">
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Severity</h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.keyword} */}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Secret Type</h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.dork_type}  */}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">URL</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.url}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Leaked Secrets</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.secreats}
              </p>
            </div>
          </div>
        </div>
      </Sidebar>
      {/* <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            JavaScript Leak <span> ({data.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              ref={ref}
              paginatorTemplate={paginationTemplate()}
              removableSort
              value={data}
              rows={10}
              globalFilter={globalFilter}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              ))}
              <Column
                header="Full info"
                body={(rowData) => {
                  return (
                    <button
                      onClick={() => {
                        setCurrentDetails(rowData);
                      }}
                    >
                      <FiArrowUpRight size={20} color="#074EE8" />
                    </button>
                  );
                }}
                exportable={false}
              />
            </DataTable>
          )}
        </div>
      </div> */}
    </div>
  );
}
