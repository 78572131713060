import { useEffect, useState } from "react";

import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import AddControls, {
  DOMAINS,
  GROUP,
} from "../../modules/home/components/compliance/AddControls";
import { complianceAPI } from "../../Api/CSPM/api";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";

export default function Controls() {
  const [employee, setEmployee] = useState([]);
  const [frameworks, setFrameworks] = useState([]);
  const framework = useSelector(getUserDetails)?.framework;

  const columns = [
    { field: "name", header: "Control Name" },
    {
      field: "code",
      header: "Control Code",
    },
    {
      field: "framework",
      header: "Framework",
      type: "select",
      value: frameworks.map((i) => ({
        label: i.name,
        value: i.id,
      })),
    },
    {
      field: "domain",
      header: "Control Domain",
    },
    {
      field: "group",
      header: "Function Group",
      type: "select",
      value: GROUP,
    },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: employee.map((e) => ({
        label: e.email,
        value: e.id,
      })),
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        { label: "NON COMPLIANT", value: "NON COMPLIANT" },
        { label: "COMPLIANT", value: "COMPLIANT" },
        { label: "NOT APPLICABLE", value: "NOT APPLICABLE" },
      ],
    },
    {
      field: "out_of_scope",
      header: "Scope",
      type: "select",
      value: [
        { label: "In Scope", value: false },
        { label: "Out of Scope", value: true },
      ],
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getFrameworks = () => {
    complianceAPI
      .post("/framework/filter/")
      .then(({ data }) => {
        setFrameworks(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
    getFrameworks();
  }, []);

  return (
    <ComplianceTable
      title="Controls"
      columns={columns}
      url_path="/controls/"
      AddComponent={AddControls}
      filter={framework}
    />
  );
}
