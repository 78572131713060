import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import { useParams } from "react-router-dom";

export default function AddAccess({
  visible,
  onHide,
  currentDetails,
  isPublic = false,
}) {
  const { token } = useParams();
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const host = window.location.hostname.split(".", 1);
  const subdomain = host[0];

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (isPublic) {
      console.log(data);
      complianceAPI
        .post(`/trustcenter/${token || subdomain}/`, data)
        .then((res) => {
          if (res.status === "success") {
            toast.success("Request sent successfully");
          } else {
            HandleResponseError(res);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
        });
      e.target.submit.removeAttribute("disabled");
      cleanClose();
      return;
    }
    if (currentDetails) {
      complianceAPI
        .patch(`/trust-center/access/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Access updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/trust-center/access/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Access added successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      onHide={cleanClose}
      position="right"
      className="w-full md:w-20rem lg:w-30rem custom-sidebar-width"
    >
      <p className="font-bold text-2xl">{`${
        isPublic ? "Request" : currentDetails ? "Edit" : "Create New"
      } Access`}</p>
      <p className="text-sm py-1">
        {`${
          isPublic ? "Request a new" : currentDetails ? "Edit" : "Create a new"
        } access`}
      </p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Company Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Company Name"
              value={data.company}
              required
              onChange={(e) => {
                setData({ ...data, company: e.target.value });
                setError({ ...error, company: false });
              }}
            />
            {error.company && (
              <p className="text-red-500 text-sm">{error.company}</p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Address</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="address"
              value={data.address}
              required
              onChange={(e) => {
                setData({ ...data, address: e.target.value });
                setError({ ...error, address: false });
              }}
            />
            {error.address && (
              <p className="text-red-500 text-sm">{error.address}</p>
            )}
          </div>
          <div className="flex w-full gap-3">
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm ">State</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="State"
                value={data.state}
                required
                onChange={(e) => {
                  setData({ ...data, state: e.target.value });
                  setError({ ...error, state: false });
                }}
              />
              {error.state && (
                <p className="text-red-500 text-sm">{error.state}</p>
              )}
            </div>
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm">Country</label>
              <input
                className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="Country"
                value={data.country}
                required
                onChange={(e) => {
                  setData({ ...data, country: e.target.value });
                  setError({ ...error, country: false });
                }}
              />
              {error.country && (
                <p className="text-red-500 text-sm">{error.country}</p>
              )}
            </div>
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Description"
              value={data.description}
              //   required
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
                setError({ ...error, description: false });
              }}
            />
            {error.description && (
              <p className="text-red-500 text-sm">{error.description}</p>
            )}
          </div>
          <p className="font-bold text-xl">User Details</p>
          <div className="flex w-full gap-3">
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm ">First Name</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="First Name"
                value={data.first_name}
                required
                onChange={(e) => {
                  setData({ ...data, first_name: e.target.value });
                  setError({ ...error, first_name: false });
                }}
              />
              {error.first_name && (
                <p className="text-red-500 text-sm">{error.first_name}</p>
              )}
            </div>
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm">Last Name</label>
              <input
                className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="Last Name"
                value={data.last_name}
                required
                onChange={(e) => {
                  setData({ ...data, last_name: e.target.value });
                  setError({ ...error, last_name: false });
                }}
              />
              {error.last_name && (
                <p className="text-red-500 text-sm">{error.last_name}</p>
              )}
            </div>
          </div>
          <div className="flex w-full gap-3">
            <div className="w-1/2 pt-3">
              <label className="block text-gray-500 text-sm ">Email</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="email"
                placeholder="Email"
                value={data.email}
                required
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                  setError({ ...error, email: false });
                }}
              />
              {error.email && (
                <p className="text-red-500 text-sm">{error.email}</p>
              )}
            </div>
            {!isPublic && (
              <div className="w-1/2 pt-3">
                <label className="block text-gray-500 text-sm">Status</label>
                <Dropdown
                  value={data.status}
                  onChange={(e) => setData({ ...data, status: e.value })}
                  options={[
                    {
                      label: "Active",
                      value: true,
                    },
                    {
                      label: "Inactive",
                      value: false,
                    },
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select"
                  className="w-full md:w-14rem my-1"
                />
              </div>
            )}
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {isPublic ? "Request" : currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
