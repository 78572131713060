import SecureCodeReviewTable from "../../../modules/home/components/secureCodeReview/secureCodeReviewTable";
import ViewVulnerability from "./ViewVulnerabilities";

export default function Vulnerabilities({ data, employees }) {
  const columns = [
    {
      field: "rule_id",
      header: "Vulnerability ID",
    },
    {
      field: "details",
      header: "Description",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        { value: "open", label: "Open" },
        { value: "closed", label: "Closed" },
      ],
    },
    {
      field: "severity",
      header: "Severity",
    },
    {
      field: "full-info",
      header: "Full info",
      type: "full_info",
    },
  ];

  return (
    <SecureCodeReviewTable
      key={data.last_scan}
      title="Vulnerabilities"
      columns={columns}
      url_path="/vulnerabilities/"
      allowAdd={false}
      filter={{ scan: data.last_scan }}
      ViewComponent={ViewVulnerability}
    />
  );
}
