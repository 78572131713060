import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import CreateUserForm from "../../modules/sidebar/components/CreateUserForm";
import UserInformationForm from "../../modules/sidebar/components/UserInformationForm";
import ChangePasswordForm from "../../modules/sidebar/components/ChangePasswordForm";
import MultifactorAuthentication from "../../modules/sidebar/components/MultifactorAuthentication";
import MultifactorOTPverify from "../../modules/sidebar/components/MultifactorOTPVerify";
import MultifactorScanVerify from "../../modules/sidebar/components/MultifactorScanVerify";
import PricingCalculator from "../../modules/sidebar/components/PricingCalculator";
import CreateProject from "../../modules/sidebar/components/CreateProject";
import InitiateScanForm from "../../modules/sidebar/components/InitiateScanForm";
import ScanHistory from "../../modules/sidebar/components/ScanHistory";
import ScanHistoryDRP from "../../modules/sidebar/components/ScanHistoryDRP";
import ScanHistoryBrand from "../../modules/sidebar/components/ScanHistoryBrand";
import WebApplicationVulnerability from "../../modules/sidebar/components/WebApplicationVulnerability";
import TableSidebarFilter from "../../modules/sidebar/components/TableSidebarFilter";
import { filter } from "../../assets/svg";
import { GoFilter } from "react-icons/go";
import VulnerabilityForm from "../../modules/sidebar/components/VulnerabilityForm";
import ScanReport from "../../modules/sidebar/components/ScanReport";
import ScheduleScanForm from "../../modules/sidebar/components/initiateScanForms/ScheduleScanForm";
import TargetInitiateScan from "../../modules/sidebar/components/TargetInitiateScan";
import CheckboxSample from "../../modules/sidebar/components/CheckboxSample";
import WhoisFullinfo from "../../modules/sidebar/components/WhoisFullinfo";
import DarkWebInformation from "../../modules/sidebar/components/DarkWebInformation";
import ImportVulnerability from "../../modules/sidebar/components/ImportVulnerability";
import AssignAgent from "../../modules/sidebar/components/AssignAgents";
import ScanHistoryCSPM from "../../modules/sidebar/components/ScanHistoryCSPM";
import ScanReportCloud from "../../modules/sidebar/components/ScanReportCloud";
import CloudIntegrateSideBar from "../../modules/home/components/Integrations/Sidebars/CloudIntegrateSideBar";
import WebHookSideBar from "../../modules/home/components/Integrations/Sidebars/WebHookSideBar";
import APIKeySideBar from "../../modules/home/components/Integrations/Sidebars/APIKeySideBar";
import APIKeyCompanySideBar from "../../modules/home/components/Integrations/Sidebars/Apikeycompanysidebar";
import APIKeyandsubdomainSideBar from "../../modules/home/components/Integrations/Sidebars/Apikeysubdomainsidebar";
import AddorEditRole from "../../screens/compliance/people/Employee/AddorEditRole";
import AddorEditAssignee from "../../screens/compliance/people/Employee/AddorEditAssignee";

export const SidebarSlide = (props) => {
  const {
    title,
    description,
    navigate,
    visibleRight,
    setVisibleRight,
    selectedSubscriptionPlan,
    userTableViewData,
    role,
    getUserInformationTableData,
    customWidth,
    sideBarVocIntelligenceDetail,
    loadingSidebarVocDetail,
    getProjectTableData,
    scanHistoryData,
    userDetails,
    columns,
    filter,
    type,
    subDomianEnumeration,
    planTypeData,
    setFilterParams,
    filterParams,
    plansData,
    darkweb,
    getTableFilterData,
    editData,
    dashboardPage,
    orgUsers,
  } = props;

  const [authInstruction, setAuthInstruction] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [mobileVerify, setMobileVerify] = useState();
  const [subDomainEnumeration, setSubDomainEnumeration] = useState(false);
  const [domainAdded, setDomainAdded] = useState(false);
  const [initiateScan, setInitiateScan] = useState(false);

  const handleCloseSideBar = () => {
    setVisibleRight(false);
    setSubDomainEnumeration(false);
    setDomainAdded(false);
    localStorage.removeItem("domainName");
  };
  const handleFinish = () => {
    console.log("SAAS");
    setSubDomainEnumeration(false);
    setDomainAdded(true);
  };

  return (
    <Sidebar
      visible={visibleRight}
      position="right"
      onHide={() => setVisibleRight(false)}
      className={`w-full md:w-20rem lg:w-30rem ${
        customWidth ? "custom-sidebar-width" : "sidebar-style"
      }`}
      closeOnEscape={false}
      dismissable={false}
    >
      {customWidth ? (
        <p className="font-bold text-2xl text-center">{title}</p>
      ) : (
        <>
          {filter ? (
            <div className="flex justify-between py-2">
              <p className="font-bold text-2xl">{title} </p>
              <GoFilter size={24} style={{ color: "#074EE8" }} />
            </div>
          ) : (
            <>
              {domainAdded ? (
                <p className="font-bold text-2xl">Domain is added</p>
              ) : (
                <p className="font-bold text-2xl">{title} </p>
              )}
            </>
          )}
        </>
      )}
      {authInstruction ? (
        <p className="text-sm py-1">Follow the instructions below</p>
      ) : (
        <>
          {subDomainEnumeration ? (
            <p className="text-sm py-1">
              Subdomain Enumeration is in progress, please wait for few mins or
              come back later
            </p>
          ) : (
            <>
              {domainAdded ? "" : <p className="text-sm py-1">{description}</p>}
            </>
            //  <p className="text-sm py-1">{description}</p>
          )}
        </>
      )}

      {visibleRight && title === "Add Assignee" && (
        <AddorEditAssignee
          handleCloseSideBar={handleCloseSideBar}
          setVisibleRight={setVisibleRight}
          add={true}
          getTableFilterData={getTableFilterData}
        />
      )}

      {visibleRight && title === "Edit Assignee" && (
        <AddorEditAssignee
          handleCloseSideBar={handleCloseSideBar}
          setVisibleRight={setVisibleRight}
          currentDetails={visibleRight}
          getTableFilterData={getTableFilterData}
        />
      )}

      {visibleRight && title === "Edit Role" && (
        <AddorEditRole
          handleCloseSideBar={handleCloseSideBar}
          setVisibleRight={setVisibleRight}
          currentDetails={visibleRight}
          getTableFilterData={getTableFilterData}
        />
      )}

      {visibleRight && title === "Add Role" && (
        <AddorEditRole
          handleCloseSideBar={handleCloseSideBar}
          setVisibleRight={setVisibleRight}
          add={true}
          getTableFilterData={getTableFilterData}
        />
      )}

      {visibleRight && title === "Create User" && (
        <CreateUserForm
          handleCloseSideBar={handleCloseSideBar}
          setVisibleRight={setVisibleRight}
          role={role}
          orgUsers={orgUsers}
          getUserInformationTableData={getUserInformationTableData}
        />
      )}
      {visibleRight && title === "User Information" && (
        <UserInformationForm
          handleCloseSideBar={handleCloseSideBar}
          setVisibleRight={setVisibleRight}
          userTableViewData={userTableViewData}
          role={role}
          getUserInformationTableData={getUserInformationTableData}
          orgUsers={orgUsers}
        />
      )}
      {visibleRight && title === "Change password" && (
        <ChangePasswordForm
          handleCloseSideBar={handleCloseSideBar}
          setAuthInstruction={setAuthInstruction}
          setVisibleRight={setVisibleRight}
        />
      )}
      {visibleRight &&
        title === "Multifactor Authentication" &&
        !authInstruction && (
          <MultifactorAuthentication
            setAuthInstruction={setAuthInstruction}
            setVisibleRight={setVisibleRight}
            setEmailVerify={setEmailVerify}
            setMobileVerify={setMobileVerify}
          />
        )}
      {visibleRight &&
        title === "Multifactor Authentication" &&
        authInstruction &&
        !emailVerify &&
        !mobileVerify && (
          <MultifactorScanVerify
            setVisibleRight={setVisibleRight}
            authInstruction={authInstruction}
            setAuthInstruction={setAuthInstruction}
            setEmailVerify={setEmailVerify}
          />
        )}
      {visibleRight &&
        title === "Multifactor Authentication" &&
        authInstruction &&
        (emailVerify || mobileVerify) && (
          <MultifactorOTPverify
            setVisibleRight={setVisibleRight}
            emailVerify={emailVerify}
            mobileVerify={mobileVerify}
            authInstruction={authInstruction}
            setAuthInstruction={setAuthInstruction}
          />
        )}
      {visibleRight && title === "Pricing Calculator" && (
        <PricingCalculator
          handleCloseSideBar={handleCloseSideBar}
          selectedSubscriptionPlan={selectedSubscriptionPlan}
          planTypeData={planTypeData}
          plansData={plansData}
        />
      )}
      {visibleRight &&
        title === "Add Target" &&
        !subDomainEnumeration &&
        !domainAdded &&
        !initiateScan && (
          <CreateProject
            handleCloseSideBar={handleCloseSideBar}
            getProjectTableData={getProjectTableData}
            setSubDomainEnumeration={setSubDomainEnumeration}
            onFinish={() => handleFinish()}
          />
        )}

      {visibleRight &&
        title === "Add Target" &&
        subDomainEnumeration &&
        !domainAdded &&
        !initiateScan && (
          <CreateProject
            handleCloseSideBar={handleCloseSideBar}
            getProjectTableData={getProjectTableData}
            subDomainEnumeration={subDomainEnumeration}
            setVisibleRight={setVisibleRight}
            onFinish={() => handleFinish()}
          />
        )}

      {visibleRight &&
        title === "Add Target" &&
        !subDomainEnumeration &&
        domainAdded &&
        !initiateScan && (
          <CreateProject
            handleCloseSideBar={handleCloseSideBar}
            getProjectTableData={getProjectTableData}
            setSubDomainEnumeration={setSubDomainEnumeration}
            domainAdded={domainAdded}
            setDomainAdded={setDomainAdded}
            onFinish={() => handleFinish()}
          />
        )}
      {visibleRight && title === "Initiate Scan" && (
        <InitiateScanForm
          handleCloseSideBar={handleCloseSideBar}
          getProjectTableData={getProjectTableData}
          userDetails={userDetails}
        />
      )}

      {/* {visibleRight && title === "Initiate Scan" && (
        <InitiateScanForm
          handleCloseSideBar={handleCloseSideBar}
          getProjectTableData={getProjectTableData}
          userDetails={userDetails}
          
        />
      ) } */}

      {visibleRight && title === "Initiate Scan" && initiateScan && (
        <InitiateScanForm
          handleCloseSideBar={handleCloseSideBar}
          getProjectTableData={getProjectTableData}
          userDetails={userDetails}
        />
      )}

      {visibleRight && title === "Schedule Scanning" && (
        <InitiateScanForm
          handleCloseSideBar={handleCloseSideBar}
          getProjectTableData={getProjectTableData}
          showScheduleScan={true}
        />
      )}

      {visibleRight && title === "Scan History" && dashboardPage == "ASM" && (
        <ScanHistory
          scanHistoryData={scanHistoryData}
          userDetails={userDetails}
          handleCloseSideBar={handleCloseSideBar}
        />
      )}

      {visibleRight && title === "Scan History" && dashboardPage == "DRP" && (
        <ScanHistoryDRP
          scanHistoryData={scanHistoryData}
          userDetails={userDetails}
          handleCloseSideBar={handleCloseSideBar}
        />
      )}

      {visibleRight && title === "Scan History" && dashboardPage == "Brand" && (
        <ScanHistoryBrand
          scanHistoryData={scanHistoryData}
          userDetails={userDetails}
          handleCloseSideBar={handleCloseSideBar}
        />
      )}

      {visibleRight && title === "Scan History" && dashboardPage == "CPSM" && (
        <ScanHistoryCSPM
          userDetails={userDetails}
          handleCloseSideBar={handleCloseSideBar}
          scanHistoryData={scanHistoryData}
        />
      )}
      {visibleRight && title === "Vulnerability Intelligence" && (
        <WebApplicationVulnerability
          sideBarVocIntelligenceDetail={sideBarVocIntelligenceDetail}
          loadingSidebarVocDetail={loadingSidebarVocDetail}
        />
      )}

      {visibleRight && title === "Whois Information" && (
        <WhoisFullinfo
          sideBarVocIntelligenceDetail={sideBarVocIntelligenceDetail}
          loadingSidebarVocDetail={loadingSidebarVocDetail}
        />
      )}
      {visibleRight && title === "Filter" && (
        <TableSidebarFilter
          columns={columns}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
        // <CheckboxSample/>
      )}
      {visibleRight &&
        (title === "Add New Vulnerability" ||
          title === "Edit Vulnerability") && (
          <VulnerabilityForm
            handleCloseSideBar={handleCloseSideBar}
            getTableFilterData={getTableFilterData}
            editData={editData}
          />
        )}
      {visibleRight && title === "Import Vulnerability" && (
        <ImportVulnerability handleCloseSideBar={handleCloseSideBar} />
      )}
      {visibleRight && title === "Report" && dashboardPage !== "CPSM" && (
        <ScanReport handleCloseSideBar={handleCloseSideBar} />
      )}

      {visibleRight && title === "Report" && dashboardPage === "CPSM" && (
        <ScanReportCloud handleCloseSideBar={handleCloseSideBar} />
      )}

      {visibleRight && title === "Target Initiate Scanning" && (
        <TargetInitiateScan />
      )}

      {visibleRight && title === "Assign Agent" && (
        <AssignAgent
          visibleRight={visibleRight}
          handleCloseSideBar={handleCloseSideBar}
        />
      )}

      {visibleRight && title === "Cloud Integration" && (
        <CloudIntegrateSideBar
          handleCloseSideBar={handleCloseSideBar}
          editData={editData}
          getTableFilterData={getTableFilterData}
        />
      )}

      {visibleRight && title === "Webhook Integration" && (
        <WebHookSideBar
          handleCloseSideBar={() => {
            handleCloseSideBar();
            getTableFilterData();
          }}
          type={type}
        />
      )}

      {visibleRight && title === "API Key Integration" && (
        <APIKeySideBar
          handleCloseSideBar={() => {
            handleCloseSideBar();
            getTableFilterData();
          }}
          type={type}
        />
      )}

      {visibleRight && title === "API Keys Integration" && (
        <APIKeyandsubdomainSideBar
          handleCloseSideBar={() => {
            handleCloseSideBar();
            getTableFilterData();
          }}
          type={type}
        />
      )}
      {visibleRight && title === "API Keys and Company Id Integration" && (
        <APIKeyCompanySideBar
          handleCloseSideBar={() => {
            handleCloseSideBar();
            getTableFilterData();
          }}
          type={type}
        />
      )}

      {visibleRight && title && darkweb && (
        <DarkWebInformation
          sideBarVocIntelligenceDetail={sideBarVocIntelligenceDetail}
          // loadingSidebarVocDetail={loadingSidebarVocDetail}
        />
      )}
    </Sidebar>
  );
};
