import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ThemeContext } from "../../../context/ThemeContext";
import { DateTimeToDate } from "../../../helpers";
import SecureScore from "../../../modules/attackSurfaceManagement/components/SecureScore";
import DonutChart from "../../../common/Charts/DonutChart";
import ServiceStatus from "./ServiceStatus";

export default function GCPDashboard({ data }) {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const ITEMS = [
    {
      key: "API",
      title: "API",
      tooltip: "API",
    },
    {
      key: "BigQuery",
      title: "Databases - BigQuery",
      tooltip: "Databases - BigQuery",
    },
    {
      key: "CLB",
      title: "CLB",
      tooltip: "CLB",
    },
    {
      key: "Resource Manager",
      title: "Cloud Resource Manager",
      tooltip: "Cloud Resource Manager",
    },
    {
      key: "CloudBuild",
      title: "Cloud Build",
      tooltip: "Cloud Build",
    },
    {
      key: "Cloud Functions",
      title: "Cloud Functions",
      tooltip: "Cloud Functions",
    },
    {
      key: "Cloud Composer",
      title: "Composer",
      tooltip: "Composer",
    },
    {
      key: "Compute",
      title: "Compute",
      tooltip: "Compute",
    },
    {
      key: "Cryptographic Keys",
      title: "Cryptographic Keys",
      tooltip: "Cryptographic Keys",
    },
    {
      key: "Dataflow",
      title: "Dataflow",
      tooltip: "Dataflow",
    },
    {
      key: "DNS",
      title: "DNS",
      tooltip: "DNS",
    },
    {
      key: "Dataproc",
      title: "Data Processor",
      tooltip: "Data Processor",
    },
    {
      key: "Deployment Manager",
      title: "Deployment Manager",
      tooltip: "Deployment Manager",
    },
    {
      key: "IAM",
      title: "Identity and Access Management",
      tooltip: "Identity and Access Management",
    },
    {
      key: "Kubernetes",
      title: "Kubernetes",
      tooltip: "Kubernetes",
    },
    {
      key: "Logging",
      title: "Logging",
      tooltip: "Logging",
    },
    {
      key: "Pub/Sub",
      title: "Pub/Sub",
      tooltip: "Pub/Sub",
    },
    {
      key: "Security",
      title: "Security",
      tooltip: "Security",
    },
    {
      key: "Service Usage",
      title: "Service Usage",
      tooltip: "Service Usage",
    },
    {
      key: "Spanner",
      title: "Spanner",
      tooltip: "Spanner",
    },
    {
      key: "SQL",
      title: "SQL",
      tooltip: "SQL",
    },
    {
      key: "Storage",
      title: "Storage",
      tooltip: "Storage",
    },
    {
      key: "AI & ML",
      title: "Vertex AI",
      tooltip: "Vertex AI",
    },
    {
      key: "VPC Network",
      title: "VPC Network",
      tooltip: "VPC Network",
    },
  ];

  return (
    <div className="mb-2 grid grid-cols-3 gap-4">
      <div
        className={`lg:col-span-1 ${
          isDarkMode ? "header-dark" : "bg-white"
        } w-full rounded-2xl`}
      >
        <SecureScore
          secureScoreGraphCategory={
            data?.risk_score?.graph?.categories?.map(
              (item) => DateTimeToDate(item).split(",")[0]
            ) || []
          }
          secureScoreGraphData={data?.risk_score?.graph?.data || []}
          progressBar={[
            { name: "Critical", value: data?.risk_score?.critical || 0 },
            { name: "High", value: data?.risk_score?.high || 0 },
            { name: "Medium", value: data?.risk_score?.medium || 0 },
            { name: "Low", value: data?.risk_score?.low || 0 },
          ]}
          avg={data?.risk_score?.average || 0}
          tooltip="Truzta Risk Score reflects the current state of your cloud posture risk. A higher score denotes weaker security and more risks. A lower score denotes stronger security and less risks."
        />
      </div>
      <div
        className={`lg:col-span-1 ${
          isDarkMode ? "header-dark" : "bg-white"
        } w-full rounded-2xl`}
      >
        <div className="px-3 flex flex-col h-full">
          <DonutChart
            title="Audit Status"
            type="normal"
            labels={["Pass", "Fail", "Unknown"]}
            colors={["#148842", "#E8001D", "#F5B207"]}
            chartData={[
              data?.audit_status?.success || 0,
              data?.audit_status?.failure || 0,
              data?.audit_status?.unknown || 0,
            ]}
            conditionType="Issues"
            total={`${data?.audit_status?.success || 0}/${
              (data?.audit_status?.success || 0) +
              (data?.audit_status?.failure || 0) +
              (data?.audit_status?.unknown || 0)
            }`}
            legends={[
              {
                name: `Pass - ${data?.audit_status?.success || 0}`,
                color: "#148842",
              },
              {
                name: `Fail - ${data?.audit_status?.failure || 0}`,
                color: "#E8001D",
              },
              {
                name: `Unknown - ${data?.audit_status?.unknown || 0}`,
                color: "#F5B207",
              },
            ]}
            navigateLink={"./audit-status"}
            navigateState={{
              title: "Audit Status",
            }}
            tooltip="Audit status is the status of the audit that is being performed on the system."
          />
        </div>
      </div>
      <div
        className={`lg:col-span-1 ${
          isDarkMode ? "header-dark" : "bg-white"
        } w-full rounded-2xl`}
      >
        <div className="px-3 flex flex-col h-full">
          <DonutChart
            title="Severity"
            type="normal"
            labels={["Critical", "High", "Medium", "Low"]}
            colors={["#A30123", "#E8001D", "#F5B207", "#148842"]}
            chartData={[
              data?.severity?.critical || 0,
              data?.severity?.high || 0,
              data?.severity?.medium || 0,
              data?.severity?.low || 0,
            ]}
            conditionType="Issues"
            total={data?.audit_status?.failure || 0}
            legends={[
              {
                name: `Critical - ${data?.severity?.critical || 0}`,
                color: "#A30123",
              },
              {
                name: `High - ${data?.severity?.high || 0}`,
                color: "#E8001D",
              },
              {
                name: `Medium - ${data?.severity?.medium || 0}`,
                color: "#F5B207",
              },
              {
                name: `Low - ${data?.severity?.low || 0}`,
                color: "#148842",
              },
            ]}
            navigateLink="./severity"
            navigateState={{
              title: "Severity",
              filter: {
                status: 2,
              },
            }}
            tooltip="Cloud Security Vulnerabilities are classified into distinct categories, allowing for clear risk assessment and effective prioritization."
          />
        </div>
      </div>
      {ITEMS.map((item) => (
        <ServiceStatus
          key={item.key}
          title={item.title}
          tooltip={item.tooltip || item.title}
          data={{
            ...data?.service_status?.[
              item.key.replaceAll(" ", "_").toLowerCase()
            ],
            ...data?.service_severity?.[
              item.key.replaceAll(" ", "_").toLowerCase()
            ],
          }}
          onClick={() =>
            navigate(`./${item.key.replaceAll(" ", "-")}`, {
              state: {
                title: item.title,
                filter: {
                  resource__type: item.key,
                },
              },
            })
          }
        />
      ))}
    </div>
  );
}
