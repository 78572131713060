import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import fileUpload from "../../../../../utils/fileUpload";

export const NATURE_OF_FINDINGS = [
  {
    label: "Major",
    value: "major",
  },
  {
    label: "Minor",
    value: "minor",
  },
  {
    label: "Observation",
    value: "observation",
  },
  {
    label: "Opportunity for Improvement",
    value: "opportunity_for_improvement",
  },
];

export const STATUS = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  {
    label: "Need Review",
    value: "need_review",
  },
  {
    label: "Need Revision",
    value: "need_revision",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const FINDINGS = {
  "ISO 27001": [
    "Do you have information security policies that are approved by management, regularly reviewed, and updated?",
    "Are everyone's information security responsibilities clearly defined?",
    "Are tasks and responsibilities assigned to avoid conflicts of interest?",
    "Does management ensure all employees and contractors follow information security rules?",
    "Are your contacts with relevant authorities clear?",
    "Do you know which special interest groups or professional associations we should stay in touch with?",
    "Do youcollect and analyze information about security threats?",
    "Do your projects consider information security?",
    "Do you have a list of all our information assets, and does each asset have a designated owner?",
    "Do you have rules for handling information and other assets?",
    "Do employees and contractors return company assets when they leave?",
    "Do you have criteria for classifying information?",
    "Do you have procedures for labeling and handling classified information?",
    "Is the transfer of information properly protected?",
    "Do you have an access control policy, and do users only access the resources they are allowed to use?",
    "Is access provided through a formal registration process?",
    "Do you have policies for passwords and other authentication methods?",
    "Do you have a formal process for managing and reviewing access to information systems?",
    "Do you have a policy for managing risks related to suppliers and partners?",
    "Are security requirements included in agreements with suppliers and partners?",
    "Do you regularly monitor suppliers and manage changes in agreements considering risks?",
    "Do you manage cloud services according to security requirements?",
    "Do you manage security incidents properly?",
    "Are security events assessed and classified correctly?",
    "Do you have documented procedures for responding to incidents?",
    "Do you analyze security incidents thoroughly?",
    "Do you have procedures for collecting evidence?",
    "Are your information security continuity requirements defined, implemented, tested, and exercised?",
    "Is ICT readiness managed according to business continuity objectives?",
    "Are all legislative, regulatory, contractual, and other security requirements listed?",
    "Do you have procedures to protect intellectual property rights?",
    "Are records properly protected?",
    "Is personally identifiable information protected correctly?",
    "Is information security regularly reviewed by an independent auditor?",
    "Do managers regularly review security policies and procedures in their areas of responsibility to ensure compliance?",
    "Are operating procedures for IT processes documented?",
    "Do you perform background checks on candidates and contractors?",
    "Do agreements with employees and contractors specify information security responsibilities?",
    "Do employees and contractors attend security training, and are there awareness programs?",
    "Do you have a formal disciplinary process?",
    "Are information security responsibilities covered in agreements valid after employment ends?",
    "Do you list all confidentiality clauses that need to be included in agreements with third parties?",
    "Are there rules for protecting information when employees work from home or other sites?",
    "Are security events and weaknesses reported correctly by employees and contractors?",
    "Do you have secure areas to protect sensitive information?",
    "Are the entrances to secure areas protected?",
    "Are secure areas located in a safe way?",
    "Are our premises monitored for unauthorized access?",
    "Do you have alarms, fire protection, and other security systems installed?",
    "Are working procedures for secure areas defined?",
    "Do users know what to do when they are at their workstations?",
    "Is your equipment properly protected?",
    "Are organization’s assets protected when not on the premises?",
    "Do you have procedures for handling, protecting, transporting, and disposing of storage media in line with classification rules?",
    "Is the equipment protected against power variations?",
    "Are power and telecommunication cables adequately protected?",
    "Is the equipment maintained regularly?",
    "Is information properly removed from media or equipment that will be disposed of?",
    "Do you have rules for securely handling mobile devices and protecting equipment when not in physical possession of users?",
    "Are privileged access rights managed carefully?",
    "Is access to information in systems restricted according to the access control policy?",
    "Is access to source code restricted to authorized persons?",
    "Is secure log-on required on systems according to the access control policy?",
    "Are resources monitored, and plans made to ensure they meet users' demands?",
    "Are anti-virus and malware protection software installed and used properly?",
    "Is information about vulnerabilities managed correctly, and are systems regularly reviewed for compliance with security policies?",
    "Are configurations of IT assets properly managed?",
    "Is data that is no longer required properly disposed of?",
    "Is data masking applied where required?",
    "Do systems, networks, and devices have measures to prevent data leakage?",
    "Is a backup policy defined and followed?",
    "Does the IT infrastructure have redundancy included in its planning and operation?",
    "Are IT events logged periodically, and are logs protected?",
    "Are systems, networks, and applications monitored, and proper actions taken for malicious behaviors?",
    "Are clocks on all IT systems synchronized?",
    "Is the use of utility tools controlled and limited to specific employees?",
    "Is the installation of software strictly controlled?",
    "Are networks controlled to protect information in systems and applications?",
    "Are security requirements for network services defined and included in agreements?",
    "Are networks segregated based on risks and asset classification?",
    "Is access to external websites controlled?",
    "Do you have a policy for encryption and other cryptographic controls?",
    "Do you have rules for the secure development of software and systems?",
    "Is application information transferred through public networks protected appropriately?",
    "Are secure system engineering principles applied in the development process?",
    "Is software code developed according to defined principles?",
    "Are security requirements tested during system development, and are acceptance criteria defined?",
    "Is outsourced development of systems monitored?",
    "Are development, testing, and production environments separated and secured?",
    "Are changes to information security of new or existing systems controlled and tested?",
    "Is test data carefully selected and protected?",
    "Are production systems audits planned and executed correctly?",
  ],
  "SOC 2": [
    "Does the client have a documented information security policy that is reviewed and approved by management?",
    "Are employees trained on security policies and procedures?",
    "Are there regular security awareness programs for employees?",
    "Are security logs monitored and reviewed for suspicious activities?",
    "Does the client conduct regular security risk assessments?",
    "Are multi-factor authentication (MFA) methods used for accessing critical systems?",
    "Are there procedures for managing and controlling changes to the IT environment?",
    "Are there mechanisms to detect and respond to network intrusions?",
    "Are third-party service providers assessed for security compliance?",
    "Does the client perform regular vulnerability assessments and penetration tests?",
    "Does the client have policies and procedures to ensure their systems are available when needed?",
    "Are system uptime and performance metrics monitored continuously?",
    "Does the client have a disaster recovery plan that includes regular testing?",
    "Are backups performed regularly and stored securely?",
    "Does the client have a business continuity plan that includes alternative processing sites?",
    "Are there regular drills or simulations to test the disaster recovery plan?",
    "Are backup data tested regularly to ensure they can be restored successfully?",
    "Does the client have agreements with third-party providers for critical services to ensure availability?",
    "Are there procedures for handling system capacity planning?",
    "Does the client maintain documentation for system recovery processes?",
    "Does the client have policies and procedures to ensure that data is processed accurately and completely?",
    "Are there documented procedures for data entry, processing, and output?",
    "Are input data validated to ensure accuracy and completeness?",
    "Are there controls to ensure data processing is performed as intended?",
    "Are there automated tools to check for data integrity and consistency?",
    "Are data reconciliation processes in place to identify and correct discrepancies?",
    "Are transactions tracked and logged for accountability?",
    "Are audit trails maintained for all significant data processing activities?",
    "Are there procedures for reviewing and approving data processing changes?",
    "Does the client have policies and procedures to protect confidential information?",
    "Are there access controls to restrict who can view or modify confidential information?",
    "Is confidential information encrypted at rest and in transit?",
    "Are there policies for classifying and handling different types of confidential information?",
    "Does the client have procedures for monitoring access to confidential information?",
    "Are confidentiality requirements included in contracts with third parties?",
    "Are there procedures for securely shredding or destroying confidential documents?",
    "Are confidentiality policies reviewed and updated regularly?",
    "Does the client have a privacy policy that is communicated to customers and employees?",
    "Does the client have a privacy officer or team responsible for overseeing privacy practices?",
    "Are privacy impact assessments conducted for new projects or systems that handle personal information?",
    "Are there procedures in place to collect, use, retain, and dispose of personal information in accordance with privacy laws and regulations?",
    "Does the client obtain consent from individuals before collecting their personal information?",
    "Are individuals informed about how their personal information will be used and shared?",
    "Does the client maintain a record of data processing activities involving personal information?",
    "Are individuals’ rights to access, correct, and delete their personal information supported?",
    "Are privacy notices and consent forms reviewed to ensure they are clear and transparent?",
    "Are there procedures for managing data subject requests and complaints?",
    "Does the client have measures in place to protect personal information from unauthorized access and disclosure?",
    "Are privacy policies and practices reviewed and audited regularly for compliance with applicable laws and regulations?",
    "Are incidents managed properly?",
    "Are security events within the client’s organization assessed and classified correctly?",
    "Does the client have documented procedures for responding to incidents?",
    "Does the client analyze security incidents thoroughly?",
    "Does the client have procedures for collecting evidence?",
    "Are the client's information security continuity requirements defined, implemented, tested, and exercised?",
    "Is ICT readiness within the client's organization managed according to business continuity objectives?",
    "Are all legislative, regulatory, contractual, and other security requirements listed by the client?",
    "Does the client have procedures to protect intellectual property rights?",
    "Are records within the client’s organization properly protected?",
    "Is personally identifiable information protected correctly by the client?",
    "Is information security regularly reviewed by an independent auditor?",
    "Do the client’s managers regularly review security policies and procedures in their areas of responsibility to ensure compliance?",
    "Are operating procedures for the client’s IT processes documented?",
    "Does the client perform background checks on candidates and contractors?",
    "Do the client's agreements with employees and contractors specify information security responsibilities?",
    "Do the client's employees and contractors attend security training, and are there awareness programs?",
    "Does the client have a formal disciplinary process?",
    "Are information security responsibilities covered in agreements with the client valid after employment ends?",
    "Does the client list all confidentiality clauses that need to be included in agreements with third parties?",
    "Are there rules for protecting information when the client’s employees work from home or other sites?",
    "Are security events and weaknesses reported correctly by the client’s employees and contractors?",
    "Does the client have secure areas to protect sensitive information?",
    "Are the entrances to the client's secure areas protected?",
    "Are the client's secure areas located in a safe way?",
    "Are the client’s premises monitored for unauthorized access?",
    "Does the client have alarms, fire protection, and other security systems installed?",
    "Are working procedures for the client’s secure areas defined?",
    "Do the client’s users know what to do when they are at their workstations?",
    "Is the client’s equipment properly protected?",
    "Are the client’s assets protected when not on their premises?",
    "Does the client have procedures for handling, protecting, transporting, and disposing of storage media in line with classification rules?",
    "Is the client’s equipment protected against power variations?",
    "Are the client’s power and telecommunication cables adequately protected?",
    "Is the client’s equipment maintained regularly?",
    "Is information properly removed from media or equipment that will be disposed of by the client?",
    "Does the client have rules for securely handling mobile devices and protecting equipment when not in the physical possession of users?",
    "Are the client’s privileged access rights managed carefully?",
    "Is access to information in the client’s systems restricted according to the access control policy?",
    "Is access to source code restricted to authorized persons within the client’s organization?",
    "Is secure log-on required on the client’s systems according to the access control policy?",
    "Are the client’s resources monitored, and plans made to ensure they meet users' demands?",
    "Are anti-virus and malware protection software installed and used properly within the client’s organization?",
    "Is information about vulnerabilities managed correctly by the client, and are systems regularly reviewed for compliance with security policies?",
    "Are configurations of the client’s IT assets properly managed?",
    "Is data that is no longer required by the client properly disposed of?",
    "Is data masking applied by the client where required?",
    "Do the client’s systems, networks, and devices have measures to prevent data leakage?",
    "Is a backup policy defined and followed by the client?",
    "Does the client’s IT infrastructure have redundancy included in its planning and operation?",
    "Are IT events logged periodically by the client, and are logs protected?",
    "Are the client’s systems, networks, and applications monitored, and proper actions taken for malicious behaviors?",
    "Are clocks on all the client’s IT systems synchronized?",
    "Is the use of utility tools controlled and limited to specific employees within the client’s organization?",
    "Is the installation of software strictly controlled by the client?",
    "Are the client’s networks controlled to protect information in systems and applications?",
    "Are security requirements for network services defined by the client and included in agreements?",
    "Are the client’s networks segregated based on risks and asset classification?",
    "Is access to external websites controlled by the client?",
    "Does the client have a policy for encryption and other cryptographic controls?",
    "Does the client have rules for the secure development of software and systems?",
    "Is application information transferred through public networks protected appropriately by the client?",
    "Are secure system engineering principles applied in the client’s development process?",
    "Is software code developed according to defined principles within the client’s organization?",
    "Are security requirements tested during the client’s system development, and are acceptance criteria defined?",
    "Is outsourced development of systems monitored by the client?",
    "Are the client’s development, testing, and production environments separated and secured?",
    "Are changes to the client’s information security of new or existing systems controlled and tested?",
    "Is test data carefully selected and protected by the client?",
    "Are production systems audits planned and executed correctly by the client?",
  ],
};

export default function AddFindings({ visible, onHide, currentDetails }) {
  const { id } = useParams();

  const [data, setData] = useState({ audit: id });
  const [error, setError] = useState({});
  const [department, setDepartment] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [controls, setControls] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [evidence, setEvidence] = useState([]);
  const [auditInfo, setAuditInfo] = useState(null);

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartment(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPolicies = () => {
    complianceAPI
      .post("/policy/filter/")
      .then(({ data }) => {
        setPolicies(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEvidence = () => {
    complianceAPI
      .post("/evidence/filter/")
      .then(({ data }) => {
        setEvidence(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getControls = () => {
    complianceAPI
      .post("/controls/filter/")
      .then(({ data }) => {
        setControls(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAuditInfo = () => {
    complianceAPI
      .get(`/audit/${id}`)
      .then((data) => setAuditInfo(data))
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAPI = (e) => {
    if (currentDetails) {
      complianceAPI
        .patch(`/finding/${currentDetails.id}/`, { ...data, audit: id })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Findings updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/finding/", { ...data, audit: id })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Findings added successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");

    const file = e.target.file.files[0];

    if (file) {
      fileUpload("v1/compliance/attachments/", file)
        .then((res) => {
          if (res.status === "success") {
            data.attachment = res.data.id;
            handleAPI(e);
          } else {
            toast.error(res.message);
            e.target.submit.removeAttribute("disabled");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
          e.target.submit.removeAttribute("disabled");
          return;
        });
    } else {
      handleAPI(e);
    }
  };
  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  useEffect(() => {
    getEmployee();
    getDepartment();
    getPolicies();
    getEvidence();
    getControls();
    getAuditInfo();
  }, []);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-30rem sidebar-style"
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Findings`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Findings`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          {/* <div className="w-full">
            <label className="block text-gray-500 text-sm ">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div> */}
          <div className="w-full ">
            <label className="block text-gray-500 text-sm">Name</label>
            <Dropdown
              filter
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.value })}
              options={
                FINDINGS[auditInfo?.framework_info?.name]
                  ? FINDINGS[auditInfo.framework_info.name].map((item) => ({
                      label: item,
                      id: item,
                    }))
                  : []
              }
              itemTemplate={(option) => {
                return (
                  <p className="w-80	truncate" title={option.label}>
                    {option.label}
                  </p>
                );
              }}
              editable
              optionLabel="label"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm" for="file_input">
              Upload file
            </label>
            <input
              className="w-full cursor-pointer rounded-lg border-[1px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter dark:file:text-gray-500 file:py-2 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:focus:border-primary"
              name="file"
              type="file"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Assignee</label>
            <Dropdown
              value={data.assignee}
              onChange={(e) => setData({ ...data, assignee: e.value })}
              options={employees.filter((i) => i.role === "Super User")}
              filter
              optionLabel="email"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">
              Nature of Findings
            </label>
            <Dropdown
              filter
              value={data?.nature}
              onChange={(e) => setData({ ...data, nature: e.value })}
              options={NATURE_OF_FINDINGS}
              optionLabel="label"
              optionValue="value"
              placeholder="Select nature of findings"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Department</label>
            <Dropdown
              filter
              value={data?.department}
              onChange={(e) => setData({ ...data, department: e.value })}
              options={department}
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Status</label>
            <Dropdown
              filter
              value={data?.status}
              onChange={(e) => setData({ ...data, status: e.value })}
              options={STATUS}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter description"
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
                setError({ ...error, description: false });
              }}
            />
            {error.description && (
              <p className="text-red-500 text-sm">{error.description}</p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Action Items</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter action items"
              value={data.action_items}
              onChange={(e) => {
                setData({ ...data, action_items: e.target.value });
                setError({ ...error, action_items: false });
              }}
            />
            {error.action_items && (
              <p className="text-red-500 text-sm">{error.action_items}</p>
            )}
          </div>
          <hr />
          <p className="font-bold text-lg mt-2">Controls</p>
          <div className="mt-3">
            <label className="block text-gray-500 text-sm">Controls</label>
            <MultiSelect
              className="w-full"
              optionLabel="label"
              optionValue="id"
              filter
              value={data.controls}
              options={controls?.map((i) => ({
                ...i,
                label: i?.code ? `${i.code} - ${i.name}` : i.name,
              }))}
              placeholder="Select Controls"
              onChange={(e) => setData({ ...data, controls: e.value })}
            />
          </div>
          <hr />
          <p className="font-bold text-lg mt-2">Linked Artifacts</p>
          <div className="mt-3">
            <label className="block text-gray-500 text-sm">Policy</label>
            <MultiSelect
              className="w-full"
              optionLabel="name"
              optionValue="id"
              filter
              value={data.policies}
              options={policies}
              placeholder="Select policies"
              onChange={(e) => setData({ ...data, policies: e.value })}
            />
          </div>
          <div className="mt-3">
            <label className="block text-gray-500 text-sm">Evidence</label>
            <MultiSelect
              className="w-full"
              optionLabel="label"
              optionValue="id"
              filter
              value={data.evidence}
              options={evidence?.map((i) => ({
                ...i,
                label: i?.code ? `${i.code} - ${i.name}` : i.name,
              }))}
              placeholder="Select evidence"
              onChange={(e) => setData({ ...data, evidence: e.value })}
            />
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
