import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiArrowUpRight, FiMoreVertical } from "react-icons/fi";
import TableFilter from "../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../SkeletonAnimation/SkeletonDataTable";
import paginationTemplate from "../../../utils/paginationTemplate";
import { isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin } from "../../../utils/accessCheck";
import { useNavigate, useLocation } from "react-router-dom";
import customAxios, { baseUrl } from "../../../Api/Repository";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import GenericLayout from "../../../common/layout/GenericLayout";
import { ThemeContext } from "../../../context/ThemeContext";
import { DateTimeToDate } from "../../../helpers";

export default function ViewTask() {
  const { isDarkMode } = useContext(ThemeContext);
  const { state } = useLocation();
  const scan = state?.scan;

  const ref = useRef(null);
  const userDetails = useSelector(getUserDetails);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [editDetails, setEditDetails] = useState(null);
  const [viewDetails, setViewDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);
  const [first, setFirst] = useState(0);

  const getFilterParams = useCallback(() => {
    console.log("ID -> ", scan);
    setLoading(true);
    customAxios
      .get(`${baseUrl}v1/web/scan-status/filter?id=${scan}`)
      .then(({ data }) => {
        setFilterParams(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [scan]);

  const getData = () => {
    setLoading(true);
    customAxios
      .post(`${baseUrl}v1/web/scan-status/filter/`, {
        scan: scan,
        ...filterParams,
      })
      .then(({ data }) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const renderActions = () => {};

  useEffect(() => {
    getFilterParams();
    getData();
  }, []);

  console.log("Filter Params -> ", filterParams);
  console.log("Data -> ", data);

  useEffect(() => {
    if (!state?.scan) {
      navigate("/dashboard");
    }
  }, [state?.scan, navigate]);

  console.log("Data -> ", data);

  const columns = [
    {
      field: "task",
      header: "Task",
    },
    {
      field: "status",
      header: "Status",
    },
    {
      field: "created_at",
      header: "Started",
      type: "date",
    },
  ];

  return (
    <GenericLayout>
      <div
        className={`mt-7 rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <>
          <TableFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            // getFilterData={getFilterData}
            columns={columns?.map((item) => ({
              ...item,
              value: filterData?.hasOwnProperty(item.field)
                ? item.value?.filter((v) =>
                    filterData[item.field]?.includes(v.value)
                  ) || filterData[item.field]
                : null,
            }))}
            tableData={data}
            tableHeader={columns}
            dt={ref}
            isDarkMode={isDarkMode}
            setVisibleRight={() => {
              setAddVisibility(true);
            }}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              ref={ref}
              removableSort
              value={
                data &&
                data?.map((d) => {
                  const newData = { ...d };
                  columns.forEach((col) => {
                    if (col.type === "select") {
                      // if any select option in update data from here itself
                      newData[col.field] = col.value.find(
                        (o) => o.value === d[col.field]
                      )?.label;
                    } else if (col.type === "multi_select") {
                      newData[col.field] = col.value
                        .filter((o) => d[col.field].includes(o.value))
                        ?.map((o) => o.label)
                        .join(", ");
                    } else if (col.type === "date") {
                      // for date type data
                      if (col.field?.includes(".")) {
                        const nestedFields = col.field.split(".");
                        let value = newData;
                        for (let i = 0; i < nestedFields.length; i++) {
                          value = value[nestedFields[i]];
                        }
                        newData[col.field] = DateTimeToDate(value);
                      } else {
                        newData[col.field] = DateTimeToDate(d[col.field]);
                      }
                    }
                  });
                  return newData;
                })
              }
              paginator
              rows={10}
              first={first}
              onPage={(e) => {
                setFirst(e.first);
              }}
              sortMode="multiple"
              globalFilter={globalFilter}
              paginatorTemplate={paginationTemplate()}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs cursor-pointer`}
            >
              {columns
                .filter((item) => {
                  if (item.admins) {
                    return (
                      isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin(
                        userDetails.role
                      ) ||
                      (userDetails.role === "Editor" &&
                        userDetails.module.includes("Action Button"))
                    );
                  } else {
                    return true;
                  }
                })
                ?.map((col, i) => {
                  if (col.type === "full_info") {
                    return (
                      <Column
                        header="Full info"
                        exportable={false}
                        body={(rowData) => (
                          <div className="flex gap-2">
                            <button onClick={() => setViewDetails(rowData)}>
                              <FiArrowUpRight size={20} color="#074EE8" />
                            </button>
                          </div>
                        )}
                      />
                    );
                  } else if (col.type === "func") {
                    return (
                      <Column
                        key={i}
                        field={col.field}
                        header={col.header}
                        exportable={false}
                        body={(e) => col.func(e)}
                      />
                    );
                  } else if (col.type === "action") {
                    return (
                      <Column
                        key={i}
                        field={col.field}
                        header={col.header}
                        exportable={false}
                        body={(e) => (
                          <SplitButton
                            icon={<FiMoreVertical size={24} />}
                            className="p-button-text outline-none split-style"
                            model={renderActions(e)}
                          ></SplitButton>
                        )}
                      />
                    );
                  }
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                })}
            </DataTable>
          )}
        </>
      </div>
    </GenericLayout>
  );
}
