import React, { useState } from "react";
import { toast } from "react-toastify"; // Assuming you use react-toastify for notifications

import { useSelector } from "react-redux";
import integrationsAPI from "../../../../../Api/integerations/api"; // Adjust the import path accordingly
import { getUserDetails } from "../../../../../redux-toolkit/redux/auth";

export default function APIKeyandsubdomainSideBar({
  type,
  handleCloseSideBar,
}) {
  const [key, setKey] = useState("");
  const [subdomain, setSubdomain] = useState(""); // New state for subdomain
  const userDetails = useSelector(getUserDetails);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the subdomain as part of the data
    integrationsAPI
      .post("/", { type, key, subdomain, org_id: userDetails.org_id }) // Include subdomain in the request body
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
          handleCloseSideBar();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occurred while submitting the form.");
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Subdomain</label>
          <input
            className="placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            type="text"
            required
            placeholder="Enter Subdomain"
            value={subdomain}
            onChange={(e) => setSubdomain(e.target.value)}
          />
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">API Key</label>
          <input
            className="placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            type="text"
            required
            placeholder="Enter API Key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        </div>
        <div className="flex w-full mt-9">
          <button
            type="submit"
            className="p-1 border mr-2 rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
          >
            Submit
          </button>
          <button
            className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
            onClick={handleCloseSideBar}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
}
