import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ThemeContext } from "../../../context/ThemeContext";
import Header from "../../../common/Charts/Header";
import DonutWithBars from "../../../Component/cspm/azure/DonutWithBars";
import HorizontalSpanBar from "../../../Component/cspm/azure/HorizontalSpanBar";
import VerticalBar from "../../../Component/cspm/azure/VerticalBar";
import HorizontalLabelBar from "../../../Component/cspm/azure/HorizontalLabelBar";
import RadialBar from "../../../Component/cspm/azure/RadialBar";
import HorizontalBar from "../../../Component/cspm/azure/HorizontalBar";
import AngleBar from "../../../Component/cspm/azure/AngleBar";
import { Bar } from "react-chartjs-2";

export default function AzureDashboard({ data }) {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  return (
    <div className="mb-2">
      <div className="grid grid-cols-4 gap-3">
        <div
          className={`col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl p-2`}
        >
          <Header
            title="Identity & Access Management"
            tooltip="Identity & Access Management"
          />
          <DonutWithBars
            data={[
              {
                title: "Active Directory",
                ...data?.resource__type_status?.active_directory,
                onClick: () => {
                  navigate("./ActiveDirectory", {
                    state: {
                      title: "Active Directory",
                      filter: { resource__type: "Active Directory" },
                    },
                  });
                },
              },
              {
                title: "Bastion",
                ...data?.resource__type_status?.bastion,
                onClick: () => {
                  navigate("./Bastion", {
                    state: {
                      title: "Bastion",
                      filter: { resource__type: "Bastion" },
                    },
                  });
                },
              },
              {
                title: "Key Vaults",
                ...data?.resource__type_status?.key_vaults,
                onClick: () => {
                  navigate("./KeyVaults", {
                    state: {
                      title: "Key Vaults",
                      filter: { resource__type: "Key Vaults" },
                    },
                  });
                },
              },
            ]}
          />
        </div>
        <div
          className={`col-span-3 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl py-2 px-4`}
        >
          <Header
            title="Networking & Connectivity"
            tooltip="Networking & Connectivity"
          />
          <div className="grid grid-cols-7 gap-3">
            <div className="col-span-6 grid grid-cols-2 gap-2">
              <HorizontalSpanBar
                title="CDN Profiles"
                data={data?.resource__type_status?.cdn_profiles}
                onClick={() => {
                  navigate("./CDNProfiles", {
                    state: {
                      title: "CDN Profiles",
                      filter: { resource__type: "CDN Profiles" },
                    },
                  });
                }}
              />
              <HorizontalSpanBar
                title="Front Door"
                data={data?.resource__type_status?.front_door}
                onClick={() => {
                  navigate("./FrontDoor", {
                    state: {
                      title: "Front Door",
                      filter: { resource__type: "Front Door" },
                    },
                  });
                }}
              />
              <HorizontalSpanBar
                title="Load Balancer"
                data={data?.resource__type_status?.load_balancer}
                onClick={() => {
                  navigate("./LoadBalancer", {
                    state: {
                      title: "Load Balancer",
                      filter: { resource__type: "Load Balancer" },
                    },
                  });
                }}
              />
              <HorizontalSpanBar
                title="Virtual Networks"
                data={data?.resource__type_status?.virtual_networks}
                onClick={() => {
                  navigate("./VirtualNetworks", {
                    state: {
                      title: "Virtual Networks",
                      filter: { resource__type: "Virtual Networks" },
                    },
                  });
                }}
              />
            </div>
            <div className="border border-1 rounded-md">
              <DonutWithBars
                data={[
                  {
                    title: "WAF",
                    ...data?.resource__type_status?.waf,
                    onClick: () => {
                      navigate("./WAF", {
                        state: {
                          title: "WAF",
                          filter: { resource__type: "WAF" },
                        },
                      });
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div
          className={`col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl  pb-2`}
        >
          <Header
            title="Governance & Monitoring"
            tooltip="Governance & Monitoring"
          />
          <div className="grid grid-cols-5">
            <VerticalBar
              title="Advisor"
              data={data?.resource__type_status?.advisor}
              onClick={() => {
                navigate("./Advisor", {
                  state: {
                    title: "Advisor",
                    filter: { resource__type: "Advisor" },
                  },
                });
              }}
            />
            <VerticalBar
              title="Log Alerts"
              data={data?.resource__type_status?.log_alerts}
              onClick={() => {
                navigate("./LogAlerts", {
                  state: {
                    title: "Log Alerts",
                    filter: { resource__type: "Log Alerts" },
                  },
                });
              }}
            />
            <VerticalBar
              title="Monitor"
              data={data?.resource__type_status?.monitor}
              onClick={() => {
                navigate("./Monitor", {
                  state: {
                    title: "Monitor",
                    filter: { resource__type: "Monitor" },
                  },
                });
              }}
            />
            <VerticalBar
              title="Policy Service"
              data={data?.resource__type_status?.policy_service}
              onClick={() => {
                navigate("./PolicyService", {
                  state: {
                    title: "Policy Service",
                    filter: { resource__type: "Policy Service" },
                  },
                });
              }}
            />
            <VerticalBar
              title="Security Center"
              data={data?.resource__type_status?.security_center}
              onClick={() => {
                navigate("./SecurityCenter", {
                  state: {
                    title: "Security Center",
                    filter: { resource__type: "Security Center" },
                  },
                });
              }}
            />
          </div>
        </div>
        <div
          className={`col-span-2 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl pb-2`}
        >
          <Header
            title="API & Application Management"
            tooltip="API & Application Management"
          />
          <div className="grid grid-cols-5">
            <VerticalBar
              title="API Management"
              data={data?.resource__type_status?.api_management}
              onClick={() => {
                navigate("./APIManagement", {
                  state: {
                    title: "API Management",
                    filter: { resource__type: "API Management" },
                  },
                });
              }}
            />
            <VerticalBar
              title="App Configurations"
              data={data?.resource__type_status?.app_configurations}
              onClick={() => {
                navigate("./AppConfigurations", {
                  state: {
                    title: "App Configurations",
                    filter: { resource__type: "App Configurations" },
                  },
                });
              }}
            />
            <VerticalBar
              title="App Service"
              data={data?.resource__type_status?.app_service}
              onClick={() => {
                navigate("./AppService", {
                  state: {
                    title: "App Service",
                    filter: { resource__type: "App Service" },
                  },
                });
              }}
            />
            <VerticalBar
              title="Application Gateway"
              data={data?.resource__type_status?.application_gateway}
              onClick={() => {
                navigate("./ApplicationGateway", {
                  state: {
                    title: "Application Gateway",
                    filter: { resource__type: "Application Gateway" },
                  },
                });
              }}
            />
            <VerticalBar
              title="Key Vaults"
              data={data?.resource__type_status?.key_vaults}
              onClick={() => {
                navigate("./KeyVaults", {
                  state: {
                    title: "Key Vaults",
                    filter: { resource__type: "Key Vaults" },
                  },
                });
              }}
            />
          </div>
        </div>
        <div
          className={`col-span-4 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl pb-2`}
        >
          <Header
            title="Data Management & Databases"
            tooltip="Data Management & Databases"
          />
          <div className="grid lg:grid-cols-9 md:grid-cols-5">
            <HorizontalLabelBar
              title="Blob Service"
              data={data?.resource__type_status?.blob_service}
              onClick={() => {
                navigate("./BlobService", {
                  state: {
                    title: "Blob Service",
                    filter: { resource__type: "Blob Service" },
                  },
                });
              }}
            />
            <HorizontalLabelBar
              title="Cosmos DB"
              data={data?.resource__type_status?.cosmos_db}
              onClick={() => {
                navigate("./CosmosDB", {
                  state: {
                    title: "Cosmos DB",
                    filter: { resource__type: "Cosmos DB" },
                  },
                });
              }}
            />
            <HorizontalLabelBar
              title="Databricks"
              data={data?.resource__type_status?.databricks}
              onClick={() => {
                navigate("./Databricks", {
                  state: {
                    title: "Databricks",
                    filter: { resource__type: "Databricks" },
                  },
                });
              }}
            />
            <HorizontalLabelBar
              title="File service"
              data={data?.resource__type_status?.file_service}
              onClick={() => {
                navigate("./FileService", {
                  state: {
                    title: "File service",
                    filter: { resource__type: "File service" },
                  },
                });
              }}
            />
            <HorizontalLabelBar
              title="PostgreSQL Server"
              data={data?.resource__type_status?.postgresql_server}
              onClick={() => {
                navigate("./PostgreSQLServer", {
                  state: {
                    title: "PostgreSQL Server",
                    filter: { resource__type: "PostgreSQL Server" },
                  },
                });
              }}
            />
            <HorizontalLabelBar
              title="MySQL Server"
              data={data?.resource__type_status?.mysql_server}
              onClick={() => {
                navigate("./MySQLServer", {
                  state: {
                    title: "MySQL Server",
                    filter: { resource__type: "MySQL Server" },
                  },
                });
              }}
            />
            <HorizontalLabelBar
              title="SQL Databases"
              data={data?.resource__type_status?.sql_databases}
              onClick={() => {
                navigate("./SQLDatabases", {
                  state: {
                    title: "SQL Databases",
                    filter: { resource__type: "SQL Databases" },
                  },
                });
              }}
            />
            <HorizontalLabelBar
              title="SQL Server"
              data={data?.resource__type_status?.sql_server}
              onClick={() => {
                navigate("./SQLServer", {
                  state: {
                    title: "SQL Server",
                    filter: { resource__type: "SQL Server" },
                  },
                });
              }}
            />
            <HorizontalLabelBar
              title="Table service"
              data={data?.resource__type_status?.table_service}
              onClick={() => {
                navigate("./TableService", {
                  state: {
                    title: "Table service",
                    filter: { resource__type: "Table service" },
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full mt-3 flex gap-3">
        <div
          className={`w-8/12 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl pb-2`}
        >
          <Header
            title="Analytics, AI & Cognitive Services"
            tooltip="Analytics, AI & Cognitive Services"
          />
          <div className="grid grid-cols-5">
            <RadialBar
              title="Event Grid"
              data={data?.resource__type_status?.event_grid}
              onClick={() => {
                navigate("./EventGrid", {
                  state: {
                    title: "Event Grid",
                    filter: { resource__type: "Event Grid" },
                  },
                });
              }}
            />
            <RadialBar
              title="Event Hub"
              data={data?.resource__type_status?.event_hub}
              onClick={() => {
                navigate("./EventHub", {
                  state: {
                    title: "Event Hub",
                    filter: { resource__type: "Event Hub" },
                  },
                });
              }}
            />
            <RadialBar
              title="Machine Learning"
              data={data?.resource__type_status?.machine_learning}
              onClick={() => {
                navigate("./MachineLearning", {
                  state: {
                    title: "Machine Learning",
                    filter: { resource__type: "Machine Learning" },
                  },
                });
              }}
            />
            <RadialBar
              title="OpenAI"
              data={data?.resource__type_status?.openai}
              onClick={() => {
                navigate("./OpenAI", {
                  state: {
                    title: "OpenAI",
                    filter: { resource__type: "OpenAI" },
                  },
                });
              }}
            />
            <RadialBar
              title="Synapse"
              data={data?.resource__type_status?.synapse}
              onClick={() => {
                navigate("./Synapse", {
                  state: {
                    title: "Synapse",
                    filter: { resource__type: "Synapse" },
                  },
                });
              }}
            />
          </div>
        </div>
        <div
          className={`w-4/12 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl pb-2`}
        >
          <Header title="Container Services" tooltip="Container Services" />
          <div className="grid grid-cols-3 justify-center">
            <VerticalBar
              title="Container Apps"
              data={data?.resource__type_status?.container_apps}
              type="bricks"
              onClick={() => {
                navigate("./ContainerApps", {
                  state: {
                    title: "Container Apps",
                    filter: { resource__type: "Container Apps" },
                  },
                });
              }}
            />
            <VerticalBar
              title="Container Registry"
              data={data?.resource__type_status?.container_registry}
              type="bricks"
              onClick={() => {
                navigate("./ContainerRegistry", {
                  state: {
                    title: "Container Registry",
                    filter: { resource__type: "Container Registry" },
                  },
                });
              }}
            />
            <VerticalBar
              title="Kubernetes Service"
              data={data?.resource__type_status?.kubernetes_service}
              type="bricks"
              onClick={() => {
                navigate("./KubernetesService", {
                  state: {
                    title: "Kubernetes Service",
                    filter: { resource__type: "Kubernetes Service" },
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-full mt-3 flex gap-3">
        <div className="w-9/12 grid grid-cols-2 gap-3">
          <div
            className={`${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl pb-2`}
          >
            <Header title="Compute Services" tooltip="Compute Services" />
            <div className="grid grid-cols-3 gap-2 mt-5">
              <HorizontalBar
                title="Batch Accounts"
                data={data?.resource__type_status?.batch_accounts}
                type="bricks"
                onClick={() => {
                  navigate("./BatchAccounts", {
                    state: {
                      title: "Batch Accounts",
                      filter: { resource__type: "Batch Accounts" },
                    },
                  });
                }}
              />
              <HorizontalBar
                title="Virtual Machines"
                data={data?.resource__type_status?.virtual_machines}
                type="bricks"
                onClick={() => {
                  navigate("./VirtualMachines", {
                    state: {
                      title: "Virtual Machines",
                      filter: { resource__type: "Virtual Machines" },
                    },
                  });
                }}
              />
              <HorizontalBar
                title="Virtual Machine Scale Set"
                data={data?.resource__type_status?.virtual_machine_scale_set}
                type="bricks"
                onClick={() => {
                  navigate("./VirtualMachineScaleSet", {
                    state: {
                      title: "Virtual Machine Scale Set",
                      filter: { resource__type: "Virtual Machine Scale Set" },
                    },
                  });
                }}
              />
            </div>
          </div>
          <div
            className={` ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl pb-2`}
          >
            <Header
              title="Security & Compliance"
              tooltip="Security & Compliance"
            />
            <div className="grid grid-cols-3 gap-2">
              <HorizontalLabelBar
                title="Defender"
                data={data?.resource__type_status?.defender}
                onClick={() => {
                  navigate("./Defender", {
                    state: {
                      title: "Defender",
                      filter: { resource__type: "Defender" },
                    },
                  });
                }}
              />
              <HorizontalLabelBar
                title="Recovery Service"
                data={data?.resource__type_status?.recovery_service}
                onClick={() => {
                  navigate("./RecoveryService", {
                    state: {
                      title: "Recovery Service",
                      filter: { resource__type: "Recovery Service" },
                    },
                  });
                }}
              />
              <HorizontalLabelBar
                title="Redis Cache"
                data={data?.resource__type_status?.redis_cache}
                onClick={() => {
                  navigate("./RedisCache", {
                    state: {
                      title: "Redis Cache",
                      filter: { resource__type: "Redis Cache" },
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`w-3/12 ${
            isDarkMode ? "header-dark" : "bg-white"
          } rounded-2xl pb-2 px-2`}
        >
          <Header
            title="Messaging & Integration"
            tooltip="Messaging & Integration"
          />
          <div className="p-5">
            <DonutWithBars
              data={[
                {
                  title: "Service Bus",
                  ...data?.resource__type_status?.service_bus,
                  onClick: () => {
                    navigate("./ServiceBus", {
                      state: {
                        title: "Service Bus",
                        filter: { resource__type: "Service Bus" },
                      },
                    });
                  },
                },
                {
                  title: "Queue Service",
                  ...data?.resource__type_status?.queue_service,
                  onClick: () => {
                    navigate("./QueueService", {
                      state: {
                        title: "Queue Service",
                        filter: { resource__type: "Queue Service" },
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 my-3 gap-3">
        <div
          className={`${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl pb-2`}
        >
          <Header title="Media & Content" tooltip="Media & Content" />
          <div
            className="grid grid-cols-3 cursor-pointer"
            onClick={() => {
              navigate("./MediaContent", {
                state: {
                  title: "Media Content",
                  filter: { resource__type: "Media Content" },
                },
              });
            }}
          >
            <AngleBar
              type="pass"
              value={data?.resource__type_status?.media_content?.success}
            />
            <AngleBar
              type="fail"
              value={data?.resource__type_status?.media_content?.failure}
            />
            <AngleBar
              type="unknown"
              value={data?.resource__type_status?.media_content?.unknown}
            />
          </div>
        </div>
        <div
          className={`${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl pb-2`}
        >
          <Header title="Resource Management" tooltip="Resource Management" />
          <div className="grid grid-cols-2 gap-2 p-2">
            <HorizontalSpanBar
              title="Resources"
              data={data?.audit_status}
              onClick={() => {
                navigate("./ResourceManagement", {
                  state: {
                    title: "Resource Management",
                    filter: { resource__type: "Resource Management" },
                  },
                });
              }}
            />
            <HorizontalSpanBar
              title="Subscription"
              data={data?.resource__type_status?.subscription}
              onClick={() => {
                navigate("./Subscription", {
                  state: {
                    title: "Subscription",
                    filter: { resource__type: "Subscription" },
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`${
          isDarkMode ? "header-dark" : "bg-white"
        } w-full rounded-2xl pb-2`}
      >
        <Header title="Top Compliance" tooltip="Top Compliance" />
        <div
          className="flex justify-center min-h-[300px] p-5 w-full"
          style={{
            maxHeight: "300px",
            width: "100%",
          }}
        >
          <Bar
            data={{
              labels: ["ISO 27001", "SOC 2", "PCI DSS", "GDPR", "HIPAA"],
              datasets: [
                {
                  label: "Passed",
                  data: [
                    data?.compliance?.iso27001?.success || 0,
                    data?.compliance?.soc_2?.success || 0,
                    data?.compliance?.pci_dss?.success || 0,
                    data?.compliance?.gdpr?.success || 0,
                    data?.compliance?.hipaa?.success || 0,
                  ],
                  backgroundColor: ["#008C1B"],
                  maxBarThickness: 50,
                },
                {
                  label: "Failed",
                  data: [
                    data?.compliance?.iso27001?.failure || 0,
                    data?.compliance?.soc_2?.failure || 0,
                    data?.compliance?.pci_dss?.failure || 0,
                    data?.compliance?.gdpr?.failure || 0,
                    data?.compliance?.hipaa?.failure || 0,
                  ],
                  backgroundColor: ["#D11305"],
                  maxBarThickness: 50,
                },
                {
                  label: "Unknown",
                  data: [
                    data?.compliance?.iso27001?.unknown || 0,
                    data?.compliance?.soc_2?.unknown || 0,
                    data?.compliance?.pci_dss?.unknown || 0,
                    data?.compliance?.gdpr?.unknown || 0,
                    data?.compliance?.hipaa?.unknown || 0,
                  ],
                  backgroundColor: ["#FDCA3B"],
                  maxBarThickness: 50,
                },
              ],
            }}
            options={{
              responsive: true,
              // onClick: (e, el) => {
              //   const keys = [
              //     "ISO27001-2013",
              //     "SOC2",
              //     "PCI-3.2.1",
              //     "GDPR",
              //     "HIPAA",
              //   ];
              //   let label = e.chart.data.labels[el[0].index];
              //   navigate(`./${keys[el[0].index]}`, {
              //     state: {
              //       title: label,
              //       filter: {
              //         compliance__framework: keys[el[0].index],
              //       },
              //     },
              //   });
              // },
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  grid: {
                    display: false,
                  },
                  border: {
                    display: false,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
