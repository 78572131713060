import React, { useContext, useRef, useState, useEffect } from "react";
import TableFilter from "../../../common/Tables/TableFilter";
// import { vulnerabilityTableData } from '../../../constants/index';
import { SidebarSlide } from "../../../Component/Header/SidebarSlide";
import { DataTable } from "primereact/datatable";
import { FiArrowUpRight } from "react-icons/fi";
import { ThemeContext } from "../../../context/ThemeContext";
import { Column } from "primereact/column";
import { AiOutlineArrowDown } from "react-icons/ai";
import SkeletonDataTable from "../../SkeletonAnimation/SkeletonDataTable";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { Dropdown } from "primereact/dropdown";
import { message } from "antd";
import { MdKeyboardArrowDown } from "react-icons/md";
import { SeverityLevelLabel } from "../../../helpers";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { FaRegEdit } from "react-icons/fa";
import {
  isAdminOrSuperAdmin,
  isAgentOrAdminOrSuperAdmin,
} from "../../../utils/accessCheck";
import paginationTemplate from "../../../utils/paginationTemplate";

const VulnerabilityOperationTable = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const {
    vulnerabilityTableData,
    loadingVocTable,
    vocByStatus,
    userDetails,
    vocTableData,
    FilterData,
    setFilterParams,
    filterParams,
    getTableFilterData,
    type,
  } = props;

  const dt = useRef(null);
  const [projects, setProjects] = useState(vulnerabilityTableData);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedProjects, setSelectedProjects] = useState(null);
  const [openVulnerabilitySideBar, setOpenVulnerabilitySideBar] =
    useState(false);
  const [openDropDown, setOpenDropDown] = useState({});
  const [sideBarVocIntelligenceDetail, setSideBarVocIntelligenceDetail] =
    useState({});
  const [loadingSidebarVocDetail, setLoadingSidebarVocDetail] = useState(false);
  const [selectedStatusType, setSelectedStatusType] = useState(null);
  const [vocFilterData, setVocFilterData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleRightImport, setVisibleRightImport] = useState(false);
  const [editData, setEditData] = useState(null);

  const columns = [
    { field: "host", header: "Hosts", value: FilterData?.host },
    // { field: "ip", header: "IP Address", value: FilterData?.ip },
    { field: "vul_name", header: "Vulnerability", value: FilterData?.vul_name },
    {
      field: "severity_level",
      header: "Severity",
      value: FilterData?.severity_level,
    },
    { field: "matched_at", header: "URL", value: FilterData?.matched_at },
    { field: "cve_id", header: "CVE", value: FilterData?.cve_id },
    // { field: "cwe_id", header: "CWE ID", value: FilterData?.cwe_id },
    // { field: "cvss_score", header: "CVSS Score", value: FilterData?.cvss_score },
    { field: "status", header: "Status", value: FilterData?.status },
  ];

  const selectStatusType = [
    { name: "Open", code: "open" },
    { name: "Closed", code: "closed" },
    { name: "In Progress", code: "progress" },
    { name: "Risk Accepted", code: "risk" },
  ];

  const infoBody = (rowData) => {
    return (
      <div className="flex gap-2">
        <button
          onClick={() => {
            setSideBarVocIntelligenceDetail(rowData);
            setOpenVulnerabilitySideBar(true);
          }}
        >
          <FiArrowUpRight size={20} color="#074EE8" />
        </button>
        {isAdminOrSuperAdmin(userDetails.role) && !type
          ? editAction(rowData)
          : null}
      </div>
    );
  };

  const toggleDropDown = (id) => {
    setOpenDropDown((prevOpenDropDown) => ({
      ...prevOpenDropDown,
      [id]: !prevOpenDropDown[id],
    }));
  };

  const handleShowDetailVulnerability = (rowData) => {
    setOpenVulnerabilitySideBar(true);
    setLoadingSidebarVocDetail(true);
    TableApi.vulnerabilityIntelligenceDetail(rowData).then((data) => {
      if (data.status === "success") {
        setSideBarVocIntelligenceDetail(data.data);
        setLoadingSidebarVocDetail(false);
      }
    });
  };
  const handleChange = (e, rowData) => {
    // setSelectedStatusType(e.value);
    const payload = {
      id: rowData.id,
      type: rowData.type,
      status: e.value.code,
    };
    const payloadStatus = {
      unique_id: userDetails.unique_id,
      scan_id: userDetails.scan_id,
    };
    // vocTableData(payloadStatus);
    TableApi.vulnerabilityIntelligenceStatus(payload).then((data) => {
      if (data.status === "success") {
        message.success(data.message);
        // vocByStatus(payloadStatus);
      }
      getTableFilterData();
    });
  };

  // useEffect(() => {
  //   const payloadStatus = {
  //     method: "GET",
  //     scan_id: userDetails.scan_id,
  //     hosts: "",
  //     ip: "",
  //     url: "",
  //     status: "",
  //     vulnerability: "",
  //     severity: "",
  //     exploits: "",
  //     exploitability: "",
  //     cve_id: "",
  //   };
  //   TableApi.vocIntelligenceFilter(payloadStatus).then((data) => {
  //     setVocFilterData(data.data);
  //   });
  // }, []);

  const handleToggleDropDown = (rowData) => {
    setSelectedRowId(rowData);

    if (rowData === selectedRowId) {
      setSelectedRowId(null);
    }
  };

  const editAction = (rowData) => {
    if (rowData.type !== "manual") return null;
    else
      return (
        <div
          onClick={() => {
            setEditData(rowData);
            setVisibleRight(true);
          }}
        >
          <FaRegEdit />
        </div>
      );
  };

  const renderLink = (rowData) => {
    // const urlWithoutAnchor = rowData?.replace(/<\/?a[^>]*>/g, '');

    return (
      <a target="_blank" href={rowData} className="text-blue-500">
        {rowData}
      </a>
    );
  };

  const severityText = (severity) => {
    switch (severity) {
      case "1":
        return <p className="text-[#A30123]">Critical</p>;
      case "2":
        return <p className="text-[#E8001D]">High</p>;
      case "3":
        return <p className="text-[#F5B207]">Medium</p>;
      case "4":
        return <p className="text-[#148842]">Low</p>;
      default:
        return <p className="text-[#7B7DE2]">Info</p>;
    }
  };
  const getBusinessRisk = (rowData) => {
    /**
     * Exploit	Exploitability	Business Risk
        Yes	Yes	Critical
        Yes	No	High
        No	Yes	High
        No	No	Low
     */
    if (rowData?.business_risk) return rowData?.business_risk;

    const exploit = rowData?.exploits?.toLowerCase();
    const exploitability = rowData?.exploitability?.toLowerCase();

    if (!exploit && !exploitability) return null;
    if (exploit === "yes" && exploitability === "yes") return "High";
    else if (exploit === "yes" && exploitability === "no") return "Medium";
    else if (exploit === "no" && exploitability === "yes") return "Medium";
    else if (exploit === "no" && exploitability === "no") return "Low";
    else return null;
  };

  return (
    <div
      className={`p-8 rounded-2xl userTable-style  ${
        isDarkMode
          ? "header-dark  text-white"
          : "header-light white-background text-color"
      }`}
    >
      {type && (
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {type === "web"
              ? "Web Application Vulnerabilities"
              : "Web Server Vulnerabilities"}
            <span> ({vulnerabilityTableData.length}) </span>
          </p>
        </div>
      )}
      <TableFilter
        dt={dt}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        columns={columns}
        btnName={isAgentOrAdminOrSuperAdmin(userDetails.role) && "Add"}
        btnText={isAgentOrAdminOrSuperAdmin(userDetails.role) && !type}
        importButton={() => setVisibleRightImport(!visibleRightImport)}
        isDarkMode={isDarkMode}
        setVisibleRight={setVisibleRight}
        tableData={vulnerabilityTableData}
        tableHeader={columns}
        setFilterParams={setFilterParams}
        filterParams={filterParams}
      />
      {loadingVocTable ? (
        <SkeletonDataTable columns={columns} />
      ) : (
        <DataTable
          removableSort
          selection={selectedProjects}
          onSelectionChange={(e) => setSelectedProjects(e.value)}
          ref={dt}
          value={vulnerabilityTableData.map((item) => ({
            ...item,
            // severity_level:
            //   item.type === "nuclei"
            //     ? SeverityLevelLabel(item.severity_level)
            //     : item.type === "shodan"
            //     ? SeverityLevelLabel(item.vul_severity)
            //     : SeverityLevelLabel(item.severity),
            business_risk: getBusinessRisk(item),
          }))}
          dataKey="id"
          paginator
          paginatorTemplate={paginationTemplate()}
          rows={10}
          // sortField="severity_level"
          globalFilter={globalFilter}
          className={`${
            isDarkMode ? "dark-mode text-white" : "text-color"
          } text-xs vocStyle`}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="host"
            header="Hosts"
            sortable
            body={(rowData) => {
              return (
                <a
                  href={`//${
                    rowData.type === "greybox" ? rowData.host : rowData.host
                  }`}
                  target="_blank"
                  className="text-blue-500"
                  rel="noreferrer"
                >
                  {rowData.type === "greybox" || rowData.module === "user"
                    ? rowData.host
                    : rowData.subdomain_host}
                </a>
              );
            }}
          ></Column>
          {/* <Column field="ip" header="IP Address" sortable></Column> */}
          <Column
            field="vul_name"
            header="Vulnerability"
            sortable
            style={{ width: "35%" }}
          ></Column>
          <Column
            field="severity_level"
            header="Severity"
            sortable
            body={(rowData) => {
              return severityText(rowData.vul_severity);
            }}
          ></Column>
          <Column
            field="exploits"
            header="Exploits"
            sortable
            body={(rowData) => {
              return rowData?.exploits
                ? rowData?.exploits?.charAt(0).toUpperCase() +
                    rowData?.exploits?.slice(1)
                : null;
            }}
          ></Column>
          <Column
            field="exploitability"
            header="Exploitability"
            sortable
            body={(rowData) => {
              // make first letter capital
              return rowData?.exploitability
                ? rowData?.exploitability?.charAt(0).toUpperCase() +
                    rowData?.exploitability?.slice(1)
                : null;
            }}
          ></Column>
          <Column
            field="business_risk"
            header="Business Risk"
            sortable
            body={(rowData) => {
              return severityText(rowData.business_risk);
            }}
          ></Column>
          {/* <Column field="exploits" header="Exploits" sortable></Column>
          <Column field="exploitability" header="Exploitability" sortable></Column> */}
          <Column field="cve_id" header="CVE" sortable></Column>
          {/* <Column field="cwe" header="CWE ID" sortable></Column>
          <Column field="cvss_score" header="CVSS Score" sortable></Column> */}
          {!type && (
            <Column
              field="status"
              header="Status"
              sortable
              body={(rowData) => {
                const isCurrentRowOpen = selectedRowId === rowData.id;
                return (
                  <div className="relative">
                    {rowData.severity_level === "Low" ||
                    rowData.severity_level === "Info" ? (
                      <span className="flex w-full items-center justify-center">
                        {rowData.status}
                      </span>
                    ) : (
                      <button
                        onClick={() => handleToggleDropDown(rowData.id)}
                        className="w-24 h-10 progressButton rounded-full gap-[6px] flex items-center justify-center text-xs px-3"
                      >
                        {rowData.status ? rowData.status : "null"}
                        <MdKeyboardArrowDown size={18} />
                      </button>
                    )}

                    {isCurrentRowOpen && (
                      <div
                        className="absolute top-12 left-0 bg-white shadow-md rounded-md z-[999]"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {selectStatusType.map((status) => (
                          <button
                            key={status.code}
                            onClick={() => {
                              setSelectedRowId(null);
                              handleChange({ value: status }, rowData);
                            }}
                            className="px-3 py-2 hover:bg-gray-200 text-xs font-semibold w-full text-left"
                          >
                            {status.name}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }}
            ></Column>
          )}

          <Column
            header="Full info"
            body={(rowData) => infoBody(rowData)}
            exportable={false}
          />
        </DataTable>
      )}

      <div>
        <SidebarSlide
          title="Vulnerability Intelligence"
          visibleRight={openVulnerabilitySideBar}
          setVisibleRight={setOpenVulnerabilitySideBar}
          customWidth={true}
          sideBarVocIntelligenceDetail={sideBarVocIntelligenceDetail}
          // loadingSidebarVocDetail={loadingSidebarVocDetail}
        />
      </div>

      <div>
        <SidebarSlide
          title={editData ? "Edit Vulnerability" : "Add New Vulnerability"}
          description=""
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
          getTableFilterData={getTableFilterData}
          editData={editData}
        />
      </div>
      <div>
        <SidebarSlide
          title="Import Vulnerability"
          description=""
          visibleRight={visibleRightImport}
          setVisibleRight={setVisibleRightImport}
        />
      </div>
    </div>
  );
};

export default VulnerabilityOperationTable;
