import { useContext } from "react";

import { ThemeContext } from "../../../context/ThemeContext";
import Header from "../../../common/Charts/Header";

export default function ServiceStatus({ title, tooltip, data, onClick }) {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <div
      className={`lg:col-span-1 p-3 ${
        isDarkMode ? "header-dark" : "bg-white"
      } w-full rounded-2xl`}
    >
      <Header title={title} tooltip={tooltip} between />
      <div
        className={`grid grid-cols-2 border border-gray-200 rounded-md p-2 m-3 divide-x divide-gray-200 cursor-pointer ${
          isDarkMode ? "bg-[#1E1E1E]" : "bg-[#F9F9F9]"
        }`}
        onClick={onClick}
      >
        <p className="text-center text-[#148842]">
          Pass <br />
          {data?.success || 0}
        </p>
        <p className="text-center text-[#E8001D]">
          Fail <br />
          {data?.failure || 0}
        </p>
      </div>
      <p className="my-3">Severity</p>
      <div className="grid grid-cols-4 gap-2 w-full">
        {[
          {
            key: "Critical",
            color: "#A30123",
          },
          {
            key: "High",
            color: "#E8001D",
          },
          {
            key: "Medium",
            color: "#F5B207",
          },
          {
            key: "Low",
            color: "#148842",
          },
        ].map((item, index) => (
          <p
            className={`bg-[${item.color}] text-white text-center text-sm p-1 rounded-md`}
          >
            {data?.[item.key.toLowerCase()] || 0} {item.key}
          </p>
        ))}
      </div>
    </div>
  );
}
