import { Sidebar } from "primereact/sidebar";

export default function ViewAccessReview({ currentDetails, view, setView, columns }) {
    console.log("Current Details ===> ");
    console.log(currentDetails);
    return (
        <Sidebar
            visible={view}
            position="right"
            onHide={() => setView(false)}
            className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
        >
            <p className="font-bold text-2xl text-center">Access Review Details</p>
            <div className="flex flex-col gap-6 p-8 frame">
                <div className="flex items-center justify-between">
                    <div className="">
                        <p className="text-primary font-semibold">{currentDetails?.employee_name}</p>
                        <p className="text-secondary font-normal">Name</p>
                    </div>
                    <div className="mx-auto">
                        <p className="text-primary font-semibold">{currentDetails?.employee_email}</p>
                        <p className="text-secondary font-normal">Email</p>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <div className="w-1/2">
                        <p className="text-primary font-semibold">-</p>
                        <p className="text-secondary font-normal">Resignation date</p>
                    </div>
                    <div className="w-1/2">
                        <p className="text-primary font-semibold">-</p>
                        <p className="text-secondary font-normal">Group</p>
                    </div>
                    <div className="w-1/2">
                        <p className="text-primary font-semibold">-</p>
                        <p className="text-secondary font-normal">Review Status</p>
                    </div>
                </div>
                <div className="">
                    <p className="text-primary font-semibold">{currentDetails?.access_tool.length !== 0 && currentDetails?.access_tool.map((i) => i+" ")}</p>
                    <p className="text-secondary font-normal">Tools</p>
                </div>
            </div>
        </Sidebar>
    )
}
