import React, { useState, useRef } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { REGEX } from "../../../utils/constants";
import { toast } from "react-toastify";

const ChangePasswordForm = (props) => {
  const { setVisibleRight, handleCloseSideBar } = props;
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [newPasswordEye, setNewPasswordEye] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentErrorPassword, setCurrentErrorPassword] = useState(false);
  const [errorPasswordText, setErrorPasswordText] = useState("");
  const [newErrorPassword, setNewErrorPassword] = useState(false);
  const [errorNewPasswordText, setErrorNewPasswordText] = useState("");
  const [confirmErrorPassword, setConfirmErrorPassword] = useState(false);
  const [errorConfirmPasswordText, setErrorConfirmPasswordText] = useState("");

  const handleSavePassword = (e) => {
    e.preventDefault();
    // if (currentPassword === "") {
    //   setCurrentErrorPassword(true);
    //   setErrorPasswordText("Enter the currentPassword");
    // }
    if (newPassword === "") {
      setNewErrorPassword(true);
      setErrorNewPasswordText("Enter the NewPassword");
    } else if (newPassword) {
      REGEX.PASSWORD.test(newPassword)
        ? setNewErrorPassword(false)
        : setNewErrorPassword(true);
      setErrorNewPasswordText(
        "Minimum 12 to 16 characters with uppercase and symbols"
      );
    }
    if (confirmPassword === "") {
      setConfirmErrorPassword(true);
      setErrorConfirmPasswordText("Enter the ConfirmPassword");
    } else if (confirmPassword) {
      if (REGEX.PASSWORD.test(confirmPassword)) {
        if (newPassword !== confirmPassword) {
          setConfirmErrorPassword(true);
          setErrorConfirmPasswordText(
            "Password and confirm password does not match"
          );
        } else {
          setConfirmErrorPassword(false);
        }
      } else {
        setConfirmErrorPassword(true);
        setErrorConfirmPasswordText(
          "Minimum 12 to 16 characters with uppercase and symbols"
        );
      }
    }
    if (
      // currentPassword !== "" &&
      REGEX.PASSWORD.test(newPassword) &&
      newPassword === confirmPassword
    ) {
      TableApi.changePassword({
        // current_password: currentPassword,
        password: confirmPassword,
      }).then((data) => {
        if (data.status === "success") {
          toast.success(data.message);
          setVisibleRight(false);
        } else {
          toast.error(data.message);
        }
      });
    }
  };

  return (
    <>
      <form className="py-5 flex flex-col w-full ">
        <div className="flex flex-wrap -mx-3 mb-2">
          {/* <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">
              Current password (Min 12 - 16 characters)
            </label>
            <div className="relative">
              <input
                className={`${
                  currentErrorPassword &&
                  "border-color-error border-error-focus"
                } placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type={passwordEye === false ? "password" : "text"}
                placeholder="************"
                value={currentPassword}
                onChange={(e) => {
                  setCurrentErrorPassword(false);
                  setCurrentPassword(e.target.value);
                }}
              />
              {currentErrorPassword && (
                <p className="text-red-500 text-sm">{errorPasswordText}</p>
              )}
              <button
                type="button"
                className="absolute top-5 right-2 transform -translate-y-1/2 icon-color focus:outline-none"
                onClick={() => setPasswordEye(!passwordEye)}
              >
                {passwordEye ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>
          </div> */}
        </div>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">
              New password (Min 12 - 16 characters)
            </label>
            <div className="relative">
              <input
                className={`${
                  newErrorPassword && "border-color-error border-error-focus"
                } placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type={newPasswordEye === false ? "password" : "text"}
                placeholder="************"
                value={newPassword}
                onChange={(e) => {
                  setNewErrorPassword(false);
                  setNewPassword(e.target.value);
                }}
              />
              {newErrorPassword && (
                <p className="text-red-500 text-sm">{errorNewPasswordText}</p>
              )}
              <button
                type="button"
                className="absolute top-5 right-2 transform -translate-y-1/2 icon-color focus:outline-none"
                onClick={() => setNewPasswordEye(!newPasswordEye)}
              >
                {newPasswordEye ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">
              Confirm password (Min 12 - 16 characters)
            </label>
            <div className="relative">
              <input
                className={`${
                  confirmErrorPassword &&
                  "border-color-error border-error-focus"
                } placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type={confirmPasswordEye === false ? "password" : "text"}
                placeholder="************"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmErrorPassword(false);
                  setConfirmPassword(e.target.value);
                }}
              />
              {confirmErrorPassword && (
                <p className="text-red-500 text-sm">
                  {errorConfirmPasswordText}
                </p>
              )}
              <button
                type="button"
                className="absolute top-5 right-2 transform -translate-y-1/2 icon-color focus:outline-none"
                onClick={() => setConfirmPasswordEye(!confirmPasswordEye)}
              >
                {confirmPasswordEye ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="pt-3 flex w-full">
        <button
          onClick={(e) => handleSavePassword(e)}
          className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
        >
          Save
        </button>
        <button
          className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
          onClick={handleCloseSideBar}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default ChangePasswordForm;
