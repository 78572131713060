import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { ThemeContext } from "../../../../context/ThemeContext";
import paginationTemplate from "../../../../utils/paginationTemplate";
import SkeletonDataTable from "../../../../modules/SkeletonAnimation/SkeletonDataTable";
import TableFilter from "../../../../common/Tables/TableFilter";
import { setUserTableData } from "../../../../redux-toolkit/redux/TableData";
import { SidebarSlide } from "../../../../Component/Header/SidebarSlide";
import { complianceAPI } from "../../../../Api/CSPM/api";
import { getFilter } from "../../../../utils/utils";

export default function SecurityRole() {
  const ref = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [data, setData] = useState([]);
  const { isDarkMode } = useContext(ThemeContext);
  const [filterData, setFilterData] = useState(null);
  const [currentDetails, setCurrentDetails] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterParams, setFilterParams] = useState({});
  const [view, setView] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getFilterData = () => {
    complianceAPI
      .get("/security-role/filter")
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    setLoading(true);
    complianceAPI
      .post("/security-role/filter/", getFilter(filterData, filterParams))
      .then((res) => {
        // setData(
        //     res.data.map((item) => ({
        //     ...item,
        //     status: item.status ? "Active" : "Pending",
        //     }))
        // );
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  const columns = [
    {
      field: "role",
      header: "Role Name",
      //   value: filterData?.first_name,
    },
    {
      field: "description",
      header: "Description",
      // value: filterData?.last_name
    },
    {
      field: "employee_email",
      header: "Assigned to",
      //   value: filterData?.email,
    },
  ];

  // console.log("GPT ", getFilter(filterData, filterParams))

  return (
    <>
      <div className="flex flex-wrap  dashboard-table">
        <p className="font-bold">
          Security Role <span> ({data?.length || 0}) </span>
        </p>
      </div>
      <>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          columns={columns}
          tableData={data}
          tableHeader={columns}
          dt={ref}
          isDarkMode={isDarkMode}
          btnName="Add"
          btnText={true}
          setVisibleRight={() => setAddVisibility(true)}
          // IntegrationComponent={integrateOrImport}
        />
        {loading ? (
          <SkeletonDataTable
            columns={columns}
            // columns={[
            //     ...columns,
            //     { field: "full_info", header: "Full info" },
            // ]}
          />
        ) : (
          <DataTable
            ref={ref}
            removableSort
            value={data.map((i) => {
              return i;
              // department: departments?.find((j) => j.id === i.department)
              //     ?.name,
            })}
            paginator
            rows={10}
            sortField="severity"
            sortOrder={-1}
            sortMode="multiple"
            globalFilter={globalFilter}
            paginatorTemplate={paginationTemplate()}
            className={`${
              isDarkMode ? "dark-mode text-dark" : "text-color"
            } text-xs cursor-pointer`}
            onRowClick={({ data }) => {
              navigate(`/compliance/employee/${data.id}`);
            }}
          >
            {columns.map((col, i) => {
              return (
                <Column
                  key={i}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              );
            })}
            <Column
              key="action"
              field="action"
              header="Action"
              body={(rowData) => (
                <SplitButton
                  icon={<FiMoreVertical size={24} />}
                  className="p-button-text outline-none split-style"
                  model={[
                    {
                      label: "Edit",
                      icon: "pi pi-refresh",
                      command: () => {
                        setCurrentDetails(rowData);
                        dispatch(setUserTableData(rowData));
                        setView(false);
                      },
                    },
                    // {
                    //     label: "View",
                    //     icon: "pi pi-times",
                    //     command: () => {
                    //     setCurrentDetails(rowData);
                    //     dispatch(setUserTableData(rowData));
                    //     setView(true);
                    //     },
                    // },
                  ]}
                ></SplitButton>
              )}
            />
          </DataTable>
        )}
      </>
      <div className="side-user-info-style">
        <SidebarSlide
          title="Add Assignee"
          description="Add security role"
          visibleRight={addVisibility}
          setVisibleRight={setAddVisibility}
          // userTableViewData={view}
          // role={[]}
          getTableFilterData={getFilterData}
        />
      </div>
      <div className="side-user-info-style">
        <SidebarSlide
          title="Edit Assignee"
          description="Edit security role"
          visibleRight={currentDetails}
          setVisibleRight={setCurrentDetails}
          userTableViewData={view}
          role={[]}
          getTableFilterData={getFilterData}
        />
      </div>
    </>
  );
}
