import { Sidebar } from "primereact/sidebar";
import Toggle from "react-toggle";
import { BsCheckLg } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import axios from "axios";
import { toast } from "react-toastify";
import fileUpload from "../../../../../utils/fileUpload";
import { DateTimeToDate } from "../../../../../helpers";
import HandleResponseError from "../../../../../utils/error";
import { GrDocument } from "react-icons/gr";
import { GoDownload } from "react-icons/go";
import { MdDeleteOutline } from "react-icons/md";
import PdfViewerSideBar from "./pdfViewerSidebar";
import { FaEye } from "react-icons/fa";

export default function ComplianceView({ onHide, currentDetails, isPublic }) {
  const [data, setData] = useState({});
  const [viewPDF, setViewPDF] = useState(null);
  const fileInputRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    console.info(data);
    if (currentDetails?.id) {
      complianceAPI
        .patch(`/trust-center/compliance/${currentDetails.id}/`, data)
        .then((res) => {
          setData(res);
          toast.success("Compliance updated successfully");
          onHide();
        })
        .catch((err) => {
          HandleResponseError(err);
          console.log(err);
        });
    } else {
      complianceAPI
        .post("/trust-center/compliance/", data)
        .then((res) => {
          setData(res);
          toast.success("Compliance added successfully");
          onHide();
        })
        .catch((err) => {
          HandleResponseError(err);
          console.log(err);
        });
    }
  };

  const uploadAttachment = () => {
    const file = fileInputRef.current.files[0];

    if (file) {
      fileUpload("v1/compliance/attachments/", file)
        .then((res) => {
          if (res.status === "success") {
            setData({
              ...data,
              attachments: data?.attachments
                ? [...data.attachments, res.data.id]
                : [res.data.id],
              attachments_info: data?.attachments_info
                ? [...data.attachments_info, res.data]
                : [res.data],
            });
          } else {
            HandleResponseError(res.message);
          }
        })
        .catch((err) => {
          toast.error("Error in uploading attachment");
        });
    }
  };

  const handleDownload = (file) => {
    axios
      .get(file.file, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers.contentType,
        });
        const url = URL.createObjectURL(blob);

        // Create a link and trigger a click event to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name; // Change file extension to .yml
        a.click();

        // Release the URL object
        URL.revokeObjectURL(url);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (id) => {
    const updatedAttachements = currentDetails.attachments_info.filter(
      (i) => i.id != id
    );
    complianceAPI
      .patch(`/trust-center/compliance/${currentDetails.id}/`, {
        attachments: updatedAttachements.map((i) => i.id),
      })
      .then((res) => {
        // setData({
        //   ...currentDetails,
        //   attachments: updatedAttachements.map((i) => i.id),
        //   attachments_info: updatedAttachements,
        // });
        setData(res);
        toast.success("Attachment removed successfully");
      })
      .catch((err) => {
        toast.error("Error in uploading attachment");
      });
  };

  const cleanClose = () => {
    onHide();
  };

  useEffect(() => {
    setData({
      compliance: currentDetails?.name,
      private: currentDetails?.private,
      implementation_level: currentDetails?.implementation_level,
      auditor_name: currentDetails?.auditor_name,
      description: currentDetails?.description,
      attachments: currentDetails?.attachments,
      attachments_info: currentDetails?.attachments_info,
    });
  }, [currentDetails]);

  return (
    <>
      {" "}
      <Sidebar
        visible={currentDetails}
        position="right"
        onHide={cleanClose}
        className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
      >
        <form onSubmit={onSubmit}>
          <p className="font-bold text-2xl">Compliance</p>
          <div className="flex justify-between my-2 items-center">
            <p className="font-bold text-xl">{currentDetails?.name}</p>

            {!isPublic && (
              <div className="flex gap-2">
                <p>Private access</p>
                <Toggle
                  // checked={item.value}
                  icons={{
                    checked: (
                      <BsCheckLg
                        size={10}
                        style={{
                          color: "white",
                          position: "absolute",
                        }}
                      />
                    ),
                    unchecked: null,
                  }}
                  onChange={(e) => {
                    setData({ ...data, private: e.target.checked });
                  }}
                  defaultChecked={currentDetails?.private}
                />
              </div>
            )}
          </div>
          {currentDetails?.best_practices && !isPublic && (
            <div className="my-3 border rounded-md bg-slate-50 p-3">
              <p className="font-bold">Best Practices:</p>
              <p
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html: currentDetails.best_practices,
                }}
              ></p>
            </div>
          )}
          {!isPublic && (
            <>
              <p className="text-md font-bold mt-2">Implementation Level</p>
              <div className="flex gap-2">
                <input
                  type="radio"
                  name="implementation_level"
                  id="N/A"
                  onChange={(e) => {
                    setData({ ...data, implementation_level: "n/a" });
                  }}
                  checked={data.implementation_level === "n/a" ? true : false}
                />
                <label htmlFor="N/A">N/A</label>
                <input
                  type="radio"
                  name="implementation_level"
                  id="In Progress"
                  onChange={(e) => {
                    setData({ ...data, implementation_level: "in_progress" });
                  }}
                  checked={
                    data.implementation_level === "in_progress" ? true : false
                  }
                />
                <label htmlFor="In Progress">In Progress</label>
                <input
                  type="radio"
                  name="implementation_level"
                  id="Fully Implemented"
                  onChange={(e) => {
                    setData({
                      ...data,
                      implementation_level: "fully_implemented",
                    });
                  }}
                  checked={
                    data.implementation_level === "fully_implemented"
                      ? true
                      : false
                  }
                />
                <label htmlFor="Fully Implemented">Fully Implemented</label>
              </div>
            </>
          )}

          <p className="text-md font-bold mt-2">Auditor</p>
          <label className="block text-gray-500 text-sm font-semibold ">
            Name
          </label>
          {isPublic ? (
            <p>{currentDetails?.auditor_name}</p>
          ) : (
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Auditor name"
              name="auditor_name"
              value={data.auditor_name}
              onChange={(e) => {
                setData({ ...data, auditor_name: e.target.value });
              }}
            />
          )}

          <label className="block text-gray-500 text-sm font-semibold">
            {isPublic ? "Description" : "Description"}
          </label>
          {isPublic ? (
            <p>{currentDetails?.description}</p>
          ) : (
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter description"
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
              }}
            />
          )}
          {!isPublic && (
            <>
              <p
                className="font-bold mt-2 cursor-pointer"
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                + ADD ATTACHMENT
              </p>
              <input
                ref={fileInputRef}
                type="file"
                name="file"
                accept="application/pdf"
                hidden
                onChange={uploadAttachment}
              />
            </>
          )}

          <div className="flex flex-col pt-6 gap-2">
            {data?.attachments_info?.map((a, i) => (
              <div
                key={i}
                className="grid grid-cols-2 w-full p-2 items-center gap-2 rounded border cursor-pointer"
                onClick={() => setViewPDF(a.file)}
              >
                <div className="flex items-center">
                  <GrDocument />
                  <div className="p-1 ml-2 flex flex-col">
                    <p className="text-sm text-color font-normal leading-5">
                      {a.name}
                    </p>
                    <p className="text-xs">{DateTimeToDate(a.created_at)}</p>
                  </div>
                </div>
                {!isPublic && (
                  <div className="justify-self-end flex divide-x gap-2">
                    <div
                      className="text-sm flex items-center gap-1 cursor-pointer"
                      onClick={() => setViewPDF(a.file)}
                    >
                      <FaEye />
                      <p>View</p>
                    </div>
                    <div
                      className="text-sm flex items-center gap-1 cursor-pointer"
                      onClick={() => handleDownload(a)}
                    >
                      <GoDownload />
                      <p>Download</p>
                    </div>
                    <div
                      className="text-sm flex items-center gap-1 cursor-pointer"
                      onClick={() => handleDelete(a.id)}
                    >
                      <MdDeleteOutline />
                      <p>Delete</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          {!isPublic && (
            <div className="flex w-full mt-9 justify-end">
              <button
                className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff]"
                type="submit"
                id="submit"
              >
                {currentDetails ? "Save" : "Add"}
              </button>
            </div>
          )}
        </form>
      </Sidebar>
      {viewPDF && (
        <PdfViewerSideBar viewPDF={viewPDF} setViewPDF={setViewPDF} />
      )}
    </>
  );
}
