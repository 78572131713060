import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiArrowUpRight, FiMoreVertical } from "react-icons/fi";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../../context/ThemeContext";
import TableFilter from "../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../SkeletonAnimation/SkeletonDataTable";
import paginationTemplate from "../../../utils/paginationTemplate";
import { complianceAPI } from "../../../Api/CSPM/api";
import { DateTimeToDate } from "../../../helpers";
import HandleResponseError from "../../../utils/error";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin } from "../../../utils/accessCheck";
import { useNavigate } from "react-router-dom";
import customAxios, { baseUrl } from "../../../Api/Repository";
import { UserFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";
import { BsDot } from "react-icons/bs";
import { ProgressBar } from "primereact/progressbar";

export default function Scans({
  url_path,
  IntegrationComponent = null,
  allowAdd = true,
}) {
  const ref = useRef(null);

  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [editDetails, setEditDetails] = useState(null);
  const [viewDetails, setViewDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);
  const [first, setFirst] = useState(0);
  const [user, setUser] = useState([]);

  const getFilterData = () => {
    customAxios
      .get(`${baseUrl}v1/web/scan/filter/`)
      .then(({ data }) => {
        setFilterData(data.data); // For Filter Sidebar
        setFilterParams(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = useCallback(() => {
    setLoading(true);
    customAxios
      .post(`${baseUrl}v1/web/scan/filter/`, { ...filterParams })
      .then(({ data }) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [filterParams]);

  const renderActions = (item) => {
    return [
      {
        label: "Edit",
        icon: "pi pi-refresh",
        command: () => {
          setEditDetails(data?.find((i) => item.id === i.id));
        },
      },
    ];
  };

  useEffect(() => {
    getFilterData();
    getData();
  }, []);

  const columns = [
    {
      field: "id",
      header: "ID",
    },
    {
      field: "date_added",
      header: "Date",
      type: "date",
    },
    {
      field: "target_info.domain",
      header: "Domain",
    },
    {
      field: "user_info.email",
      header: "User",
    },
    {
      field: "status",
      header: "Status",
      type: "scan",
    },
    {
      field: "scan_type",
      header: "Type",
      type: "select",
      value: [
        {
          label: "Brand Thread",
          value: "brand_threat",
        },
        {
          label: "Default scan",
          value: "default_scan",
        },
        {
          label: "Discovery",
          value: "discovery",
        },
        {
          label: "Digital Risk Protection",
          value: "drp",
        },
      ],
      // value: filterData?.scan_type,
    },
    {
      field: "initiated_by_info.email",
      header: "Initiated",
    },
  ];

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <>
          <TableFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            getFilterData={getFilterData}
            FilterData={filterData}
            columns={columns?.map((item) => ({
              ...item,
              value: filterData?.hasOwnProperty(item.field)
                ? item.value?.filter((v) =>
                    filterData[item.field]?.includes(v.value)
                  ) || filterData[item.field]
                : null,
            }))}
            tableData={data}
            tableHeader={columns}
            dt={ref}
            isDarkMode={isDarkMode}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              ref={ref}
              removableSort
              value={
                data &&
                data?.map((d) => {
                  const newData = { ...d };
                  columns.forEach((col) => {
                    if (col.type === "select") {
                      // if any select option in update data from here itself
                      newData[col.field] = col.value.find(
                        (o) => o.value === d[col.field]
                      )?.label;
                    } else if (col.type === "multi_select") {
                      newData[col.field] = col.value
                        .filter((o) => d[col.field].includes(o.value))
                        ?.map((o) => o.label)
                        .join(", ");
                    } else if (col.type === "date") {
                      // for date type data
                      if (col.field?.includes(".")) {
                        const nestedFields = col.field.split(".");
                        let value = newData;
                        for (let i = 0; i < nestedFields.length; i++) {
                          value = value[nestedFields[i]];
                        }
                        newData[col.field] = DateTimeToDate(value);
                      } else {
                        newData[col.field] = DateTimeToDate(d[col.field]);
                      }
                    }
                  });
                  return newData;
                })
              }
              paginator
              rows={10}
              first={first}
              onPage={(e) => {
                setFirst(e.first);
              }}
              sortMode="multiple"
              globalFilter={globalFilter}
              paginatorTemplate={paginationTemplate()}
              onRowClick={(e) => {
                navigate("scan", { state: { scan: e.data.id } });
              }}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs cursor-pointer`}
            >
              {columns
                .filter((item) => {
                  if (item.admins) {
                    return (
                      isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin(
                        userDetails.role
                      ) ||
                      (userDetails.role === "Editor" &&
                        userDetails.module.includes("Action Button"))
                    );
                  } else {
                    return true;
                  }
                })
                ?.map((col, i) => {
                  if (col.type === "full_info") {
                    return (
                      <Column
                        header="Full info"
                        exportable={false}
                        body={(rowData) => (
                          <div className="flex gap-2">
                            <button onClick={() => setViewDetails(rowData)}>
                              <FiArrowUpRight size={20} color="#074EE8" />
                            </button>
                          </div>
                        )}
                      />
                    );
                  } else if (col.type === "func") {
                    return (
                      <Column
                        key={i}
                        field={col.field}
                        header={col.header}
                        exportable={false}
                        body={(e) => col.func(e)}
                      />
                    );
                  } else if (col.type === "action") {
                    return (
                      <Column
                        key={i}
                        field={col.field}
                        header={col.header}
                        exportable={false}
                        body={(e) => (
                          <SplitButton
                            icon={<FiMoreVertical size={24} />}
                            className="p-button-text outline-none split-style"
                            model={renderActions(e)}
                          ></SplitButton>
                        )}
                      />
                    );
                  } else if (col.type === "scan") {
                    return (
                      <Column
                        field="status"
                        header="Status"
                        body={(rowData) => {
                          if (rowData.status === "completed") {
                            return (
                              <div
                                className={`activeStatusClass flex gap-1 items-center`}
                              >
                                <BsDot size={24} />
                                Completed
                              </div>
                            );
                          } else if (rowData.status === "in_progress") {
                            return (
                              <>
                                <div
                                  className={`progressStatusClass flex gap-1 items-center`}
                                >
                                  <BsDot size={24} />
                                  In Progress{" "}
                                  {rowData.progress > "0"
                                    ? parseInt(rowData.progress)
                                    : 0}
                                  %
                                </div>
                                <div className=" pl-[30px] progressBarStyle">
                                  <ProgressBar
                                    value={Number(rowData.progress)}
                                  />
                                </div>
                              </>
                            );
                          } else if (rowData.status === "not_scanned") {
                            return (
                              <div
                                className={`notscannedStatusClass flex gap-1 items-center`}
                              >
                                <BsDot size={24} />
                                Not Scanned
                              </div>
                            );
                          } else if (rowData.status === "error") {
                            return (
                              <div
                                className={`errorStatusClass flex gap-1 items-center`}
                              >
                                <BsDot size={24} />
                                Error
                              </div>
                            );
                          } else if (rowData.status === "cancelled") {
                            return (
                              <div
                                className={`errorStatusClass flex gap-1 items-center`}
                              >
                                <BsDot size={24} />
                                Cancelled
                              </div>
                            );
                          }
                        }}
                        sortable
                      ></Column>
                    );
                  }
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                })}
            </DataTable>
          )}
        </>
      </div>
    </>
  );
}
