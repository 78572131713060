import { useContext } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import fileUpload from "../../../../utils/fileUpload";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  setUserDetails,
} from "../../../../redux-toolkit/redux/auth";

export default function Settings() {
  const { isDarkMode } = useContext(ThemeContext);

  const userDetails = useSelector(getUserDetails);
  const dispatch = useDispatch();

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    const file = e.target.file.files[0];

    if (file) {
      fileUpload("v1/settings/", file, "logo")
        .then((res) => {
          toast.success(res.message);
          e.target.submit.removeAttribute("disabled");
          dispatch(setUserDetails({ ...userDetails, logo: res?.data?.path }));
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error in uploading attachment");
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      toast.error("Select a file");
      e.target.submit.removeAttribute("disabled");
    }
  };

  return (
    <>
      <div
        className={`p-8 rounded-2xl relative flex flex-col justify-between ${
          isDarkMode ? "header-dark text-white" : "white-background text-color"
        }`}
      >
        {/* logo uploader */}
        <p className="font-bold">Logo</p>
        <form onSubmit={(e) => handleCreate(e)}>
          <div className="flex gap-2 items-center">
            <div className="w-1/2 pt-3">
              <label
                className="block text-gray-500 text-sm mb-1"
                for="file_input"
              >
                Upload file
              </label>
              <input
                className="w-full cursor-pointer rounded-lg border-[1px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter dark:file:text-gray-500 file:py-2 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:focus:border-primary"
                name="file"
                type="file"
                accept="image/*"
              />
            </div>
            <div className="justify-items-center">
              <button
                className="mt-9 ml-2 px-4 border  rounded-[16px] border-[#AAAAAA] h-[40px] bg-[#181818] text-[#ffff] cursor-pointer"
                type="submit"
                id="submit"
              >
                Upload Logo
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
