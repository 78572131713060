import { FaRegUser } from "react-icons/fa";
import { MdOutlinePolicy } from "react-icons/md";
import { SiReadthedocs } from "react-icons/si";
import { HiOutlineExternalLink } from "react-icons/hi";
import { IoWarningOutline } from "react-icons/io5";
import { MdOutlinePendingActions } from "react-icons/md";
import { PiVault } from "react-icons/pi";
import { SiFramework } from "react-icons/si";
import { FaStethoscope } from "react-icons/fa6";
import { IoMdCloudOutline } from "react-icons/io";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { IoLibraryOutline } from "react-icons/io5";
import { RiAlarmWarningLine } from "react-icons/ri";

export const tabs = [
  { id: 1, tabName: "user information", tab: "userInformation" },
  // {id:2, tabName: 'user management', tab: 'userManagement' },
  { id: 3, tabName: "my projects", tab: "myProjects" },
  { id: 4, tabName: "security", tab: "security" },
  { id: 5, tabName: "subscription", tab: "subscription" },
  { id: 6, tabName: "Integrations", tab: "integrations" },
  { id: 7, tabName: "Departments", tab: "departments", admin: true },
  { id: 8, tabName: "Entities", tab: "entities", admin: true },
  { id: 9, tabName: "Settings", tab: "settings", admin: true },
  { id: 10, tabName: "Compliance", tab: "compliance", superAdmin: true },
  { id: 9, tabName: "Access Token", tab: "access_token", admin: true },
];

export const attackSurfaceDropdownItems = [
  {
    section: "assets discovery",
    subLinks: [
      { navLink: "/attack-surface-management/dns-record", link: "Domain" },
      { navLink: "/attack-surface-management/sub-domain", link: "Subdomain" },
      { navLink: "/attack-surface-management/live-hosts", link: "Live Hosts" },
      { navLink: "/attack-surface-management/ip-address", link: "IP Address" },
      { navLink: "/attack-surface-management/open-ports", link: "Open Ports" },
      {
        navLink: "/attack-surface-management/end-points",
        link: "URL Endpoints",
      },
      {
        navLink: "/attack-surface-management/technologies",
        link: "Technologies",
      },
      // {
      //   navLink: "/attack-surface-management/dns-record",
      //   link: "Hosting Provider",
      // },
    ],
  },
  {
    section: "vulnerabilities intelligence analysis",
    subLinks: [
      {
        navLink: "/attack-surface-management/web-server-vulnerabilities",
        link: "Web Server Vulnerabilities",
      },
      {
        navLink: "/attack-surface-management/web-application-vulnerabilities",
        link: "Web Application Vulnerabilities",
      },
      {
        navLink: "/attack-surface-management/exploit-intelligence",
        link: "Exploit Vulnerabilities",
      },
    ],
  },
  {
    section: "compliance",
    subLinks: [
      {
        navLink: "/attack-surface-management/compliance#IS0-27001",
        link: "ISO 27001:2022",
      },
      {
        navLink: "/attack-surface-management/compliance#PCI-DSS",
        link: "PCI DSS",
      },
      { navLink: "/attack-surface-management/compliance#SOC-2", link: "SOC 2" },
      { navLink: "/attack-surface-management/compliance#GDPR", link: "GDPR" },
      {
        navLink: "/attack-surface-management/compliance#OWASP",
        link: "OWASP 2021",
      },
      {
        navLink: "/attack-surface-management/compliance#NIST",
        link: "NIST 800-53",
      },
    ],
  },
];

export const digitalThreatDropdownItems = [
  {
    section: "data breach intelligence",
    subLinks: [
      {
        navLink:
          "/digital-risk-protection/data-breach-intelligence#ComputerCompromised",
        link: "Compromised computer",
      },
      {
        navLink:
          "/digital-risk-protection/data-breach-intelligence#EmployeeCompromised",
        link: "Compromised employee",
      },
      {
        navLink:
          "/digital-risk-protection/data-breach-intelligence#CompromisedUser",
        link: "Combo List",
      },
      {
        navLink:
          "/digital-risk-protection/data-breach-intelligence#EmployeeCredentials",
        link: "Compromised credentials",
      },
      {
        navLink:
          "/digital-risk-protection/data-breach-intelligence#CompromisedURL",
        link: "Compromised url",
      },
      {
        navLink:
          "/digital-risk-protection/data-breach-intelligence#CompromisedFiles",
        link: "Compromised files",
      },
      {
        navLink:
          "/digital-risk-protection/data-breach-intelligence#ThirdPartyURL",
        link: "third party url",
      },
      {
        navLink:
          "/digital-risk-protection/data-breach-intelligence#ThirdPartyCredentials",
        link: "third party credentials",
      },
    ],
  },
  {
    section: "dark web mentions",
    subLinks: [
      {
        navLink: "/digital-risk-protection/dark-web#DarkWeb",
        link: "Hacker Forums",
      },
      {
        navLink: "/digital-risk-protection/dark-web#Telegram",
        link: "telegram",
      },
      {
        navLink: "/digital-risk-protection/dark-web#PasteSites",
        link: "paste sites",
      },
    ],
  },
  {
    section: "secrets leak",
    subLinks: [
      {
        navLink: "/digital-risk-protection/data-leak-lntelligence#Github",
        link: "github",
      },
      // {
      //   navLink:
      //     "/digital-risk-protection/data-leak-lntelligence#JavaScriptLeaks",
      //   link: "java script",
      // },
    ],
  },
  {
    section: "phishing intelligence",
    subLinks: [
      {
        navLink: "/digital-risk-protection/phishing",
        link: "phishing intelligence",
      },
    ],
  },
  {
    section: "brand threat intelligence",
    subLinks: [
      {
        navLink: "/digital-risk-protection/brand-threat-intelligence#Twitter",
        link: "twitter",
      },
      {
        navLink: "/digital-risk-protection/brand-threat-intelligence#Instagram",
        link: "Instagram",
      },
      {
        navLink: "/digital-risk-protection/brand-threat-intelligence#Youtube",
        link: "youtube",
      },
      {
        navLink: "/digital-risk-protection/brand-threat-intelligence#Facebook",
        link: "facebook",
      },
      {
        navLink: "/digital-risk-protection/brand-threat-intelligence#FakeApps",
        link: "fake apps",
      },
    ],
  },
];

export const complianceDropdownItems = [
  {
    section: "Framework",
    subLinks: [
      {
        navLink: "/compliance/framework",
        link: "Framework",
      },
      {
        navLink: "/compliance/controls",
        link: "Controls",
      },
    ],
  },
  {
    section: "People",
    subLinks: [
      {
        navLink: "/compliance/security-awareness",
        link: "Security Awareness",
      },
      {
        navLink: "/compliance/employee",
        link: "Employee",
      },
      {
        navLink: "/compliance/training-portal",
        link: "Employee Training Portal",
        isAdmin: true,
      },
      {
        navLink: "/compliance/access-review",
        link: "Automated Access Review",
      },
    ],
    icon: FaRegUser,
  },
  {
    navLink: "/compliance/policy",
    link: "Policy",
    icon: MdOutlinePolicy,
  },
  {
    navLink: "/compliance/evidence",
    link: "Evidence",
    icon: SiReadthedocs,
  },
  {
    navLink: "/compliance/vendor",
    link: "Vendor Management",
    icon: HiOutlineExternalLink,
  },
  {
    navLink: "/compliance/risk",
    link: "Risk Management",
    icon: IoWarningOutline,
  },
  {
    navLink: "/compliance/corrective-action",
    link: "Corrective Action Center",
    icon: MdOutlinePendingActions,
  },
  {
    navLink: "/compliance/audit",
    link: "Audit Center",
    icon: FaStethoscope,
  },
  {
    navLink: "/compliance/incident-management",
    link: "Incident Management",
    icon: RiAlarmWarningLine,
  },
  {
    link: "Vault",
    icon: PiVault,
    navLink: "/compliance/vault",
    // subLinks: [
    //   {
    //     navLink: "/compliance/vault-folder",
    //     link: "Folder",
    //   },
    //   {
    //     navLink: "/compliance/vault-file",
    //     link: "File",
    //   },
    // ],
  },
  {
    link: "Cloud Security Assessment",
    icon: IoMdCloudOutline,
    navLink: "/compliance",
  },
  {
    link: "Trust Center",
    icon: VscWorkspaceTrusted,
    navLink: "/compliance/trust-center",
  },
  {
    link: "Template Library",
    icon: IoLibraryOutline,
    navLink: "/compliance/template-library",
    isAdmin: true,
  },
];

export const modules = [
  { id: 1, isActive: true, moduleName: "attack surface management" },
  { id: 2, isActive: false, moduleName: "deep & dark web monitoring" },
  { id: 3, isActive: false, moduleName: "data leak detection" },
  { id: 4, isActive: false, moduleName: "brand threat monitoring" },
  { id: 5, isActive: false, moduleName: "vulnerability operation center" },
];

export const optionsIncluded = [
  { id: 1, optionName: "Domain", optionTime: "1" },
  { id: 2, optionName: "Keywords", optionTime: "5" },
  { id: 3, optionName: "Subdomains", optionTime: "10" },
  { id: 4, optionName: "Scan Interval", optionTime: "Bi-weekly" },
  { id: 5, optionName: "Users", optionTime: "5" },
];

export const subscriptionTableData = [
  {
    id: 1,
    subscriptionPlan: "Basic",
    activationPeriod: "Aug 1, 2023 - Aug 31, 2023",
    amount: "$500",
    date: "Aug 1, 2023",
    status: "Active",
  },
  {
    id: 2,
    subscriptionPlan: "Standard",
    activationPeriod: "Jul 1, 2023 - Jul 31, 2023",
    amount: "$800",
    date: "Jul 1, 2023",
    status: "Expired",
  },
  {
    id: 3,
    subscriptionPlan: "Enterprise",
    activationPeriod: "Jun 1, 2023 - Jun 30, 2023",
    amount: "$500",
    date: "Jun 1, 2023",
    status: "Expired",
  },
  {
    id: 4,
    subscriptionPlan: "Basic",
    activationPeriod: "Aug 1, 2023 - Aug 31, 2023",
    amount: "$500",
    date: "Aug 1, 2023",
    status: "Active",
  },
  {
    id: 5,
    subscriptionPlan: "Standard",
    activationPeriod: "Jul 1, 2023 - Jul 31, 2023",
    amount: "$800",
    date: "Jul 1, 2023",
    status: "Expired",
  },
  {
    id: 6,
    subscriptionPlan: "Enterprise",
    activationPeriod: "Jun 1, 2023 - Jun 30, 2023",
    amount: "$500",
    date: "Jun 1, 2023",
    status: "Expired",
  },
];

export const userInformation = [
  {
    id: 1,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 2,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 3,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 4,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 5,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 6,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 7,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 8,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 9,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 10,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 11,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
  {
    id: 12,
    name: "John",
    surname: "Hammond",
    email: "info@cyberheals.com",
    projectsAssigned: "1",
    role: "Admin",
    mfa: "disabled",
  },
];

export const darkWebTableData = [
  {
    id: 1,
    keyboard: "www.cyberheals.com",
    thread: "info@cyberheals.com",
    severity: "Completed",
    date: "Aug 18, 2023",
    source: "Aug 18, 2023",
    tags: "initiate tags",
    status: "active",
  },
  {
    id: 2,
    keyboard: "www.ultraheals.com",
    thread: "rd@cyberheals.com",
    severity: "Completed",
    date: "Aug 18, 2023",
    source: "Aug 18, 2023",
    tags: "initiate tags",
    status: "active",
  },
  {
    id: 3,
    keyboard: "www.mobiheals.com",
    thread: "mobi@cyberheals.com",
    severity: "In progess 77%",
    date: "Aug 18, 2023",
    source: "",
    tags: "initiate scanning",
    status: "active",
  },
];

export const planData = [
  {
    id: 1,
    title: "Basic",
    subTitle: "Perfect plan for starters",
    domainNo: "1",
    domain: "domain",
    keywordNo: "5",
    keyword: "keywords",
    subDomainNo: "10",
    subDomain: "subdomains",
    scanning: "scanning",
    scanType: "bi-weekly",
    users: "users",
    usersNo: "5",
    btnName: "Calculate",
  },
  {
    id: 2,
    title: "Standard",
    subTitle: "Best for growing companies",
    domainNo: "1",
    domain: "domain",
    keywordNo: "5",
    keyword: "keywords",
    subDomainNo: "10",
    subDomain: "subdomains",
    scanning: "scanning",
    scanType: "bi-weekly",
    users: "users",
    usersNo: "5",
    btnName: "Calculate",
    currentPlan: true,
  },
  {
    id: 3,
    title: "Enterprise",
    subTitle: "Optimal for large teams",
    domainNo: "1",
    domain: "domain",
    keywordNo: "5",
    keyword: "keywords",
    subDomainNo: "10",
    subDomain: "subdomains",
    scanning: "scanning",
    scanType: "bi-weekly",
    users: "users",
    usersNo: "5",
    btnName: "Calculate",
  },
  {
    id: 4,
    title: "Custom",
    subTitle: "Create your personal plan",
    domainNo: "",
    domain: "",
    keywordNo: "",
    keyword: "",
    subDomainNo: "custom",
    subDomain: "options",
    scanning: "",
    scanType: "",
    users: "",
    usersNo: "",
    btnName: "Calculate",
  },
];

export const vulnerabilityTableData = [
  {
    id: 1,
    hosts: "owa.gordonlawfirm.com",
    ipAddress: "64.179.192.174",
    vulnerability: "Weak Cipher Suites Detection",
    severity: "Medium",
    cve: "CVE-2021-34473",
    cweId: "CVE-2021-341",
    cvssScore: "9.1",
    status: "Risk Accepted",
  },
  {
    id: 2,
    hosts: "owa.gordonlawfirm.com",
    ipAddress: "64.179.192.174",
    vulnerability: "Weak Cipher Suites Detection",
    severity: "Medium",
    cve: "CVE-2021-34473",
    cweId: "CVE-2021-341",
    cvssScore: "9.1",
    status: "Risk Accepted",
  },
];

export const scanHistoryData = [
  {
    id: 1,
    date: "2023-09-28T00:00:00Z",
  },
  {
    id: 2,
    date: "2023-09-28T01:00:00Z",
  },
  {
    id: 3,
    date: "2023-09-28T02:00:00Z",
  },
  {
    id: 4,
    date: "2023-09-28T03:00:00Z",
  },
  {
    id: 5,
    date: "2023-09-28T04:00:00Z",
  },
  {
    id: 6,
    date: "2020-01-01T00:00:00Z",
  },
  {
    id: 7,
    date: "2020-02-01T00:00:00Z",
  },
  {
    id: 8,
    date: "2021-03-01T00:00:00Z",
  },
  {
    id: 9,
    date: "2021-04-01T00:00:00Z",
  },
  {
    id: 10,
    date: "2022-05-01T00:00:00Z",
  },
];

export const loginHistoryData = [
  {
    id: 1,
    date: "2023-09-28T00:00:00Z",
    ipAddress: "64.179.192.174",
    browser: "Chrome",
    agent: "Mozilla/5.0 (Version 10)",
  },
  {
    id: 2,
    date: "2023-09-28T00:00:00Z",
    ipAddress: "64.179.192.174",
    browser: "Chrome",
    agent: "Mozilla/5.0 (Version 10)",
  },
  {
    id: 3,
    date: "2023-09-28T00:00:00Z",
    ipAddress: "64.179.192.174",
    browser: "Chrome",
    agent: "Mozilla/5.0 (Version 10)",
  },
  {
    id: 4,
    date: "2023-09-28T00:00:00Z",
    ipAddress: "64.179.192.174",
    browser: "Chrome",
    agent: "Mozilla/5.0 (Version 10)",
  },
  {
    id: 5,
    date: "2023-09-28T00:00:00Z",
    ipAddress: "64.179.192.174",
    browser: "Chrome",
    agent: "Mozilla/5.0 (Version 10)",
  },
  {
    id: 6,
    date: "2023-09-28T00:00:00Z",
    ipAddress: "64.179.192.174",
    browser: "Chrome",
    agent: "Mozilla/5.0 (Version 10)",
  },
  {
    id: 7,
    date: "2023-09-28T00:00:00Z",
    ipAddress: "64.179.192.174",
    browser: "Chrome",
    agent: "Mozilla/5.0 (Version 10)",
  },
  {
    id: 8,
    date: "2023-09-28T00:00:00Z",
    ipAddress: "64.179.192.174",
    browser: "Chrome",
    agent: "Mozilla/5.0 (Version 10)",
  },
];

export const sslTableData = [
  {
    id: 1,
    host: "www.support.cyberheals.com",
    expire_date: "Aug 18, 2023",
    ssl_provider: "application/javascript",
  },
  {
    id: 2,
    host: "www.support.cyberheals.com",
    expire_date: "Aug 18, 2023",
    ssl_provider: "application/javascript",
  },
  {
    id: 3,
    host: "www.support.cyberheals.com",
    expire_date: "Aug 18, 2023",
    ssl_provider: "application/javascript",
  },
  {
    id: 4,
    host: "www.support.cyberheals.com",
    expire_date: "Aug 18, 2023",
    ssl_provider: "application/javascript",
  },
  {
    id: 5,
    host: "www.support.cyberheals.com",
    expire_date: "Aug 18, 2023",
    ssl_provider: "application/javascript",
  },
  {
    id: 6,
    host: "www.support.cyberheals.com",
    expire_date: "Aug 18, 2023",
    ssl_provider: "application/javascript",
  },
];

export const notifications = [
  {
    id: 1,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 2,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 3,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 4,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 5,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 6,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 7,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 8,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 9,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 10,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
  {
    id: 11,
    message: "John Hammond request is waiting for your approval",
    time: "2023-10-30T00:00:00Z",
  },
];
