import { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { AiOutlineCalendar } from "react-icons/ai";
import { useParams } from "react-router-dom";

import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../../helpers";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import fileUpload from "../../../../../utils/fileUpload";

export const TYPE = [
  {
    label: "Access Ticket",
    value: "access_ticket",
  },
  {
    label: "COC",
    value: "coc",
  },
  {
    label: "BGV",
    value: "bgv",
  },
  {
    label: "Confidentiality Agreement",
    value: "confidentiality_agreement",
  },
];

export default function AddOffBoarding({ visible, onHide, currentDetails }) {
  const { id } = useParams();

  const [data, setData] = useState({
    employee: id,
  });
  const [error, setError] = useState({});

  const [openDate, setOpenDate] = useState(false);

  const handleAPI = (e) => {
    if (currentDetails) {
      complianceAPI
        .patch(`/onboarding-document/${currentDetails.id}/`, {
          ...data,
          employee: id,
        })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Documents updated successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/onboarding-document/", { ...data, employee: id })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Documents added successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");

    const file = e.target.file.files[0];
    if (!file && !currentDetails) {
      toast.error("Please select a file");
      e.target.submit.removeAttribute("disabled");
      return;
    }

    if (file) {
      fileUpload("v1/compliance/attachments/", file)
        .then((res) => {
          if (res.status === "success") {
            data.attachment = res.data.id;
            handleAPI(e);
          } else {
            toast.error(res.message);
            e.target.submit.removeAttribute("disabled");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
          e.target.submit.removeAttribute("disabled");
          return;
        });
    } else {
      handleAPI(e);
    }
  };

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className={`w-full md:w-20rem lg:w-30rem`}
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Documents`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Documents`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Type</label>
            <Dropdown
              value={data.type}
              onChange={(e) => setData({ ...data, type: e.value })}
              options={TYPE}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">Date</label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.date)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenDate(!openDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={data.date}
                    onChange={(e) => {
                      setData({
                        ...data,
                        date: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm" for="file_input">
              Upload file
            </label>
            <input
              className="w-full cursor-pointer rounded-lg border-[1px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter dark:file:text-gray-500 file:py-2 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:focus:border-primary"
              name="file"
              type="file"
            />
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
