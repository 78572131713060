import { useContext, useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { ThemeContext } from "../../../../../context/ThemeContext";
import { Sidebar } from "primereact/sidebar";
import { frameworks } from "./data";
import ComplianceView from "./ComplianceView";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export const Status = ({ status }) => {
  let color = "text-gray-500";
  switch (status) {
    case "n/a":
      color = "text-gray-500";
      break;
    case "in_progress":
      color = "text-yellow-500";
      break;
    case "fully_implemented":
      color = "text-green-500";
      break;
    default:
      color = "text-gray-500";
      break;
  }
  return (
    <span className={`font-bold ${color}`}>
      <IoMdCheckmarkCircleOutline />
    </span>
  );
};

export default function Compliance({ _data }) {
  const { isDarkMode } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(null);
  const [currentDetails, setCurrentDetails] = useState(null);

  const getData = () => {
    complianceAPI
      .post("/trust-center/compliance/filter/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (_data) {
      setData(_data);
    } else {
      getData();
    }
  }, []);

  return (
    <>
      <div
        className={`rounded-2xl p-5 ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        <div className="flex justify-between">
          <p className="font-bold">Compliance</p>
          {!_data && (
            <button
              className="p-button-text outline-none flex border p-1 gap-2 items-center rounded-md"
              onClick={() => setVisible(true)}
            >
              Edit <FaRegEdit />
            </button>
          )}
        </div>
        <div className="grid grid-cols-6 gap-2 mt-3 ">
          {frameworks
            .filter((i) =>
              data?.find(
                (j) =>
                  j.compliance === i.name && j.implementation_level !== "n/a"
              )
            )
            .map((item) => (
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={() => {
                  setCurrentDetails({
                    ...item,
                    ...data?.find((i) => i.compliance === item.name),
                  });
                }}
              >
                <img src={item.logo} width="80px" height="80px" />
                <p>{item.name}</p>
              </div>
            ))}
        </div>
      </div>
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <p className="font-bold text-2xl">Compliance</p>
        <div className="flex flex-col gap-3">
          {frameworks?.map((item) => (
            <div className="border rounded-md gap-2 p-3 flex justify-between items-center">
              <p className="flex gap-1 items-center">
                {/* <IoMdCheckmarkCircleOutline color="blue" /> */}
                <Status
                  status={
                    data?.find((i) => i.compliance === item.name)
                      ?.implementation_level
                  }
                />
                {item.name}
              </p>
              <button
                className="p-button-text flex p-1 items-center rounded-md"
                onClick={() => {
                  setCurrentDetails({
                    ...item,
                    ...data?.find((i) => i.compliance === item.name),
                  });
                }}
              >
                <FaRegEdit />
                Edit
              </button>
            </div>
          ))}
        </div>
      </Sidebar>
      <ComplianceView
        currentDetails={currentDetails}
        setCurrentDetails={setCurrentDetails}
        onHide={() => {
          if (!_data) {
            getData();
          }
          setCurrentDetails(null);
        }}
        isPublic={_data}
      />
    </>
  );
}
