import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { useSelector } from "react-redux";

import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import TableFilterApiBase from "../../../../Api/FIlterAPI/base";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { getFilter } from "../../../../utils/utils";

export default function FakeApps() {
  const { isDarkMode } = useContext(ThemeContext);
  const scanID = useSelector(getUserDetails).scan_id;
  const api = new TableFilterApiBase("v1/web/modapp/filter/");
  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scanID });
  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    { field: "app_name", header: "App Name", value: filterData?.app_name },
    { field: "platform", header: "Platform", value: filterData?.platform },
    { field: "app_url", header: "URL" },
    { field: "app_version", header: "Version", value: filterData?.app_version },
    { field: "app_type", header: "Type", value: filterData?.app_type },
  ];

  const getFilterData = () => {
    api
      .get({ scanID })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    api
      .post({ ...getFilter(filterData, filterParams), scanID })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scanID]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <div className="ssl-active">
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            Mod Apps <span> ({data.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              paginatorTemplate={paginationTemplate()}
              removableSort
              globalFilter={globalFilter}
              value={data}
              ref={ref}
              rows={10}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => {
                if (col.field === "app_url") {
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      body={(rowData) => (
                        <a
                          href={rowData.app_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {rowData.app_url}
                        </a>
                      )}
                    />
                  );
                } else {
                  return (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                }
              })}
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}
