import React, { useContext, useEffect, useRef, useState } from "react";
import { TbArrowUpRight } from "react-icons/tb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiArrowUpRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineDislike, AiOutlineLike } from "react-icons/ai";

import GenericLayout from "../../common/layout/GenericLayout";
import { ThemeContext } from "../../context/ThemeContext";
import TableFilter from "../../common/Tables/TableFilter";
import SkeletonDataTable from "../../modules/SkeletonAnimation/SkeletonDataTable";
import { getCSPMFilters, getUserDetails } from "../../redux-toolkit/redux/auth";
import { complianceAPI, cspmAPI } from "../../Api/CSPM/api";

import {
  SEVERITY_COLOR,
  SEVERITY_TEXT,
  STATUS_COLOR,
  STATUS_TEXT,
} from "../CSPM/variables";
import paginationTemplate from "../../utils/paginationTemplate";
import { getFilter } from "../../utils/utils";
import { Sidebar } from "primereact/sidebar";
import { toast } from "react-toastify";
import HandleResponseError from "../../utils/error";

export default function CloudTestTable(props) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const currentUrl = window.location.href;

  const ref = useRef(null);
  const { state } = useLocation();

  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scan = userDetails.compliance_cloud_id;
  const cspmFilters = useSelector(getCSPMFilters);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({
    scan,
    ...state?.filter,
  });
  const [globalFilter, setGlobalFilter] = useState(null);
  const [assignCheck, setAssignCheck] = useState(false);

  const [employee, setEmployee] = useState([]);

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getFilterData = () => {
    cspmAPI
      .get("/findings/filter/", {
        scan,
        ...state?.filter,
        resource__labels__contains: JSON.stringify(cspmFilters),
      })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    setLoading(true);
    cspmAPI
      .post("/findings/filter/", {
        scan,
        ...getFilter(filterData, filterParams),
        ...state?.filter,
        order_by: "-status",
        unique_by: "checker",
        resource__labels__contains: cspmFilters,
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("getFilterData", scan);
    if (scan !== 0) {
      console.log("getFilterData1", scan);
      getFilterData();
      getEmployee();
    }
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  const columns = [
    { field: "check_info.title", header: "Check" },
    {
      field: "severity",
      header: "Severity",
    },
    {
      field: "status",
      header: "Status",
    },
    { field: "resource_info.group.name", header: "Service" },
  ];

  const filterColumns = [
    {
      field: "severity",
      header: "Severity",
      type: "select",
      value: filterData?.severity?.map((item) => {
        return { label: SEVERITY_TEXT[item], value: item };
      }),
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: filterData?.status?.map((item) => {
        return { label: STATUS_TEXT[item], value: item };
      }),
    },
    {
      field: "resource__region",
      header: "Region",
      value: filterData?.resource__region,
    },
    {
      field: "resource__group__name",
      header: "Service",
      value: filterData?.resource__group__name,
    },
  ];

  const IgnoreCheck = (rowData) => {
    if (rowData.ignored) {
      cspmAPI
        .delete(
          `/ignore-check/${rowData.checker}/${rowData.resource_info.cloud}/`
        )
        .then((res) => {
          toast.success("Check unignored successfully");
          getData();
        })
        .catch((err) => {
          HandleResponseError(err.response.data);
          console.log(err);
        });
    } else {
      cspmAPI
        .post("/ignore-check/", {
          checker: rowData.checker,
          cloud: rowData.resource_info.cloud,
        })
        .then((res) => {
          if (res.id) {
            toast.success("Check ignored successfully");
            getData();
          } else {
            HandleResponseError(res);
          }
        })
        .catch((err) => {
          if (err.response.data?.non_field_errors) {
            toast.error("Check already ignored");
          } else {
            HandleResponseError(err.response.data);
          }
          console.log(err);
        });
    }
  };

  const returnTable = () => {
    const CSPM_COMPLIANCE_SLUGS = [
      "ISO27001-2013",
      "SOC2",
      "PCI-3.2.1",
      "GDPR",
      "HIPAA",
      "CISA",
      "NIST-800-53-Revision-5",
      "NIST-CSF-1.1",
      "FedRamp-Moderate-Revision-4",
      "FedRAMP-Low-Revision-4",
      "RBI-Cyber-Security-Framework",
      "FFIEC",
    ];
    if (
      userDetails.role === "Editor" &&
      CSPM_COMPLIANCE_SLUGS.includes(slug) &&
      !userDetails.module.includes("Compliance - CSPM")
    )
      return <div className="flex justify-center">Forbidden</div>;
    else if (
      userDetails.role === "Editor" &&
      !CSPM_COMPLIANCE_SLUGS.includes(slug) &&
      !userDetails.module.includes("Services")
    )
      return <div className="flex justify-center">Forbidden</div>;

    return (
      <>
        <div
          className={`rounded-2xl userTable-style  ${
            isDarkMode
              ? "header-dark  text-white"
              : "header-light white-background  text-color"
          }`}
        >
          <div className="flex flex-wrap  dashboard-table">
            <p className="font-bold">
              {state?.title || slug.replaceAll("_", " ")}{" "}
              <span> ({data.length || 0}) </span>
            </p>
          </div>
          <TableFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            columns={filterColumns}
            tableData={data}
            tableHeader={columns}
            dt={ref}
          />
          {loading ? (
            <SkeletonDataTable
              columns={[
                ...columns,
                { field: "full_info", header: "Full info" },
              ]}
            />
          ) : (
            <DataTable
              removableSort
              value={data.map((item) => {
                return {
                  ...item,
                  severity: SEVERITY_TEXT[item.severity],
                  status: STATUS_TEXT[item.status],
                };
              })}
              paginator
              rows={10}
              ref={ref}
              sortField="severity"
              sortOrder={-1}
              sortMode="multiple"
              globalFilter={globalFilter}
              paginatorTemplate={paginationTemplate()}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => {
                if (col.field === "severity" || col.field === "status") {
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                      body={(rowData) => (
                        <span
                          className={
                            col.field === "status"
                              ? STATUS_COLOR[rowData.status]
                              : SEVERITY_COLOR[rowData.severity]
                          }
                        >
                          {rowData[col.field]}
                        </span>
                      )}
                    />
                  );
                } else {
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                }
              })}
              <Column
                header="Assignee"
                body={(rowData) => {
                  if (rowData.assignee.length === 0) {
                    return "-";
                  } else {
                    return rowData.assignee
                      .map((item) => employee.find((e) => e.id === item)?.email)
                      .join(", ");
                  }
                }}
              />
              <Column
                header="Actions"
                exportable={false}
                body={(rowData) => (
                  <div className="flex w-full justify-between gap-2">
                    <button
                      onClick={() => {
                        IgnoreCheck(rowData);
                      }}
                    >
                      {rowData.ignored ? (
                        <AiOutlineLike size={20} title="Unignore" />
                      ) : (
                        <AiOutlineDislike size={20} title="Ignore" />
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setAssignCheck(rowData);
                      }}
                    >
                      <FaRegUser size={20} />
                    </button>
                  </div>
                )}
              />
              <Column
                header="Full info"
                exportable={false}
                body={(rowData) => (
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        const { compliance__framework, scan } = state?.filter;
                        navigate(
                          `/compliance-cloud/${slug}/${rowData.check_info.uid}`,
                          {
                            state: {
                              title: `${state.title} - ${rowData.check_info.title}`,
                              filter: {
                                compliance__framework,
                                scan,
                                checker: rowData.checker,
                                checker__uid: rowData.check_info.uid,
                              },
                              prevState: state,
                            },
                          }
                        );
                      }}
                    >
                      <FiArrowUpRight size={20} color="#074EE8" />
                    </button>
                  </div>
                )}
              />
            </DataTable>
          )}
        </div>
      </>
    );
  };
  return (
    <GenericLayout>
      <div className="flex justify-between dashboard-header items-center">
        <p className="font-bold">Compliance</p>
        <div className="flex justify-between">
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
            onClick={() => {
              // navigate("/cloud-security-posture-management");
              navigate(`/${currentUrl.split("/").slice(3, -1).join("/")}`);
              // navigate(-1);
            }}
          >
            Go to dashboard
            <TbArrowUpRight />
          </button>
        </div>
      </div>
      {returnTable()}
      <AssignCheck
        assignCheck={assignCheck}
        setAssignCheck={setAssignCheck}
        employee={employee}
        getData={getData}
      />
    </GenericLayout>
  );
}

function AssignCheck({ assignCheck, setAssignCheck, employee, getData }) {
  const [data, setData] = useState({});

  const cleanClose = () => {
    setData({});
    setAssignCheck(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    cspmAPI
      .post("/assign-check/", {
        ...data,
        cloud: assignCheck.resource_info.cloud,
        checker: assignCheck.checker,
      })
      .then((res) => {
        if (res.id) {
          toast.success("Check assigned successfully");
          cleanClose();
          getData();
        } else {
          HandleResponseError(res);
        }
      })
      .catch((err) => {
        if (err.response.data?.non_field_errors) {
          toast.error("Check already assigned");
        } else {
          HandleResponseError(err.response.data);
        }
        console.log(err);
      });
  };

  return (
    <Sidebar visible={assignCheck} onHide={cleanClose} position="right">
      <p className="font-bold text-2xl">Assign Check</p>
      <form onSubmit={onSubmit}>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Assignee</label>
          <Dropdown
            filter
            value={data?.assignee}
            onChange={(e) => setData({ ...data, assignee: e.value })}
            options={employee}
            optionLabel="email"
            optionValue="id"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
            required
          />
        </div>
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            id="submit"
          >
            Assign
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={cleanClose}
            type="reset"
          >
            Back
          </button>
        </div>
      </form>
    </Sidebar>
  );
}
