export function handleSubmitButtonPress(e) {
  e.preventDefault();
  e.target.submit.setAttribute("disabled", "disabled");
  e.target.submit.className = e.target.submit.className + " cursor-wait";
}

export function handleSubmitButtonRelease(e) {
  e.target.submit.removeAttribute("disabled");
  e.target.submit.className = e.target.submit.className.replace(
    " cursor-wait",
    ""
  );
}

export function getFilter(filterData, filterParams) {
  const filter = {};
  Object.keys(filterData).forEach((key) => {
    if (filterParams[key]) {
      filter[key] = filterData[key].filter((item) => {
        if (
          typeof filterParams[key] === "string" ||
          typeof filterParams[key] === "number"
        ) {
          return item === filterParams[key];
        } else {
          return !filterParams[key].includes(item);
        }
      });
    } else {
      filter[key] = filterData[key];
    }
  });
  // if any item in filterParams is not in filter, add it to filter
  Object.keys(filterParams).forEach((key) => {
    if (!filter[key]) {
      filter[key] = filterParams[key];
    }
  });
  return filter;
}

export function getCloudFilter(filterData, filterParams) {
  const filter = {};

  Object.keys(filterData).forEach((key) => {
    if (filterParams[key] && Array.isArray(filterParams[key])) {
      // Ensure it's an array
      console.log("if", key, filterParams[key]);
      filter[key] = filterData[key].filter(
        (item) => !filterParams[key].includes(item) // Correctly filters based on array
      );
    } else {
      console.log("else", key, filterParams[key]);
      filter[key] = filterData[key];
    }
  });

  // If any item in filterParams is not in filter, add it to filter
  Object.keys(filterParams).forEach((key) => {
    if (!filter[key]) {
      filter[key] = filterParams[key];
    }
  });

  console.log("Final filter FilterData: " + JSON.stringify(filter));
  return filter;
}

export const ALLOWED_SUBDOMAINS = [
  "app",
  "demo",
  "qa",
  "dev",
  "localhost",
  "127.0.0.1",
];

export function IsAppSubdomains() {
  // add subdomains which is used for react app only here
  const host = window.location.hostname.split(".", 1);
  const subdomain = host[0];
  return ALLOWED_SUBDOMAINS.includes(subdomain);
}
