import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { useParams } from "react-router-dom";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import fileUpload from "../../../../../utils/fileUpload";

export const TYPE = [
  {
    label: "File",
    value: "file",
  },
  {
    label: "Link",
    value: "link",
  },
];

export default function AddDocuments({ visible, onHide, currentDetails }) {
  const { id } = useParams();

  const [data, setData] = useState({
    vendor: id,
    type: "file",
  });
  const [error, setError] = useState({});

  const handleAPI = (e) => {
    if (currentDetails) {
      complianceAPI
        .patch(`/vendor-document/${currentDetails.id}/`, {
          ...data,
          vendor: id,
        })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Documents updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/vendor-document/", { ...data, vendor: id })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Documents added successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");

    if (data.type === "file") {
      const file = e.target.file.files[0];
      if (!file) {
        toast.error("Please select a file");
        e.target.submit.removeAttribute("disabled");
        return;
      }
      fileUpload("v1/compliance/attachments/", file)
        .then((res) => {
          if (res.status === "success") {
            data.file = res.data.id;
            handleAPI(e);
          } else {
            toast.error(res.message);
            e.target.submit.removeAttribute("disabled");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
          e.target.submit.removeAttribute("disabled");
          return;
        });
    } else {
      handleAPI(e);
    }
  };

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className={`w-full md:w-20rem lg:w-30rem`}
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Documents`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Documents`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Type</label>
            <Dropdown
              value={data.type}
              onChange={(e) => setData({ ...data, type: e.value })}
              options={TYPE}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            {data?.type === "link" ? (
              <>
                <label className="block text-gray-500 text-sm">Link</label>
                <input
                  className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                  type="link"
                  placeholder="Link"
                  value={data.link}
                  required
                  onChange={(e) => {
                    setData({ ...data, link: e.target.value });
                    setError({ ...error, link: false });
                  }}
                />
                {error.link && (
                  <p className="text-red-500 text-sm">{error.link}</p>
                )}
              </>
            ) : (
              <>
                <label className="block text-gray-500 text-sm" for="file_input">
                  Upload file
                </label>
                <input
                  className="w-full cursor-pointer rounded-lg border-[1px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter dark:file:text-gray-500 file:py-2 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:focus:border-primary"
                  name="file"
                  type="file"
                />
              </>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Note</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter Note"
              value={data.note}
              required
              onChange={(e) => {
                setData({ ...data, note: e.target.value });
                setError({ ...error, note: false });
              }}
            />
            {error.note && <p className="text-red-500 text-sm">{error.note}</p>}
          </div>

          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
