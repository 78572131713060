import { Sidebar } from "primereact/sidebar";
import { useContext, useState } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  handleSubmitButtonPress,
  handleSubmitButtonRelease,
} from "../../../../utils/utils";
import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../helpers";
import { AiOutlineCalendar } from "react-icons/ai";
import { Calendar } from "primereact/calendar";
import secureCodeAPI from "../../../../Api/secureCodeAPI";
import { toast } from "react-toastify";
import handleDownload from "../../../../utils/fileDownload";

const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

export default function CreateNewAccessToken({ getData }) {
  const { isDarkMode } = useContext(ThemeContext);

  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const handleCreate = (e) => {
    handleSubmitButtonPress(e);
    secureCodeAPI
      .post("/token/", data)
      .then((res) => {
        toast.success("Token created successfully");
        getData();
        cleanClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleSubmitButtonRelease(e);
      });
  };

  const cleanClose = () => {
    setVisible(false);
    setData({});
  };

  return (
    <>
      <div className="flex gap-2">
        <button
          className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
            isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
          }`}
          onClick={() => setVisible(true)}
        >
          Create New Access Token
        </button>

        <button
          className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
            isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
          }`}
          onClick={() =>
            handleDownload({
              file: `${APP_DOMAIN}v1/secure-code/static/.gitlab-ci.yml`,
              name: ".gitlab-ci.yml",
            })
          }
        >
          Sample CI/CD Pipeline
        </button>
      </div>

      <Sidebar visible={visible} position="right" onHide={cleanClose}>
        <p className="font-bold text-2xl">Create New Access Token</p>
        <div className="flex flex-wrap my-2 gap-2">
          <form onSubmit={(e) => handleCreate(e)} className="w-full">
            <div className="w-full">
              <label className="block text-gray-500 text-sm">Name</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="Enter request name"
                value={data.name}
                required
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm">Description</label>
              <textarea
                row={10}
                className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
                placeholder="Enter description"
                value={data.description}
                onChange={(e) => {
                  setData({ ...data, description: e.target.value });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-normal text-[#686868]">
                Expiry date
              </label>
              <div className="relative">
                <input
                  value={DateTimeToDate(data?.expiry)}
                  placeholder="___ __, ____"
                  className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
                />
                <div
                  className="absolute cursor-pointer top-[12px] right-[12px]"
                  onClick={() => setOpenDate(!openDate)}
                >
                  <AiOutlineCalendar />
                </div>
              </div>
              {openDate && (
                <div className="mx-auto">
                  <div className="">
                    <Calendar
                      value={new Date(data?.expiry)}
                      onChange={(e) => {
                        setData({
                          ...data,
                          expiry: DateSelectionToDjangoFormat(e.value),
                        });
                        setOpenDate(false);
                      }}
                      inline
                      style={{ width: "360px" }}
                      minDate={new Date()}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex w-full mt-9">
              <button
                className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                type="submit"
                id="submit"
              >
                Create
              </button>
              <button
                className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
                onClick={cleanClose}
                type="reset"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
}
