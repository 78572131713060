import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { complianceAPI } from "../../../../Api/CSPM/api";
import { useEffect, useState } from "react";

export default function AddorEditAssignee({
  handleCloseSideBar,
  currentDetails,
  setVisibleRight,
  getTableFilterData,
}) {
  const [employee, setEmployee] = useState([]);
  const [data, setData] = useState(currentDetails || {});

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (currentDetails) {
      complianceAPI
        .patch(`/security-role/${data.id}/`, data)
        .then((res) => {
          console.log("RESPONSE ===> ", res);
          toast.success("User Updated Successfully");
          setVisibleRight(false);
          getEmployee();
          getTableFilterData();
        })
        .catch((err) => console.log(err));
    } else {
      complianceAPI
        .post("/security-role/", data)
        .then((res) => {
          console.log("RESPONSE ===> ", res);
          toast.success("User Created Successfully");
          setVisibleRight(false);
          getEmployee();
          getTableFilterData();
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getEmployee();
  }, []);

  console.log(currentDetails);

  return (
    <>
      <form className="py-5 flex flex-col w-full login-style">
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Role Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Role Name"
              value={data.role}
              onChange={(e) => setData({ ...data, role: e.target.value })}
            />
          </div>
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter description"
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
              }}
            />
          </div>
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm">Assignee</label>
            <Dropdown
              filter
              value={data.employee_email}
              onChange={(e) => setData({ ...data, employee_email: e.value })}
              options={employee}
              optionLabel="name"
              optionValue="email"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
              // disabled={userTableViewData ? true : false}
            />
          </div>
          <div className="flex w-full px-3 mt-9">
            <button
              onClick={(e) => onSubmit(e)}
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            >
              {currentDetails ? "Update" : "Add"}
            </button>
            <button
              onClick={handleCloseSideBar}
              className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            >
              Back
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
