import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { ThemeContext } from "../../../../context/ThemeContext";
import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../../modules/SkeletonAnimation/SkeletonDataTable";

import paginationTemplate from "../../../../utils/paginationTemplate";
import AddEmployee from "../../../../modules/home/components/compliance/people/AddEmployee";
import { complianceAPI } from "../../../../Api/CSPM/api";
import IntegratePeople from "../../../../modules/home/components/compliance/people/IntegratePeople";
import { SidebarSlide } from "../../../../Component/Header/SidebarSlide";
import { setUserTableData } from "../../../../redux-toolkit/redux/TableData";
import ImportPeoples from "../../../../modules/home/components/compliance/people/ImportPeoples";
import { getFilter } from "../../../../utils/utils";
import {
  isAuditorOrSubscriberOrAdminOrSuperAdmin,
  isSuperAdmin,
} from "../../../../utils/accessCheck";
import ResendInvitation from "../../../../modules/home/components/compliance/people/ResendInvitation";
import { DateTimeToDate } from "../../../../helpers";
import ViewAccessReview from "./ViewAccessReview";
import React from "react";

export default function AutomatedAccessReview() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDarkMode } = useContext(ThemeContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState(null);
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [view, setView] = useState(false);
  const [pagination, setPagination] = useState({});
  const [metadata, setMetadata] = useState({});
  const [selectedTab, setSelectedTab] = useState("employee");
  const userInformation = JSON.parse(localStorage.getItem("userDetails"));

  const getData = () => {
    complianceAPI
      .post("/access-review/filter/", filterData)
      .then(({ data, pagination, metadata }) => {
        setData(data);
        setPagination(pagination);
        setMetadata(metadata);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  // console.log(table)
 
  const getFilterData = () => {
    complianceAPI
      .get("/access-review/filter/")
      .then(({ data }) => {
        setFilterData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

 useEffect(() => {
    getFilterData();
  }, []);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  const columns = [
    {
      field: "employee_name",
      header: "Employee name",
    },
    {
      field: "employee_email",
      header: "Email",
    },
    {
      field: "status",
      header: "Task Status",
    },
    {
      field: "joining-date",
      header: "Joining date",
    },
    {
      field: "revoke-access",
      header: "Revoke access",
    },
    {
      field: "role",
      header: "Role",
    },
    // {
    //   field: "access_tool" ,
    //   name: "Access Tools",
    //   body: (rowData) => {
    //     let datax = rowData.access_tool;
    //     let result;

    //     console.log(datax)
        
    //     if (datax instanceof Array) {
    //         return (
    //           <div className="flex items-center gap-1">
    //             {
    //               datax.map((i, indx) => {
    //                 return <img field={indx} src={getServiceImage(i)} width="20px" height="20px"/>
    //               })
    //             }
    //           </div>
    //         )
    //     }

    //     return (
    //       <img src={getServiceImage(datax)} width="20px" height="20px"/>
    //     )
        
    //     // console.log("Row Data: "+ typeof result+ result)
    //   }
    // },
    {
      field: "actions",
      header: "Actions",
    },
  ];

  const EmployeeComponent = () => (
    <>
      <div className="flex flex-wrap  dashboard-table">
        <p className="font-bold">
          Automated Access Review <span> ({data.length || 0}) </span>
        </p>
      </div>
      <>
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          columns={columns}
          tableData={data}
          tableHeader={columns}
          dt={ref}
          isDarkMode={isDarkMode}
        //   IntegrationComponent={integrateOrImport}
        />
        {loading ? (
          <SkeletonDataTable
            columns={columns}
            // columns={[...columns, { field: "full_info", header: "Full info" }]}
          />
        ) : (
          <DataTable
            ref={ref}
            removableSort
            value={data.map((i) => i)}
            paginator
            rows={10}
            sortField="severity"
            sortOrder={-1}
            sortMode="multiple"
            globalFilter={globalFilter}
            paginatorTemplate={paginationTemplate()}
            className={`${
              isDarkMode ? "dark-mode text-dark" : "text-color"
            } text-xs cursor-pointer`}
            // onRowClick={({ data }) => {
            //   navigate(`/compliance/employee/${data.id}`);
            // }}
            onRowClick={({ data }) => { 
              setView(true);
              setCurrentDetails(data);
            }}
          >
            {columns.map((col, i) => {
              return (
                <Column
                  key={i}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              );
            })}
            <Column
              key="action"
              field="action"
              header="Action"
              body={(rowData) => (
                <SplitButton
                  icon={<FiMoreVertical size={24} />}
                  className="p-button-text outline-none split-style"
                  model={[
                    {
                      label: "Edit",
                      icon: "pi pi-refresh",
                      command: () => {
                        setCurrentDetails(rowData);
                        dispatch(setUserTableData(rowData));
                        setView(false);
                      },
                    },
                    {
                      label: "View",
                      icon: "pi pi-times",
                      command: () => {
                        setCurrentDetails(rowData);
                        dispatch(setUserTableData(rowData));
                        setView(true);
                      },
                    },
                  ]}
                ></SplitButton>
              )}
            />
          </DataTable>
        )}
      </>
    </>
  );

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="py-4 px-8">
            <EmployeeComponent />
        </div>
        {
          view && <ViewAccessReview currentDetails={currentDetails} view={view} setView={setView} columns={columns} />
        }
      </div>
    </>
  );
}
