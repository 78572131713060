import { Sidebar } from "primereact/sidebar";
import { useContext, useState, useCallback } from "react";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import TextArea from "antd/es/input/TextArea";
import { Calendar } from "primereact/calendar";
import { AiOutlineCalendar } from "react-icons/ai";
import { DateSelectionToDjangoFormat, DateTimeToDate } from "../../helpers";
import customAxios, { baseUrl } from "../../Api/Repository";

export default function EditSubscription({ setShowEdit, data, setData }) {
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  // Form State
  const [plan, setPlan] = useState(data.plan || null);
  const [targets, setTargets] = useState(data.targets || null);
  const [attackSurfaceManagement, setAttackSurfaceManagement] = useState(
    data.attack_surface_management || 0
  );
  const [digitalRiskProtection, setDigitalRiskProtection] = useState(
    data.digital_risk_protection || 0
  );
  const [vulnerabilityOperationCenter, setVulnerabilityOperationCenter] =
    useState(data.vulnerability_operation_center || 0);
  const [GRC, setGRC] = useState(data.compliance || 0);
  const [cloudSecurityPosture, setCloudSecurityPosture] = useState(
    data.cloud_security_posture || 0
  );
  const [pentestAsAService, setPentestAsAService] = useState(
    data.pentest_as_a_service || 0
  );
  const [domains, setDomains] = useState(data.domains || 0);
  const [keywords, setKeywords] = useState(data.keywords || 0);
  const [subdomains, setSubdomains] = useState(data.subdomains || 0);
  const [userCount, setUserCount] = useState(data.user_count || 0);
  const [totalCount, setTotalCount] = useState(data.total_count || 0);
  const [remainingCount, setRemainingCount] = useState(
    data.remaining_count || 0
  );
  const [scanning, setScanning] = useState(data.scanning || 0);
  const [scanCount, setScanCount] = useState(data.scan_count || 0);
  const [amount, setAmount] = useState(data.amount.slice(1) || 0);
  const [startDate, setStartDate] = useState(data.start_date || null);
  const [endDate, setEndDate] = useState(data.end_date || null);

  const PLANS = [
    {
      label: "Basic",
      value: "Basic",
    },
    {
      label: "Standard",
      value: "Standard",
    },
    {
      label: "Enterprise",
      value: "Enterprise",
    },
  ];

  const editData = {
    user: data.email,
    plan: plan,
    targets: targets,
    attack_surface_management: attackSurfaceManagement,
    brand_threat_monitoring: 0,
    deep_darkweb_monitoring: 0,
    data_leak_detection: 0,
    digital_risk_protection: digitalRiskProtection,
    vulnerability_operation_center: vulnerabilityOperationCenter,
    compliance: GRC,
    cloud_security_posture: cloudSecurityPosture,
    pentest_as_a_service: pentestAsAService,
    domains: domains,
    keywords: keywords,
    subdomains: subdomains,
    user_count: userCount,
    total_count: totalCount,
    remaining_count: remainingCount,
    scanning: scanning,
    scan_count: scanCount,
    amount: amount,
    start_date: startDate,
    end_date: endDate,
  };

  const handleUpdate = (e) => {
    customAxios
      .patch(`${baseUrl}v1/subscription/${data.id}`, editData)
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
          cleanClose();
        } else {
          toast.error(res.data.message);
          cleanClose();
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.data.message);
      });
  };

  const cleanClose = () => {
    setShowEdit(false);
    setData({});
  };

  useState(() => {
    setStartDate(data?.start_date);
    setEndDate(data?.end_date);
  }, []);

  return (
    <>
      <Sidebar
        visible={true}
        position="right"
        onHide={() => setShowEdit(false)}
      >
        <p className="font-bold text-2xl">Subscription</p>
        <p className="text-sm py-1"></p>
        <div className="flex flex-col gap-3 w-full pt-3">
          {/* Plans */}
          <div className="flex flex-col gap-1">
            <label className="block text-gray-500 text-sm">Plan</label>
            <Dropdown
              value={plan}
              onChange={(e) => setPlan(e.value)}
              options={PLANS}
              optionLabel="label"
              optionValue="value"
              placeholder="Select a Plan"
              className="w-full md:w-14rem my-1"
            />
          </div>
          {/* Targets */}
          <div className="flex flex-col gap-1">
            <label className="block text-gray-500 text-sm">Targets</label>
            <TextArea
              className="w-full md:w-14rem my-1"
              value={targets}
              onChange={(e) => setTargets(e.target.value)}
            >
              {targets}
            </TextArea>
          </div>
          {/* Checkboxes */}
          <div className="flex flex-col gap-2 ml-1">
            {/* ASM */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="asm"
                name="asm"
                className="w-4 h-4"
                onChange={(e) => {
                  setAttackSurfaceManagement(e.target.checked ? 1 : 0);
                }}
                checked={attackSurfaceManagement == 1}
              />
              <label for="asm" className="text-gray-500">
                Attack Surface Management
              </label>
            </div>
            {/* DRP */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="drp"
                name="drp"
                className="w-4 h-4"
                onChange={(e) => {
                  setDigitalRiskProtection(e.target.checked ? 1 : 0);
                }}
                checked={digitalRiskProtection == 1}
              />
              <label for="drp" className="text-gray-500">
                Digital Risk Protection
              </label>
            </div>
            {/* VOC */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="voc"
                name="voc"
                className="w-4 h-4"
                onChange={(e) => {
                  setVulnerabilityOperationCenter(e.target.checked ? 1 : 0);
                }}
                checked={vulnerabilityOperationCenter == 1}
              />
              <label for="voc" className="text-gray-500">
                Vulnerability Operations Center
              </label>
            </div>
            {/* GRC */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="grc"
                name="grc"
                className="w-4 h-4"
                onChange={(e) => {
                  setGRC(e.target.checked ? 1 : 0);
                }}
                checked={GRC == 1}
              />
              <label for="grc" className="text-gray-500">
                Governance Risk Compliance
              </label>
            </div>
            {/* CSPM */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="cspm"
                name="cspm"
                className="w-4 h-4"
                onChange={(e) => {
                  setCloudSecurityPosture(e.target.checked ? 1 : 0);
                }}
                checked={cloudSecurityPosture == 1}
              />
              <label for="cspm" className="text-gray-500">
                Cloud Security Posture Management
              </label>
            </div>
            {/* PTaaS */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="ptaas"
                name="ptaas"
                className="w-4 h-4"
                onChange={(e) => {
                  setPentestAsAService(e.target.checked ? 1 : 0);
                }}
                checked={cloudSecurityPosture == 1}
              />
              <label for="ptaas" className="text-gray-500">
                Pentest As A Service
              </label>
            </div>
          </div>
          {/* Others */}
          <div className="flex flex-col gap-2">
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">Domains</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                min="0"
                value={domains}
                onChange={(e) => {
                  setDomains(e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">Keywords</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                min="0"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">Subdomains</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                min="0"
                value={subdomains}
                onChange={(e) => setSubdomains(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">User Count</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                min="0"
                value={userCount}
                onChange={(e) => setUserCount(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">
                Total Target
              </label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                min="0"
                value={totalCount}
                onChange={(e) => setTotalCount(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">
                Remaining Target
              </label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                min="0"
                value={remainingCount}
                onChange={(e) => setRemainingCount(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">Scanning</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                value={scanning}
                onChange={(e) => setScanning(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">Scan count</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                min="0"
                value={scanCount}
                onChange={(e) => setScanCount(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label className="block text-gray-500 text-sm ">Amount</label>
              <input
                className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="number"
                min="0"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
          {/* Dates */}
          <div className="flex flex-col w-full gap-2">
            <div className="w-full pt-3">
              <label className="text-sm font-normal text-[#686868]">
                Start Date
              </label>
              <div className="relative">
                <input
                  value={startDate}
                  placeholder="___ __, ____"
                  className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
                />
                <div
                  className="absolute cursor-pointer top-[12px] right-[12px]"
                  onClick={() => setOpenStartDate(!openStartDate)}
                >
                  <AiOutlineCalendar />
                </div>
              </div>
              {openStartDate && (
                <div className="mx-auto">
                  <div className="">
                    <Calendar
                      value={new Date(startDate)}
                      onChange={(e) => {
                        setStartDate(DateSelectionToDjangoFormat(e.value));
                        setOpenStartDate(false);
                      }}
                      inline
                      style={{ width: "360px" }}
                      defaultDate={new Date()}
                      maxDate={new Date(endDate)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="w-full pt-3">
              <label className="text-sm font-normal text-[#686868]">
                End Date
              </label>
              <div className="relative">
                <input
                  value={endDate}
                  placeholder="___ __, ____"
                  className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
                />
                <div
                  className="absolute cursor-pointer top-[12px] right-[12px]"
                  onClick={() => setOpenEndDate(!openEndDate)}
                >
                  <AiOutlineCalendar />
                </div>
              </div>
              {openEndDate && (
                <div className="mx-auto">
                  <div className="">
                    <Calendar
                      value={new Date(endDate)}
                      onChange={(e) => {
                        setEndDate(DateSelectionToDjangoFormat(e.value));
                        setOpenEndDate(false);
                      }}
                      inline
                      style={{ width: "360px" }}
                      minDate={
                        new Date(
                          new Date(startDate).setDate(
                            new Date(startDate).getDate() + 1
                          )
                        )
                      }
                      // minDate={new Date()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Buttons */}
          <div className="flex items-center gap-3 w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
              onClick={() => handleUpdate()}
            >
              Update
            </button>
            <button
              className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </div>
      </Sidebar>
    </>
  );
}
