import { createElement, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import { ThemeContext } from "../../../../../context/ThemeContext";
import Onboarding from "./OnBoarding";
import Offboarding from "./OffBoarding";
import { getUserDetails } from "../../../../../redux-toolkit/redux/auth";
import PolicyAcceptance from "./PolicyAcceptance";

export default function ViewEmployee() {
  const { id } = useParams();
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [currentTab, setCurrentTab] = useState("onboarding");

  const TRAINING_STATUS = {
    in_complete: "In Complete",
    complete: "Completed",
  };

  const TABS = [
    {
      id: "onboarding",
      title: "Onboarding",
      component: Onboarding,
    },
    {
      id: "offboarding",
      title: "Offboarding",
      component: Offboarding,
    },
    {
      id: "policy_status",
      title: "Policy Acceptance",
      component: PolicyAcceptance,
    },
    // {
    //   id: "compain_history",
    //   title: "Campaign History",
    //   component: () => <>hehe</>,
    // },
    // {
    //   id: "technicals",
    //   title: "Technicals",
    //   component: () => <>hehe</>,
    // },
    // {
    //   id: "audit_logs",
    //   title: "Audit Logs",
    //   component: () => <>hehe</>,
    // },
  ];

  const getData = () => {
    complianceAPI
      .get(`/employees/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <h1 className="font-bold mb-2">People</h1>
      <div
        className={`rounded-2xl userTable-style grid grid-cols-2 ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        <div>
          <p className="font-bold">{data?.name}</p>
          <p className="text-sm">{data?.email}</p>
        </div>
        <div>
          <p>
            <span className="font-bold">Training Status:</span>{" "}
            {TRAINING_STATUS[data?.training_status]}
          </p>
        </div>
        {userDetails.id == id && (
          <p
            className="text-blue-500 text-sm mt-3 cursor-pointer"
            onClick={() => navigate("/compliance/employee-campaign")}
          >
            Access Campaigns
          </p>
        )}
      </div>
      <div
        className={`flex gap-5 border-b-2 my-6 mx-2 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="py-4 px-2">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component)}
      </div>
    </>
  );
}
