import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import DarkWebThirdTable from "../../../home/components/tables/DarkWeb/DarkWebThirdTable";
import TableFilterApiBase from "../../../../Api/FIlterAPI/base";
import { getFilter } from "../../../../utils/utils";

const DarkWebThird = (props) => {
  const { isDarkMode } = useContext(ThemeContext);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;

  const api = new TableFilterApiBase(
    "v1/web/digital-risk/pastecrawlerleaks/filter/"
  );

  const { title } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({ scan });

  const getFilterData = () => {
    api
      .get({ scan, result: "pastecrawler" })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    api
      .post({
        ...getFilter(filterData, filterParams),
        result: "pastecrawler",
        scan,
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({data.length}) </span>
          </p>
        </div>
        <DarkWebThirdTable
          loadingTable={loading}
          data={data}
          filterData={filterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default DarkWebThird;
