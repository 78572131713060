import { createElement, useContext, useEffect, useState } from "react";
import { TbArrowUpRight } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";

import GenericLayout from "../../common/layout/GenericLayout";
import { ThemeContext } from "../../context/ThemeContext";
import { DateTimeToDate } from "../../helpers";
import MitigationTasks from "./MitigationTasks";
import Vulnerabilities from "./Vulnerabilities";
import ScanHistory from "./ScanHistory";
import { STATUS } from "./index";
import UpdateStatus from "./updateStatus";

export const PRIORITIES = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];

export default function ViewRepo() {
  const { state } = useLocation();
  const { isDarkMode } = useContext(ThemeContext);

  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [update, setUpdate] = useState(null);
  const [currentTab, setCurrentTab] = useState("vulnerabilities");

  const TABS = [
    {
      id: "vulnerabilities",
      title: "Vulnerabilities",
      component: Vulnerabilities,
    },
    // {
    //   id: "mitigation_tasks",
    //   title: "Mitigation Tasks",
    //   component: MitigationTasks,
    // },
  ];

  useEffect(() => {
    setData(state?.data);
  }, [state]);

  return (
    <GenericLayout>
      <div className="flex justify-between dashboard-header items-center">
        <p className="font-bold">Repo Details : {data?.name}</p>
        <div className="flex justify-between">
          <ScanHistory data={data} repository={data?.id} setData={setData} />
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
            onClick={() => {
              navigate("/secure-code-review");
            }}
          >
            Go to dashboard
            <TbArrowUpRight />
          </button>
        </div>
      </div>
      <div
        className={`rounded-2xl p-5 flex flex-col gap-4 ${
          isDarkMode ? "header-dark" : "header-light white-background"
        }`}
      >
        <div className="grid grid-cols-2">
          <p>
            <span className="font-semibold">Name: </span>
            {data?.name}
          </p>
          <p className="flex justify-between">
            <div>
              <span className="font-semibold">Repository URL: </span>
              <a
                href={data?.url}
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
              >
                {data?.url}
              </a>
            </div>
            <div
              className="flex justify-end cursor-pointer"
              onClick={() => {
                setUpdate(data);
              }}
            >
              <FaRegEdit />
            </div>
          </p>
        </div>
        <div className="grid grid-cols-4">
          <p>
            <span className="font-semibold">Due date: </span>
            {DateTimeToDate(data?.deadline)}
          </p>
          <p>
            <span className="font-semibold">Status: </span> {data?.status}
          </p>
          <p>
            <span className="font-semibold">Priority: </span>
            {PRIORITIES.find((i) => i.value === data?.priority)?.label}
          </p>
        </div>
      </div>
      <div
        className={`flex gap-5 border-b-2 mx-2 mt-4 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>
      {update && (
        <UpdateStatus
          visible={update}
          currentDetails={update}
          onHide={() => setUpdate(null)}
          setCurrentDetails={setData}
        />
      )}
      <div className="py-4 px-2">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component, {
          data,
          employees: state?.employees,
        })}
      </div>
    </GenericLayout>
  );
}
