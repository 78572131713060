import baseAPI, { baseUrl } from "./Repository.js"; //just axios instance
import { toast } from "react-toastify";
import { removeAuthenticationData } from "../utils/constants/index.jsx";
import { store } from "../redux-toolkit/store/store.js";

class API {
  constructor(path) {
    this.baseUrl = baseUrl;
    this.endpoint = this.baseUrl + path;
  }

  get(path, params, config = null) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      const org_id = store.getState()?.auth?.userDetails?.org_id || null;
      baseAPI
        .get(url, { params: { ...params, org_id }, ...config })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  post(path, data) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      const org_id = store.getState()?.auth?.userDetails?.org_id || null;
      baseAPI
        .post(url, { ...data, org_id })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  patch(path, data) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      const org_id = store.getState()?.auth?.userDetails?.org_id || null;
      baseAPI
        .patch(url, { ...data, org_id })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  postFile(path, data) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .post(url, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  delete(path) {
    return new Promise((resolve, reject) => {
      const url = this.endpoint + path;
      baseAPI
        .delete(url)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response.status === 401)
            removeAuthenticationData(); //do this in axios instance itself if it do globally same
          else reject(err);
        }); // for now
    });
  }

  exportData(e, path, params) {
    e.preventDefault();
    e.target.disabled = true;
    e.target.innerHTML = "Exporting...";
    const url = this.endpoint + path;
    const user = store.getState()?.auth?.userDetails?.org_id || null;
    baseAPI
      .post(
        url,
        { ...params, export_csv: true, user },
        { responseType: "blob" }
      )
      .then((response) => {
        try {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "text/csv" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            response.headers["content-disposition"]
              .split("filename=")[1]
              .slice(1, -1)
          );
          document.body.appendChild(link);
          link.click();
          toast.success("Exported Successfully");
        } catch (e) {
          console.log(e);
          toast.error("Export Failed");
        }
      })
      .catch((err) => {
        if (err.response.status === 401)
          removeAuthenticationData(); //do this in axios instance itself if it do globally same
        else toast.error("Export Failed");
      })
      .finally(() => {
        e.target.disabled = false;
        e.target.innerHTML = "Export";
      });
  }
}

const secureCodeAPI = new API("v1/secure-code");

export default secureCodeAPI;
